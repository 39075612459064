import styled from 'styled-components';

import { BASE_FONT_SIZE } from '../../../constants/theme';

const DataGrid = styled.div`
  position: relative;

  height: 100%;

  display: flex;
  flex-direction: column;

  > .loader {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
  }

  > .fixed-grid {
    flex: 1 1 auto;

    position: relative;

    ${(props) => props.theme.breakpoints.down('md')} {
      /* data grid CONTENTS height */
      min-height: 35rem;
    }

    /* Space for loader */
    padding-top: 4px;

    > .scroll-grid {
      position: absolute;

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      overflow-x: scroll;

      > .outer-grid {
        height: 100%;

        ${(props) => props.theme.breakpoints.down('sm')} {
          min-width: 46rem;
        }

        .row {
          display: flex;
          align-items: center;
        }

        .cell {
          flex: 1 1 auto;

          display: flex;
          align-items: center;

          height: 4.8rem;

          cursor: initial;

          line-height: 1.8rem;

          .marker {
            color: rgba(0, 0, 0, 0.05);
          }

          &.selected {
            background: rgba(0, 0, 0, 0.04);

            .marker {
              color: ${(props) => props.theme.palette.primary.main};
            }
          }
        }

        .sortHidden {
          position: absolute;

          top: 2rem;

          height: 1;
          width: 1;

          padding: 0;
          margin: -1;

          border: 0;

          clip: rect(0 0 0 0);

          overflow: hidden;
        }

        /* HACK to fix outline on react-virtualized generated table */
        .ReactVirtualized__Grid {
          outline: none;
        }

        .inner-grid .row {
          &:focus {
            outline: none;
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.07);

            .cell {
              &:not(.selected) {
                .marker {
                  color: rgba(0, 0, 0, 0.15);
                }
              }
            }
          }
        }
      }
    }
  }

  > .summary {
    flex: 0 0 auto;

    padding-top: 1.5rem;

    border-top: 1px solid ${(props) => props.theme.palette.divider};

    display: flex;
    justify-content: space-between;
    align-items: center;

    > .selected {
      flex: 0 0 auto;

      padding: 0 2rem 0 0.2rem;

      > .clear-button {
        margin-left: 1rem;
      }
    }

    > .download {
      flex: 0 0 auto;

      text-align: right;

      > .total {
        margin-right: ${(props) => props.theme.spacing(1) / BASE_FONT_SIZE}rem;
      }
    }
  }
`;

export default DataGrid;
