import styled from 'styled-components';

// Modal
export const AudienceFilterModal = styled.div``;

// Component styles
const AudienceSummary = styled.div`
  > header {
    display: flex;
    align-items: center;

    padding-bottom: 1.4rem;

    .left {
      flex: 1 1 auto;
    }

    .right {
      flex: 0 0 auto;
    }
  }

  > .summary {
    > .item {
      &:not(:first-of-type) {
        margin-top: 1.6rem;
      }

      > p {
        margin: 0;

        font-size: 1.6rem;
        font-weight: 400;
      }

      > .chip {
        margin: 0.4rem 0.4rem 0 0;

        border: 1px solid rgba(255, 255, 255, 0.3);

        color: #ffffff;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
`;

export default AudienceSummary;
