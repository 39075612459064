import React, { useState, useEffect, memo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Clear from '@material-ui/icons/Clear';
import Tune from '@material-ui/icons/Tune';
import useTheme from '@material-ui/core/styles/useTheme';

import { DateTimePanel } from './Panel/DateTimePanel';
import { FilterPanel } from './Panel/FilterPanel';
import { DataGrid } from './DataGrid';

import Logout from '../../Auth/Logout';
import Logo from '../../Assets/Images/Logo';
import ChangeDashboard from '../../Assets/Images/ChangeDashboard';

import { serializeCatchmentQueryString } from '../../../lib/queryString';
import { queryCatchment } from '../../../lib/smartStepsApi';
import { AggregationLevel } from '../../../lib/gridResults';
import { appInsights } from '../../../lib/insights';
import { RefinementState } from '../../../types/refinement';
import { ApiCatchmentDataState } from '../../../types/apiData';
import { CatchmentFilter } from '../../../types/smartStepsApi';
import { ViewStatus } from '../../../types/global';

import { AsideStyles, DashboardStyles } from './Dashboard.styles';
import { StyledPaper } from '../../../styles/MaterialComponents';
import { HeatMap } from './Map';

const GridMemo = memo(DataGrid);
const HeatMapMemo = memo(HeatMap);
const DateTimePanelMemo = memo(DateTimePanel);
const FilterPanelMemo = memo(FilterPanel);

type AggregationSelections = {
  [AggregationLevel.NUTS1]: string[];
  [AggregationLevel.NUTS2]: string[];
  [AggregationLevel.NUTS3]: string[];
  [AggregationLevel.Area]: string[];
  [AggregationLevel.District]: string[];
  [AggregationLevel.Sector]: string[];
};

const initialAggregationSelections: AggregationSelections = {
  [AggregationLevel.NUTS1]: [],
  [AggregationLevel.NUTS2]: [],
  [AggregationLevel.NUTS3]: [],
  [AggregationLevel.Area]: [],
  [AggregationLevel.District]: [],
  [AggregationLevel.Sector]: [],
};

type DashboardProps = {
  initialQuery: CatchmentFilter;
  refinement: RefinementState;
};

export const Dashboard: React.FC<DashboardProps> = ({ initialQuery, refinement }) => {
  const [apiData, setApiData] = useState<ApiCatchmentDataState>({
    loading: false,
    count: 0,
    results: [],
  });
  const [dataFilter, setDataFilter] = useState<CatchmentFilter>(initialQuery);
  const [aggregationSelections, setAggregationSelections] = useState(initialAggregationSelections);
  const [hovered, setHovered] = useState<string>();
  const [currentSelected, setCurrentSelected] = useState<string[]>(
    initialAggregationSelections[refinement.aggregationLevel]
  );
  const [lastSelected, setLastSelected] = useState<string>();
  const [viewStatus, setViewStatus] = useState<ViewStatus>();

  const [sizeMap, setSizeMap] = useState<boolean>(true);

  // Handlers
  function handleMapSize() {
    if (viewStatus !== 'mini') {
      setSizeMap(!sizeMap);
    }
  }

  function onSubmit(day: Date) {
    setDataFilter((prevState) => ({ ...prevState, day }));
  }

  const toggleSelected = (value: string) => {
    const currentSelected = aggregationSelections[refinement.aggregationLevel];
    const newSelected = aggregationSelections;

    if (currentSelected.includes(value)) {
      setLastSelected(undefined);

      newSelected[refinement.aggregationLevel] = currentSelected.filter((s) => s !== value);
    } else {
      setLastSelected(value);

      newSelected[refinement.aggregationLevel] = [...currentSelected, value];
    }

    setCurrentSelected(newSelected[refinement.aggregationLevel]);
    setAggregationSelections(newSelected);
  };

  const clearSelected = () => {
    const newSelected = aggregationSelections;

    newSelected[refinement.aggregationLevel] = [];

    setLastSelected(undefined);
    setCurrentSelected(newSelected[refinement.aggregationLevel]);
    setAggregationSelections(newSelected);
  };

  const theme = useTheme();
  const miniView = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    miniView ? setViewStatus('mini') : setViewStatus('full');
  }, [miniView]);

  useEffect(() => {
    setAggregationSelections(initialAggregationSelections);
  }, [dataFilter]);

  useEffect(() => {
    // Update query string
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${serializeCatchmentQueryString({
        query: dataFilter,
        refinement,
      })}`
    );
  }, [dataFilter, refinement]);

  useEffect(() => {
    // Query API
    setApiData((data) => ({ ...data, loading: true }));

    queryCatchment(dataFilter)
      .then((data) => {
        setApiData({
          ...data,
          loading: false,
          results: Object.values(data.results)
            .flat()
            .filter((x) => x.origin),
        });
      })
      .catch((err) => {
        appInsights?.trackException({ exception: err });
        setApiData(() => ({ count: 0, results: [], loading: false }));
      });
  }, [dataFilter]);

  return (
    <DashboardStyles viewStatus={viewStatus} mapSize={sizeMap}>
      <AsideStyles viewStatus={viewStatus}>
        <div className="catchment-summary">
          <div className="logo-container">
            <div className="brand-logo">
              <Logo size="md" align="left" viewStatus={viewStatus} linkToHome />
            </div>
            {viewStatus === 'mini' ? (
              <div id="open-btn">
                <Tune
                  onClick={() => {
                    setViewStatus('full-with-screen');
                  }}
                />
              </div>
            ) : null}
          </div>

          <div className="scroller">
            <div className="scroller-content">
              <div className="date-and-time">
                <DateTimePanelMemo dataFilter={dataFilter} onSubmit={onSubmit} />
              </div>
              <div className="filter-summary">
                <FilterPanelMemo
                  catchmentFilter={dataFilter}
                  onChange={(catchmentFilter) => {
                    setDataFilter((filter) => ({ ...filter, ...catchmentFilter }));
                  }}
                />
              </div>
              <ChangeDashboard linkTo="profiles" />
              <ChangeDashboard linkTo="audience" />
            </div>
          </div>
        </div>
        {viewStatus === 'full-with-screen' ? (
          <>
            <div id="close-btn">
              <Clear
                onClick={() => {
                  setViewStatus('mini');
                }}
              />
            </div>
            <div
              className="screen"
              onClick={() => {
                setViewStatus('mini');
              }}
            ></div>
          </>
        ) : null}
      </AsideStyles>
      <main className="motion-bg">
        <div className="content">
          <div className="sentence-and-grid">
            <div className="grid">
              <StyledPaper className="gridPaper">
                <GridMemo
                  apiData={apiData}
                  refinement={refinement}
                  setHovered={setHovered}
                  selected={currentSelected}
                  toggleSelected={toggleSelected}
                  clearSelected={clearSelected}
                  lastSelected={lastSelected}
                  resize={sizeMap}
                />
              </StyledPaper>
            </div>
          </div>
          <div className="map">
            <HeatMapMemo
              aggregationLevel={refinement.aggregationLevel}
              filter={refinement.filter}
              apiData={apiData}
              selected={currentSelected}
              setSelected={toggleSelected}
              hovered={hovered}
              onMapSizeChange={handleMapSize}
              resize={sizeMap}
              viewStatus={viewStatus}
            />
          </div>
        </div>
        <div className="login">
          <Logout />
        </div>
      </main>
    </DashboardStyles>
  );
};
