import React from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import PaperWithHeadingStyles from './PaperWithHeading.styles';

type Props = {
  label: string;
  isLoading: boolean;
};

const PaperWithHeading: React.FC<Props> = (props) => (
  <PaperWithHeadingStyles>
    <Paper className="paper">
      <h4>{props.label}</h4>

      <div className="paper-content">
        {props.isLoading ? (
          <div className="paper-loading">
            <CircularProgress size="2rem" color="secondary" />
          </div>
        ) : (
          props.children
        )}
      </div>
    </Paper>
  </PaperWithHeadingStyles>
);

export default PaperWithHeading;
