import React from 'react';
import { Snackbar, IconButton, Button, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Types
interface Props<T> {
  selected?: T[];
  snackbarOpen: boolean;
  setSnackbarOpen: (b: boolean) => void;
}

const CustomSnackbar = <T extends string>({ snackbarOpen, setSnackbarOpen }: Props<T>) => {
  const handleSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <SnackbarContent
        message="Please select at least 1 location"
        style={{ backgroundColor: '#010034', color: '#ffffff', fontWeight: 600 }}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleSnackbarClose}>
              UNDO
            </Button>

            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Snackbar>
  );
};

export default CustomSnackbar;
