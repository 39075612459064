import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import {
  formatTimeOfDay,
  calculateTimeTo,
  defaultDates,
  defaultVisitType,
  defaultQueryInterest,
  defaultDate,
} from './filter';
import {
  FromSingleHours,
  ToSingleHours,
  Gender,
  VisitType,
  QueryInterest,
  TimeOfDay,
  AudienceQuery,
  ProfilesQuery,
  isLocations,
  isGender,
  isTimeOfDay,
  isDateRange,
  isAgeBands,
  isSpendingPowers,
  isInterests,
  isVisitType,
  isQueryInterest,
  Interest,
  CatchmentFilter,
  isDay,
} from '../types/smartStepsApi';
import queryString from 'query-string';
import { RefinementState } from '../types/refinement';
import {
  defaultCatchmentAggregationLevel,
  defaultAudienceAggregationLevel,
  AggregationLevel,
} from './gridResults';
import { SUB_INTERESTS } from '../constants/filter';

export interface AudienceQueryStringState {
  query: AudienceQuery;
  refinement: RefinementState;
}

export interface CatchmentQueryStringState {
  query: CatchmentFilter;
  refinement: RefinementState;
}

const dataToArray = (data: string | string[]) => (Array.isArray(data) ? data : [data]);

const parseTimeOfDay = (query: queryString.ParsedQuery<string>): TimeOfDay => {
  if (query.startTime && query.endTime) {
    const parsedStartTime = parseInt(query.startTime.toString());
    const parsedEndTime = parseInt(query.endTime.toString());
    const parsedTimeOfDay = formatTimeOfDay(
      parsedStartTime as FromSingleHours,
      parsedEndTime as ToSingleHours
    );

    if (isTimeOfDay(parsedTimeOfDay)) {
      return parsedTimeOfDay;
    }
  }

  return {
    startHour: 0,
    hours: 24,
  };
};

const parseDateRange = (
  query: queryString.ParsedQuery<string>,
  authorized: boolean | undefined
) => {
  if (query.startDate && query.endDate) {
    const parsedStartDate = parseISO(query.startDate.toString() + 'T00:00:00Z');
    const parseEndDate = parseISO(query.endDate.toString() + 'T00:00:00Z');
    const dateRange = {
      startDate: parsedStartDate,
      endDate: parseEndDate,
    };

    if (isDateRange(dateRange)) {
      return dateRange;
    }
  }

  return defaultDates(authorized);
};

const parseDay = (query: queryString.ParsedQuery<string>, authorized: boolean | undefined) => {
  if (query.day) {
    const parsedDate = parseISO(query.day + 'T00:00:00Z');

    if (isDay(parsedDate)) {
      return parsedDate;
    }
  }
  return defaultDate(authorized);
};

const parseLocations = (query: queryString.ParsedQuery<string>): string[] => {
  if (query.locations) {
    const parsedLocations = dataToArray(query.locations);

    if (isLocations(parsedLocations)) {
      return parsedLocations;
    }
  }
  return [];
};

const parseVisitType = (query: queryString.ParsedQuery<string>): VisitType => {
  const visitType = (query.visitType || '').toString();

  if (isVisitType(visitType)) {
    return visitType;
  }
  return defaultVisitType;
};

const parseGender = (query: queryString.ParsedQuery<string>): Gender => {
  const gender = query.gender;
  if (gender && isGender(gender)) {
    return gender;
  }
  return '*';
};

const parseAgeBand = (query: queryString.ParsedQuery<string>) => {
  if (query.ageBand) {
    const parsedAgeBands = dataToArray(query.ageBand);

    if (isAgeBands(parsedAgeBands)) {
      return parsedAgeBands;
    }
  }
  return [];
};

const parseSpendingPower = (query: queryString.ParsedQuery<string>) => {
  if (query.spendingPower) {
    const parsedSpendingPowers = dataToArray(query.spendingPower);

    if (isSpendingPowers(parsedSpendingPowers)) {
      return parsedSpendingPowers;
    }
  }
  return [];
};

const parseInterests = (query: queryString.ParsedQuery<string>) => {
  if (query.interests) {
    const parsedInterests = dataToArray(query.interests);

    if (isInterests(parsedInterests)) {
      return parsedInterests;
    }
  }
  return [];
};

const parseQuerySubInterests = (query: queryString.ParsedQuery<string>, interests: Interest[]) => {
  if (query.interests) {
    const parsedSubInterests = !query.subInterests ? [] : dataToArray(query.subInterests);
    const possibleSubInterests = new Set(
      interests
        .map((i) => SUB_INTERESTS.get(i)!)
        .flat()
        .map((v) => v.value)
    );
    return parsedSubInterests.filter((s) => possibleSubInterests.has(s));
  }
  return [];
};

const parseQueryInterest = (query: queryString.ParsedQuery<string>): QueryInterest => {
  const queryInterest = (query.queryInterest || '').toString();

  if (isQueryInterest(queryInterest)) {
    return queryInterest;
  }
  return defaultQueryInterest;
};

const parseAggregateionLevel = (query: queryString.ParsedQuery<string>, catchment?: boolean) => {
  if (query.level) {
    const parsedLevel = parseInt(query.level.toString());

    if (Object.values(AggregationLevel).includes(parsedLevel)) {
      return parsedLevel;
    }
  }
  return catchment ? defaultCatchmentAggregationLevel : defaultAudienceAggregationLevel;
};

const parseFilter = (query: queryString.ParsedQuery<string>) => {
  if (query.filter) {
    return query.filter.toString();
  }
  return '';
};

// Audience
export const parseAudienceQueryString = (
  search: string,
  authorized: boolean | undefined
): AudienceQueryStringState => {
  // Parse query string
  const query = queryString.parse(search, {
    arrayFormat: 'comma',
  });

  const visitType = parseVisitType(query);

  // Optional fields
  const dateRange = parseDateRange(query, authorized);
  const timeOfDay = parseTimeOfDay(query);
  const gender = parseGender(query);
  const ageBand = parseAgeBand(query);
  const spendingPower = parseSpendingPower(query);
  const interests = parseInterests(query);
  const subInterests = parseQuerySubInterests(query, interests);
  const queryInterest = parseQueryInterest(query);
  const aggregationLevel = parseAggregateionLevel(query, false);
  const filter = parseFilter(query);

  // All good
  return {
    query: {
      visitType,
      dateRange,
      timeOfDay,
      gender,
      ageBand,
      spendingPower,
      interests,
      subInterests,
      queryInterest,
    },
    refinement: {
      aggregationLevel,
      filter,
    },
  };
};

export function serializeAudienceQueryString({
  query,
  refinement,
}: AudienceQueryStringState): string {
  const prepared = {
    visitType: query.visitType,
    startDate: format(query.dateRange.startDate, 'yyyy-MM-dd'),
    startTime: query.timeOfDay.startHour.toString(),
    endDate: format(query.dateRange.endDate, 'yyyy-MM-dd'),
    endTime: calculateTimeTo(query.timeOfDay),
    gender: query.gender === '*' ? undefined : query.gender,
    ageBand: query.ageBand,
    spendingPower: query.spendingPower,
    interests: query.interests,
    subInterests: query.subInterests,
    level:
      refinement.aggregationLevel !== defaultAudienceAggregationLevel
        ? refinement.aggregationLevel
        : undefined,
    filter: refinement.filter !== '' ? refinement.filter : undefined,
  };

  return queryString.stringify(prepared, { arrayFormat: 'comma' });
}

// Profiles
export const parseProfilesQueryString = (
  search: string,
  authorized: boolean | undefined
): ProfilesQuery => {
  // Parse query string
  const query = queryString.parse(search, {
    arrayFormat: 'comma',
  });

  const locations = parseLocations(query);
  const visitType = parseVisitType(query);

  // Optional fields
  const dateRange = parseDateRange(query, authorized);
  const timeOfDay = parseTimeOfDay(query);
  const gender = parseGender(query);
  const ageBand = parseAgeBand(query);
  const interests = parseInterests(query);
  const subInterests = parseQuerySubInterests(query, interests);
  const spendingPower = parseSpendingPower(query);
  const queryInterest = parseQueryInterest(query);
  // All good
  return {
    locations,
    visitType,
    dateRange,
    timeOfDay,
    gender,
    ageBand,
    spendingPower,
    interests,
    queryInterest,
    subInterests,
  };
};

export function serializeProfilesQueryString(query: ProfilesQuery): string {
  const prepared = {
    locations: query.locations,
    visitType: query.visitType,
    startDate: format(query.dateRange.startDate, 'yyyy-MM-dd'),
    startTime: query.timeOfDay.startHour.toString(),
    endDate: format(query.dateRange.endDate, 'yyyy-MM-dd'),
    endTime: calculateTimeTo(query.timeOfDay),
    gender: query.gender === '*' ? undefined : query.gender,
    ageBand: query.ageBand,
    spendingPower: query.spendingPower,
    interests: query.interests,
    subInterests: query.subInterests,
  };

  return queryString.stringify(prepared, { arrayFormat: 'comma' });
}

//Catchment
export const parseCatchmentQueryString = (
  search: string,
  authorized: boolean | undefined
): CatchmentQueryStringState => {
  // Parse query string
  const query = queryString.parse(search, {
    arrayFormat: 'comma',
  });

  const day = parseDay(query, authorized);
  const locations = parseLocations(query);
  const aggregationLevel = parseAggregateionLevel(query, true);
  const filter = parseFilter(query);

  // All good
  return {
    query: {
      day,
      locations,
    },
    refinement: {
      aggregationLevel,
      filter,
    },
  };
};

export function serializeCatchmentQueryString({
  query,
  refinement,
}: CatchmentQueryStringState): string {
  const prepared = {
    day: format(query.day, 'yyyy-MM-dd'),
    locations: query.locations,
    filter: refinement.filter !== '' ? refinement.filter : undefined,
  };

  return queryString.stringify(prepared, { arrayFormat: 'comma' });
}
