import React from 'react';
import Typography from '@material-ui/core/Typography';

import Logo from '../Assets/Images/Logo';

import PortraitStyles from './Portrait.styles';

const Portrait = () => (
  <PortraitStyles>
    <Logo size="lg" align="center" />
    <Typography variant="h5">Please rotate your device to a landscape view.</Typography>
  </PortraitStyles>
);

export default Portrait;
