import React, { useContext } from 'react';
import { RouteComponentProps, WindowLocation } from '@reach/router';

import { parseProfilesQueryString } from '../../lib/queryString';
import { defaultDates } from '../../lib/filter';
import { ProfilesQuery } from '../../types/smartStepsApi';

import { isProfilesFilter } from '../../types/smartStepsApi';

import Dashboard from './Dashboard/Dashboard';
import { withTracking } from '../../lib/insights';
import { AuthContext } from '../Auth/AuthProvider';

const getInitialState = (
  location: WindowLocation | undefined,
  authorized: boolean | undefined,
  client: string | undefined
): ProfilesQuery => {
  if (location === undefined) {
    return parseProfilesQueryString('', authorized);
  }

  if (isProfilesFilter(location.state)) {
    return {
      ...location.state,
      dateRange: defaultDates(authorized, client),
      timeOfDay: { startHour: 0, hours: 24 },
    };
  }

  return parseProfilesQueryString(location.search, authorized);
};

const DashboardRoute: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { state: authState } = useContext(AuthContext);
  const initialState = getInitialState(
    props.location,
    authState?.isAuthorized,
    authState?.username
  );

  return <Dashboard initialQuery={initialState} />;
};

export default withTracking(DashboardRoute);
