import React, { useState, useEffect } from 'react';
import Link from '@material-ui/core/Link';
import { Bar } from '@nivo/bar';
import { AutoSizer } from 'react-virtualized';

import PaperWithHeading from '../../Assets/Layout/PaperWithHeading';

import {
  GenderAgeBandResult,
  AgeBandResult,
  ProfilesFilter,
  Gender,
} from '../../../types/smartStepsApi';
import { capitaliseText } from '../../../lib/helpers';

import { themeA, o2colorsGender, o2colors } from '../../../styles/NivoTheme';
import GenderAgeBandStyles from './GenderAgeBand.styles';

// Types
type Props = {
  label: string;
  data?: GenderAgeBandResult;
  search?: Gender;
  isLoading: boolean;
  visitType: ProfilesFilter['visitType'] | undefined;
  ppl: number;
};

// Helpers
type FormattedData = { [key: string]: number | string };

const formatDataKey = (key: string): string => {
  if (key === '6599') {
    return '65+';
  }
  return key.substr(0, 2) + '-' + key.substr(2, 2);
};

const formatDataKeys = (r?: AgeBandResult) => {
  const newObj: { [key: string]: number } = {};

  if (r) {
    Object.entries(r).forEach((e) => {
      if (e[1] !== undefined) {
        newObj[formatDataKey(e[0])] = e[1];
      }
    });
  }
  return newObj;
};

export const formatData = (data: GenderAgeBandResult): FormattedData[] => {
  const combiArr: FormattedData[] = [];

  if (data.m) {
    const maleObj = { ...formatDataKeys(data.m) };

    Object.entries(maleObj).forEach(([key, val]) => {
      const combiIndex = combiArr.findIndex((r) => r['Age Band'] === key);

      if (combiIndex !== -1) {
        combiArr[combiIndex].Male = val;
      } else {
        combiArr.push({
          'Age Band': key,
          Male: val,
          Female: 0,
        });
      }
    });
  }

  if (data.f) {
    const femaleObj = { ...formatDataKeys(data.f) };

    Object.entries(femaleObj).forEach(([key, val]) => {
      const combiIndex = combiArr.findIndex((r) => r['Age Band'] === key);

      if (combiIndex !== -1) {
        combiArr[combiIndex].Female = val;
      } else {
        combiArr.push({
          'Age Band': key,
          Male: 0,
          Female: val,
        });
      }
    });
  }

  return combiArr;
};

const formatCombiData = (data: GenderAgeBandResult): FormattedData[] => {
  const combiArr: FormattedData[] = [];

  if (data.m) {
    const maleObj = { ...formatDataKeys(data.m) };

    Object.entries(maleObj).forEach(([key, val]) => {
      const combiIndex = combiArr.findIndex((r) => r['Age Band'] === key);

      if (combiIndex !== -1) {
        combiArr[combiIndex].Value = (combiArr[combiIndex].Value as number) + val;
      } else {
        combiArr.push({
          'Age Band': key,
          Value: val,
        });
      }
    });
  }

  if (data.f) {
    const femaleObj = { ...formatDataKeys(data.f) };

    Object.entries(femaleObj).forEach(([key, val]) => {
      const combiIndex = combiArr.findIndex((r) => r['Age Band'] === key);

      if (combiIndex !== -1) {
        combiArr[combiIndex].Value = (combiArr[combiIndex].Value as number) + val;
      } else {
        combiArr.push({
          'Age Band': key,
          Value: val,
        });
      }
    });
  }

  return combiArr;
};

// Component
const AgeBand: React.FC<Props> = (props) => {
  const ppl = props.ppl;
  const formattedData = (props.data && formatData(props.data)) || [];
  const formattedCombiData = (props.data && formatCombiData(props.data)) || [];

  // Local States
  const [ageBandView, setAgeBandView] = useState<'combi' | 'gender'>('combi');
  const [containerHeight, setContainerHeight] = useState(0);

  // Effects
  useEffect(() => {
    if (window.innerHeight) {
      setContainerHeight(window.innerHeight - window.innerHeight * 0.65);
    }
  }, [containerHeight]);

  // Render
  return (
    <PaperWithHeading label={props.label} isLoading={props.isLoading}>
      <GenderAgeBandStyles containerHeight={containerHeight} includeFilter={props.search === '*'}>
        {props.search && props.search === '*' && (
          <div className="filter">
            <Link
              component="button"
              disabled={ageBandView === 'combi'}
              className={ageBandView === 'combi' ? 'active' : undefined}
              underline="none"
              onClick={() => setAgeBandView('combi')}
            >
              Grouped
            </Link>
            <Link
              component="button"
              disabled={ageBandView === 'gender'}
              className={ageBandView === 'gender' ? 'active' : undefined}
              underline="none"
              onClick={() => setAgeBandView('gender')}
            >
              Gender Split
            </Link>
          </div>
        )}

        <div className="chart" style={{ height: containerHeight }}>
          <AutoSizer>
            {({ height, width }) => (
              <Bar
                height={height}
                width={width}
                data={ageBandView === 'gender' ? formattedData : formattedCombiData}
                theme={themeA}
                keys={ageBandView === 'gender' ? ['Female', 'Male'] : ['Value']}
                indexBy="Age Band"
                margin={{ top: props.search === '*' ? 20 : 30, right: 20, bottom: 55, left: 65 }}
                padding={0.12}
                colors={(bar) =>
                  bar.id === 'Value' ? o2colors[bar.indexValue] : o2colorsGender[bar.id]
                }
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 8,
                  tickRotation: 0,
                  legend: 'Age Band',
                  legendPosition: 'middle',
                  legendOffset: 45,
                }}
                axisLeft={{
                  tickSize: 0,
                  tickPadding: 8,
                  tickRotation: 0,
                  legend: props.visitType ? `${capitaliseText(props.visitType)}s` : 'People',
                  legendPosition: 'middle',
                  legendOffset: -55,
                  format: '~s',
                }}
                enableGridY={true}
                label={(d) =>
                  (typeof d.value === 'number' ? ((d.value / ppl) * 100).toFixed(1) : d.value) + '%'
                }
                labelSkipWidth={12}
                labelSkipHeight={12}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                tooltip={(data) => (
                  <div className="tooltip-container">
                    <h5>
                      {data.id !== 'Value' && `${data.id} - `}
                      {data.indexValue}
                    </h5>
                    <p className="people">{data.value.toLocaleString()}</p>
                    <p className="people-label">
                      {props.visitType ? `${capitaliseText(props.visitType)}s` : '-'}
                    </p>
                    <p className="percent">{((data.value / ppl) * 100).toFixed(1)} %</p>
                  </div>
                )}
              />
            )}
          </AutoSizer>
        </div>
      </GenderAgeBandStyles>
    </PaperWithHeading>
  );
};

export default AgeBand;
