import styled from 'styled-components';

export default styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;

  height: 100%;

  padding: 2rem 0;

  > h5 {
    margin: 0 0 0.4rem;

    font-size: 4rem;
    line-height: 4rem;
    font-weight: 300;
  }

  > h6 {
    margin: 0 0 0 0.6rem;

    font-size: 1.4rem;
    text-transform: capitalize;
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;
