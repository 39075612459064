import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { ComponentType } from 'react';

export let appInsights: ApplicationInsights | undefined;
let reactPlugin: ReactPlugin | undefined;

if (process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY) {
  const browserHistory = createBrowserHistory({ basename: '' });
  reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
}

export const withTracking = <T>(Component: ComponentType<T>) =>
  reactPlugin ? withAITracking(reactPlugin, Component) : Component;
