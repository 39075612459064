import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import format from 'date-fns/format';
import debounce from 'lodash/debounce';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  getNextDateRange,
  getPrevDateRange,
  getRangeLength,
  printDateRangeLength,
} from '../../lib/filter';
import { DateRange, Interval, TimeOfDay } from '../../types/smartStepsApi';
import { IntervalModal } from '../Assets/Form/DateTimeModal';
import { AuthContext } from '../Auth/AuthProvider';
import { DateTimeSummaryStyles } from './DateTimeSummary.styles';
import { CLIENT, START_DATE, CURRENT_MONTH } from '../../constants/filter';

const authorizedIntervals: DateRange[] = [
  { startDate: new Date('2023-04-04'), endDate: new Date('2023-04-04') },
  { startDate: new Date(START_DATE), endDate: new Date(CURRENT_MONTH[1]) },
];

const clientAuthorizedIntervals: DateRange[] = [
  // Define specific intervals for CLIENT here
  { startDate: new Date(START_DATE), endDate: new Date(CURRENT_MONTH[1]) }, // Replace with the desired date range
];

const demoIntervals: DateRange[] = [
  { startDate: new Date('2023-04-04'), endDate: new Date('2023-04-04') },
];

export function getInterval(authorized: boolean | undefined, client: string | undefined) {
  if (!!authorized && client && CLIENT.includes(client)) {
    return clientAuthorizedIntervals;
  } else if (!!authorized) {
    return authorizedIntervals;
  } else {
    return demoIntervals;
  }
}

// Types
interface Props {
  interval: Interval;
  onSubmit: (interval: Interval) => void;
}

const formatHour = (hour: number) => {
  if (hour === 0 || hour === 24) {
    return '12am';
  }
  if (hour < 12) {
    return `${hour.toString()}am`;
  }
  if (hour === 12) {
    return '12pm';
  }
  return `${hour - 12}pm`;
};
const formatTimeFromHour = (hour: number) => hour.toString().padStart(2, '0') + ':00';
const formatTimeOfDay = (tod: TimeOfDay) => {
  switch (tod.hours) {
    case 24:
      return <abbr title="00:00 - 24:00">All Day</abbr>;
    default: {
      const startHour = tod.startHour;
      const endHour = startHour + tod.hours;
      return (
        <span>
          <abbr title={formatTimeFromHour(startHour)}>{formatHour(startHour)}</abbr>
          {' - '}
          <abbr title={formatTimeFromHour(endHour)}>{formatHour(endHour)}</abbr>
        </span>
      );
    }
  }
};

// Component
const DateTimeSummary = (props: Props) => {
  // Local state
  const [showModal, setShowModal] = useState(false);
  const [dateRange, setDateRange] = useState(props.interval.dateRange);
  const { state: authState } = useContext(AuthContext);
  const intervals = useMemo(() => getInterval(authState?.isAuthorized, authState?.username), []);
  // Refs
  const onSubmitDebounced = useRef(debounce(props.onSubmit, 500));

  // Effects
  useEffect(() => {
    setDateRange(props.interval.dateRange);
  }, [props.interval.dateRange]);

  // Helpers
  const rangeLength = getRangeLength(dateRange);

  const navigate = (amount: number) => {
    setDateRange((currentDateRange) => {
      const newDateRange: DateRange =
        amount === 1
          ? getNextDateRange(intervals, currentDateRange)!
          : getPrevDateRange(intervals, currentDateRange)!;
      onSubmitDebounced.current({
        timeOfDay: props.interval.timeOfDay,
        dateRange: newDateRange,
      });
      return newDateRange;
    });
  };

  // the behavior depends on the size of the range!!
  const handlePrev = () => {
    navigate(-1);
  };

  const handleNext = () => {
    navigate(1);
  };

  return (
    <DateTimeSummaryStyles>
      <header>
        <div className="left">
          <h3>Date & Time</h3>
        </div>
        <div className="right">
          <IconButton
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={() => setShowModal(true)}
          >
            <Edit />
          </IconButton>
        </div>
      </header>

      <div className="date">
        <div className="range">
          <IconButton
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={handlePrev}
            disabled={!getPrevDateRange(intervals, dateRange)}
          >
            <NavigateBefore fontSize="large" />
          </IconButton>
        </div>

        <div className="content">
          <span className="date-from">
            <abbr title={dateRange.startDate.toUTCString()}>
              {format(dateRange.startDate, 'd MMM y')}
            </abbr>
          </span>
          <span className="date-to">
            <abbr title={dateRange.endDate.toUTCString()}>
              {format(dateRange.endDate, 'd MMM y')}
            </abbr>
          </span>
        </div>

        <div className="range">
          <IconButton
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={handleNext}
            disabled={!getNextDateRange(intervals, dateRange)}
          >
            <NavigateNext fontSize="large" />
          </IconButton>
        </div>
      </div>

      <div className="summary">
        <span className="range">{printDateRangeLength(rangeLength)}</span>
        <span className="time">{formatTimeOfDay(props.interval.timeOfDay)}</span>
      </div>

      {showModal ? (
        <IntervalModal
          interval={props.interval}
          allowedDateRanged={intervals}
          onCancel={() => setShowModal(false)}
          onSubmit={(interval) => {
            setShowModal(false);

            props.onSubmit(interval);
          }}
        />
      ) : null}
    </DateTimeSummaryStyles>
  );
};

export default DateTimeSummary;
