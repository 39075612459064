import React, { useState } from 'react';
import { Button, Dialog, useMediaQuery, useTheme } from '@material-ui/core';

import { CatchmentFields } from '../../Filter';
import CustomSnackbar from '../../../Assets/Form/CustomSnackbar';
import { defaultCatchmentFilter } from '../../../../lib/filter';
import { CatchmentFilter as CatchmentFilterState } from '../../../../types/smartStepsApi';
import { StyledDialogActions, StyledDialogContent } from '../../../../styles/MaterialComponents';

type ModalProps = {
  catchmentFilter: CatchmentFilterState;
  onCancel: () => void;
  onSubmit: (audienceFilter: CatchmentFilterState) => void;
};

export const Modal: React.FC<ModalProps> = ({ catchmentFilter, onSubmit, onCancel }) => {
  const [catchment, setCatchment] = useState(catchmentFilter);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleDefault = () => {
    setCatchment(defaultCatchmentFilter);
  };

  const handleSubmit = () => {
    if (!catchment.locations.length) {
      setSnackbarOpen(true);
    } else {
      onSubmit(catchment);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => onCancel()}
      fullScreen={fullScreen}
      maxWidth="xs"
      className="dialog"
    >
      <CustomSnackbar snackbarOpen={snackbarOpen} setSnackbarOpen={(r) => setSnackbarOpen(r)} />
      <StyledDialogContent className="overrideScroll">
        <CatchmentFields catchmentFilter={catchment} includeHelp={false} onChange={setCatchment} />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleDefault} color="secondary">
          Default
        </Button>
        <div className="groupedButtons">
          <Button onClick={() => onCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Apply
          </Button>
        </div>
      </StyledDialogActions>
    </Dialog>
  );
};
