import styled from 'styled-components';

export const IntervalModalPanelStyles = styled.div`
  .spacingTop {
    margin-top: 1em;
  }
  .demo-user-note {
    font-size: 1.2rem;
    color: ${(props) => props.theme.palette.warning.main};
  }
`;
