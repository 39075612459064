import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import CustomSnackbar from '../../Assets/Form/CustomSnackbar';

import { defaultProfilesFilter } from '../../../lib/filter';

import { ProfilesFilter as ProfilesFilterState } from '../../../types/smartStepsApi';

import { StyledDialogActions, StyledDialogContent } from '../../../styles/MaterialComponents';

import { ProfilesFields } from './Fields';

// Types
interface Props {
  profilesFilter: ProfilesFilterState;
  onCancel: () => void;
  onSubmit: (audienceFilter: ProfilesFilterState) => void;
}

const Modal: React.FC<Props> = ({ profilesFilter, onSubmit, onCancel }: Props) => {
  const [profiles, setProfiles] = useState(profilesFilter);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleDefault = () => {
    // Reset audience to default
    setProfiles(defaultProfilesFilter);
  };

  const handleSubmit = () => {
    //Check if at lease 1 location is selected
    if (!profiles.locations.length) {
      setSnackbarOpen(true);
    } else {
      // Continue to parent
      onSubmit(profiles);
    }
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={true}
      onClose={() => onCancel()}
      fullScreen={fullScreen}
      maxWidth="xs"
      className="dialog"
    >
      <CustomSnackbar snackbarOpen={snackbarOpen} setSnackbarOpen={(r) => setSnackbarOpen(r)} />

      <StyledDialogContent className="overrideScroll">
        <ProfilesFields profilesFilter={profiles} includeHelp={false} onChange={setProfiles} />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleDefault} color="secondary">
          Default
        </Button>
        <div className="groupedButtons">
          <Button onClick={() => onCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Apply
          </Button>
        </div>
      </StyledDialogActions>
    </Dialog>
  );
};

export default Modal;
