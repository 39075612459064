import React from 'react';

import { ChangeDashboardProps } from '../../../types/global';

import Button from '@material-ui/core/Button';

import { navigate } from '@reach/router';

function ChangeDashboard(props: ChangeDashboardProps) {
  let label = props.linkTo === 'audience' ? 'Audiences' : 'Postcodes';

  if (props.linkTo === 'catchment') {
    label = `Catchment`;
  }

  // check https://material-ui.com/es/api/button/
  return (
    <Button
      variant="contained"
      //color="secondary"
      // color="inherit"
      color="primary"
      size="small"
      style={{
        marginTop: '2em',
        color: 'white',
      }}
      onClick={() => navigate(`/${props.linkTo}`)}
    >
      {label}
    </Button>
  );
}

export default ChangeDashboard;
