import styled from 'styled-components';

const AudienceFilter = styled.div`
  .submitButton {
    margin-top: 3rem;

    text-align: center;
  }
`;

export default AudienceFilter;
