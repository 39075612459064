import React from 'react';
import { Redirect, Router } from '@reach/router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { ErrorBoundary } from './ErrorBoundary';
import { AuthProvider } from './Auth/AuthProvider';
import RequireLogin from './Auth/RequireLogin';
import Portrait from './Auth/Portrait';
import AudienceHome from './Audience/HomeRoute';
import AudienceDashboard from './Audience/DashboardRoute';
import ProfilesHome from './Profiles/HomeRoute';
import ProfilesDashboard from './Profiles/DashboardRoute';
import Home from './Home';
import Catchment, { CatchmentDashboard } from './Catchment';

const App: React.FC = () => (
  <>
    <Portrait />
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ErrorBoundary>
        <AuthProvider>
          <RequireLogin>
            <Router>
              <Home path="/" />
              <Redirect from="/sign-in" to="/" noThrow={true} />
              <AudienceHome path="audience" />
              <AudienceDashboard path="audience/dashboard" />
              <ProfilesHome path="profiles" />
              <ProfilesDashboard path="profiles/dashboard" />
              <Catchment path="catchment" />
              <CatchmentDashboard path="catchment/dashboard" />
            </Router>
          </RequireLogin>
        </AuthProvider>
      </ErrorBoundary>
    </MuiPickersUtilsProvider>
  </>
);

export default App;
