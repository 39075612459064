import React, { useState } from 'react';

import { CatchmentFilter as CatchmentFilterState } from '../../../../types/smartStepsApi';
import { FilterPanelStyles } from './FilterPanel.styles';
import { Chip, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Modal } from './Modal';

const chipArray = <T extends string>(items: T[], label?: (item: T) => string) => {
  if (items.length === 0) {
    return <p> - </p>;
  }

  const chips = items.map((item) => (
    <Chip
      key={item}
      label={label ? label(item) : item}
      variant="outlined"
      size="small"
      className="chip"
    />
  ));

  return chips;
};

type FilterPanelProps = {
  catchmentFilter: CatchmentFilterState;
  onChange: (catchmentFilter: CatchmentFilterState) => void;
};

export const FilterPanel: React.FC<FilterPanelProps> = (props) => {
  const { locations } = props.catchmentFilter;
  const [showEditModal, setShowEditModal] = useState(false);

  const handleSubmit = (catchmentFilter: CatchmentFilterState) => {
    setShowEditModal(false);
    props.onChange(catchmentFilter);
  };

  return (
    <FilterPanelStyles>
      <header>
        <div className="left">
          <h3>Catchment</h3>
        </div>
        <div className="right">
          <IconButton
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={() => setShowEditModal(true)}
          >
            <Edit />
          </IconButton>
        </div>
      </header>
      <div className="panel">
        <div className="item">
          <h4>Locations</h4>
          {chipArray(locations)}
        </div>
      </div>
      {showEditModal ? (
        <Modal
          catchmentFilter={props.catchmentFilter}
          onSubmit={handleSubmit}
          onCancel={() => setShowEditModal(false)}
        />
      ) : null}
    </FilterPanelStyles>
  );
};
