import styled from 'styled-components';

export const DateTimeSummaryStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  abbr {
    text-decoration: none;
  }

  header {
    display: flex;
    align-items: center;

    padding-bottom: 1.4rem;

    .left {
      flex: 1 1 auto;
    }

    .right {
      flex: 0 0 auto;
    }
  }

  > .date {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 300;

    > .range {
      flex: 0 0 auto;
    }

    > .content {
      flex: 0 0 auto;

      padding: 0 0.5rem;

      > span {
        display: block;

        text-align: center;
      }
    }
  }

  > .summary {
    display: flex;
    justify-content: space-between;

    padding: 1.6rem 1rem 0;

    > .range {
      flex: 1 1 auto;

      padding-right: 0.5rem;

      font-weight: 400;
    }
  }

  > .time {
    padding-top: 1.6rem;

    text-align: center;
    font-weight: 300;
  }
`;
