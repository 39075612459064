import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';

import Modal from '../Filter/Modal';

import {
  visitTypeLabel,
  genderLabel,
  ageBandLabel,
  spendingPowerLabel,
  interestLabel,
  subInterestLabel,
} from '../../../lib/filter';
import { ProfilesFilter as ProfilesFilterState } from '../../../types/smartStepsApi';

import FilterSummaryStyles from './FilterSummary.styles';

const chipArray = <T extends string>(items: T[], label?: (item: T) => string) => {
  if (items.length === 0) {
    return <p> - </p>;
  }

  const chips = items.map((item) => (
    <Chip
      key={item}
      label={label ? label(item) : item}
      variant="outlined"
      size="small"
      className="chip"
    />
  ));

  return chips;
};

// Types
interface Props {
  profilesFilter: ProfilesFilterState;
  onChange: (profilesFilter: ProfilesFilterState) => void;
}

// Component
const FilterSummary = (props: Props) => {
  const {
    locations,
    visitType,
    gender,
    ageBand,
    spendingPower,
    interests,
    subInterests,
  } = props.profilesFilter;

  const [showEditModal, setShowEditModal] = useState(false);

  // Handlers
  const handleSubmit = (profilesFilter: ProfilesFilterState) => {
    // Close modal
    setShowEditModal(false);

    // Update global state
    props.onChange(profilesFilter);
  };

  return (
    <FilterSummaryStyles>
      <header>
        <div className="left">
          <h3>Profile</h3>
        </div>
        <div className="right">
          <IconButton
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={() => setShowEditModal(true)}
          >
            <Edit />
          </IconButton>
        </div>
      </header>

      <div className="summary">
        <div className="item">
          <h4>Locations</h4>
          {chipArray(locations)}
        </div>

        <div className="item">
          <h4>Visitor Type</h4>
          <p>{visitTypeLabel(visitType)}</p>
        </div>

        <div className="item">
          <h4>Gender</h4>
          <p>{gender ? genderLabel(gender) : 'All'}</p>
        </div>

        <div className="item">
          <h4>Age Band</h4>
          {chipArray(ageBand, ageBandLabel)}
        </div>

        <div className="item">
          <h4>Spending Power</h4>
          {chipArray(spendingPower, spendingPowerLabel)}
        </div>

        <div className="item">
          <h4>Interests</h4>
          {chipArray(interests, interestLabel)}
        </div>
        <div className="item">
          <h4>Sub Interests</h4>
          {chipArray(subInterests, subInterestLabel)}
        </div>
      </div>

      {showEditModal ? (
        <Modal
          profilesFilter={props.profilesFilter}
          onSubmit={handleSubmit}
          onCancel={() => setShowEditModal(false)}
        />
      ) : null}
    </FilterSummaryStyles>
  );
};

export default FilterSummary;
