import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, WindowLocation } from '@reach/router';

import { parseAudienceQueryString, AudienceQueryStringState } from '../../lib/queryString';
import { defaultDates } from '../../lib/filter';

import { isAudienceFilter } from '../../types/smartStepsApi';

import Dashboard from './Dashboard/Dashboard';
import { defaultAudienceRefinement } from '../../types/refinement';
import { withTracking } from '../../lib/insights';
import { AuthContext } from '../Auth/AuthProvider';
import { PostalPermission, queryPostalApi } from '../../lib/postal';

function getInitialState(
  location: WindowLocation | undefined,
  authorized: boolean | undefined,
  client: string | undefined
): AudienceQueryStringState {
  if (location === undefined) {
    return parseAudienceQueryString('', authorized);
  }

  if (isAudienceFilter(location.state)) {
    return {
      query: {
        ...location.state,
        dateRange: defaultDates(authorized, client),
        timeOfDay: { startHour: 0, hours: 24 },
      },
      refinement: defaultAudienceRefinement,
    };
  }

  return parseAudienceQueryString(location.search, authorized);
}

const DashboardRoute: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const [postalPermission, setPostalPermission] = useState<PostalPermission[]>([]);
  const { state: authState } = useContext(AuthContext);
  const initialState = getInitialState(
    props.location,
    authState?.isAuthorized,
    authState?.username
  );

  useEffect(() => {
    const fetch = async () => {
      if (authState?.username) {
        const postalResult = await queryPostalApi({ username: authState.username });
        setPostalPermission(postalResult.recordset);
      }
    };
    fetch().catch(console.error);
  }, [authState?.username]);

  return (
    <Dashboard
      initialQuery={initialState.query}
      initialRefinement={initialState.refinement}
      postalPermission={postalPermission}
    />
  );
};

export default withTracking(DashboardRoute);
