import styled, { css } from 'styled-components';
import { emphasize } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

export const StyledChip = styled(Chip)`
  margin: ${(props) => props.theme.spacing(0.5, 0.25)};

  &:focus {
    background-color: ${(props) =>
      emphasize(
        props.theme.palette.type === 'light'
          ? props.theme.palette.grey[300]
          : props.theme.palette.grey[700],
        0.08
      )};
  }
`;

type Props = {
  position: 'top' | 'bottom';
  showError: boolean | undefined;
};

export default styled.div<Props>`
  ${(props) =>
    props.showError
      ? css`
          span {
            font-size: 1.2rem;
            color: #ff8077;
          }
        `
      : null}

  .noOptionsMessage {
    padding: ${(props) => props.theme.spacing(1, 2)};
  }

  .textField {
    display: flex;

    height: auto;

    padding: 0;
  }

  .placeholder {
    position: absolute;

    left: 0.2rem;
    bottom: 0.6rem;
  }

  .valueContainer {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    align-items: center;

    overflow: hidden;
  }

  .paper {
    position: absolute;

    left: 0;
    right: 0;

    ${(props) =>
      props.position === 'top'
        ? css`
            top: -30rem; /* To appear above the field */
          `
        : css`
            top: 4rem; /* To appear above the field */
          `}

    max-height: 30rem;

    z-index: 1;

    background-color: #f1f1f1;
  }
`;
