import {
  AggregationLevel,
  defaultAudienceAggregationLevel,
  defaultCatchmentAggregationLevel,
} from '../lib/gridResults';

/*
  ----- REDUX -----
*/

export interface RefinementState {
  aggregationLevel: AggregationLevel;
  filter: string;
}

export const defaultAudienceRefinement: RefinementState = {
  aggregationLevel: defaultAudienceAggregationLevel,
  filter: '',
};

export const defaultCatchmentRefinement: RefinementState = {
  aggregationLevel: defaultCatchmentAggregationLevel,
  filter: '',
};
