import styled, { css } from 'styled-components';

import { ViewProps } from '../../../types/global';

export const AsideStyles = styled.section<ViewProps>`
  display: flex;
  flex: 0 0 25rem;
  ${(props) =>
    props.viewStatus !== 'full' &&
    css`
      z-index: 1001;
    `}

  > .catchment-summary {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 0 0 25rem;
    background: #003;
    box-shadow: 0rem 0 1rem 0 rgba(0, 0, 0, 0.5);
    padding: 3rem;
    color: #ffffff;

    h1 {
      margin: 0;
      font-size: 3.4rem;
      font-weight: 900;
    }

    h3 {
      margin: 0;
      font-size: 1.8rem;
      font-weight: 600;
    }

    h4 {
      margin: 0 0 0.2rem;
      font-size: 1.2rem;
      font-weight: 600;
      opacity: 0.6;
    }

    > .logo-container {
      > .brand-logo {
        flex: 0 0 auto;
        padding-bottom: 0.2rem;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.3);
      }

      > #open-btn {
        display: flex;
        justify-content: center;
        padding: 1rem 0;

        > svg {
          font-size: 3rem;
          color: white;
        }
        &:hover svg {
          color: #0090d0;
          cursor: pointer;
        }
      }
    }

    > .scroller {
      flex: 1 1 auto;
      position: relative;

      > .scroller-content {
        position: absolute;

        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        overflow-y: scroll;

        > .date-and-time,
        .filter-summary {
          padding-top: 3rem;
        }
      }
    }
  }

  ${(props) =>
    props.viewStatus === 'mini' &&
    css`
      flex: 0 0 10rem;

      > .catchment-summary {
        flex: 0 0 10rem;
        > .logo-container {
          position: fixed;
        }

        > .scroller {
          display: none;
        }
      }
    `}

  ${(props) =>
    props.viewStatus === 'full-with-screen' &&
    css`
      position: fixed;
      > .catchment-summary {
        // Place to the top
        z-index: 1002;
      }
      > #close-btn {
        // Place to the top
        z-index: 1002;

        height: 100%;
        padding: 1rem;
        background-color: #0090d0;
        > svg {
          color: white;
          font-size: 1.8rem;
        }
        &:hover {
          cursor: pointer;
        }
      }
    `}

  > .screen {
    height: 100vh;
    width: 100%;
    flex: 1 1 auto;
    display: flex;

    background-color: black;
    opacity: 0.5;
    position: fixed;
  }
`;

export const DashboardStyles = styled.section<ViewProps>`
  display: flex;
  min-height: 100vh;

  > main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 4rem 4rem 0;

    ${(props) =>
      props.viewStatus === 'full-with-screen' &&
      css`
        padding-left: 14rem;
      `}

    ${(props) =>
      props.mapSize
        ? css`
            .map {
              flex: 0 0 45%;
            }
          `
        : css`
            .map {
              flex: 0 0 60%;
            }
          `}

    > .content {
      flex: 1 1 auto;
      display: flex;

      > .sentence-and-grid {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        padding-right: 3rem;

        > .grid {
          flex: 1 1 auto;
          height: 100%;

          > .gridPaper {
            padding-top: 2.2rem;
            padding-bottom: 1.5rem;
          }
        }
      }

      /* > .map {
        flex: 0 0 30%;
      } */
    }

    ${(props) => props.theme.breakpoints.down('md')} {
      > .content {
        flex-direction: column;
        flex: 0 0 auto;

        > .sentence-and-grid {
          padding-right: 0rem;
          padding-bottom: 3rem;

          > .grid {
            /* data grid FRAME height */
            min-height: 45rem;
          }
        }
      }
    }

    > .login {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 4rem;
      padding-top: 0.5em;
      .link-container {
        text-align-last: center;
      }
    }
  }
`;
