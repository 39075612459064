import isDate from 'date-fns/isDate';
import isValid from 'date-fns/isValid';
import differenceInDays from 'date-fns/differenceInDays';

/*
  ---- FILTER ----
*/

// Time
export type FromSingleHours =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;

export type FromThreeHours = 0 | 3 | 6 | 9 | 12 | 15 | 18 | 21;

export type FromHours = FromSingleHours | FromThreeHours | 0;

export type ToSingleHours =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24;

export type ToThreeHours = 6 | 9 | 12 | 15 | 18 | 21 | 24;

export type ToHours = ToSingleHours | ToThreeHours;

interface ParameterisedTimeInterval<Start, End> {
  start_hour: Start;
  end_hour: End;
}

type SingleHourIntervals = ParameterisedTimeInterval<FromSingleHours, ToSingleHours>;
type TriHourIntervals = ParameterisedTimeInterval<FromThreeHours, ToThreeHours>;
type WholeDayInterval = ParameterisedTimeInterval<0, 24>;

export type TimeInterval = WholeDayInterval | TriHourIntervals | SingleHourIntervals;

export type Hours = 1 | 3 | 24;

export const isHours = (input: unknown): input is Hours =>
  input === 1 || input === 3 || input === 24;

interface HourRange<TFrom extends FromHours, THours extends Hours> {
  startHour: TFrom;
  hours: THours;
}

type SingleHour = HourRange<FromSingleHours, 1>;
type ThreeHour = HourRange<FromThreeHours, 3>;
type WholeDay = HourRange<0, 24>;

export type TimeOfDay = WholeDay | SingleHour | ThreeHour;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTimeOfDay = (input: any): input is TimeOfDay => {
  if (typeof input !== 'object' || input === null) {
    return false;
  }

  const { startHour, hours } = input;

  return (
    typeof startHour === 'number' &&
    typeof hours == 'number' &&
    (hours === 1 || hours === 3 || hours === 24) &&
    startHour % hours === 0
  );
};

// Visit Type
export type VisitType = 'resident' | 'worker' | 'visitor' | 'all';

export const VisitTypes: VisitType[] = ['resident', 'worker', 'visitor', 'all'];

export const isVisitType = (input: unknown): input is VisitType =>
  VisitTypes.includes(input as VisitType);

// Gender
export type Gender = '*' | 'm' | 'f';

export const Genders: Gender[] = ['*', 'm', 'f'];

export const isGender = (input: unknown): input is Gender => Genders.includes(input as Gender);

// Age Band
export type AgeBand = '1824' | '2534' | '3544' | '4554' | '5564' | '6599';

export const AgeBands: AgeBand[] = ['1824', '2534', '3544', '4554', '5564', '6599'];

export const isAgeBand = (input: unknown): input is AgeBand => AgeBands.includes(input as AgeBand);

export const isAgeBands = (input: unknown): input is AgeBand[] =>
  Array.isArray(input) && input.every(isAgeBand);

// Spending Power
export type SpendingPower = '0019' | '2039' | '4059' | '6079' | '8099';

export const SpendingPowers: SpendingPower[] = ['0019', '2039', '4059', '6079', '8099'];

export const isSpendingPower = (input: unknown): input is SpendingPower =>
  SpendingPowers.includes(input as SpendingPower);

export const isSpendingPowers = (input: unknown): input is SpendingPower[] =>
  Array.isArray(input) && input.every(isSpendingPower);

// Interest

// Query
export type QueryInterest = 'AND' | 'OR';

export const QueryInterests: QueryInterest[] = ['AND', 'OR'];

export const isQueryInterest = (input: unknown): input is QueryInterest =>
  QueryInterests.includes(input as QueryInterest);

// export const isQueryInterests = (input: unknown): input is QueryInterest[] =>
//   Array.isArray(input) && input.every(isQueryInterest);

// Interests definition
export type InterestWithLevel = [Interest, InterestLevel[]];

export type Interest =
  | 'Arts_and_Entertainment'
  | 'Automotive'
  | 'Beginning_Investing'
  | 'Business'
  | 'Career_and_Education'
  | 'Family_and_Parenting'
  | 'Finance'
  | 'Food_and_Drink'
  | 'Games'
  | 'Health'
  | 'Hobbies_and_Interests'
  | 'Home_and_Garden'
  | 'Internet_Services'
  | 'Kids_and_Teens'
  | 'Law_Government_and_Politics'
  | 'News'
  | 'Online_Communities'
  | 'Pets'
  | 'Real_Estate'
  | 'Reference'
  | 'Religion_and_Spirituality'
  | 'Science'
  | 'Searching'
  | 'Shopping'
  | 'Society'
  | 'Sports'
  | 'Style_and_Fashion'
  | 'Technology_and_Computing'
  | 'Telecommunications'
  | 'Travel';
// | 'Advertising'
// | 'Competitors';

export const Interests: Interest[] = [
  // 'Advertising',
  'Arts_and_Entertainment',
  'Automotive',
  'Beginning_Investing',
  'Business',
  'Career_and_Education',
  // 'Competitors',
  'Family_and_Parenting',
  'Finance',
  'Food_and_Drink',
  'Games',
  'Health',
  'Hobbies_and_Interests',
  'Home_and_Garden',
  'Internet_Services',
  'Kids_and_Teens',
  'Law_Government_and_Politics',
  'News',
  'Online_Communities',
  'Pets',
  'Real_Estate',
  'Reference',
  'Religion_and_Spirituality',
  'Science',
  'Searching',
  'Shopping',
  'Society',
  'Sports',
  'Style_and_Fashion',
  'Technology_and_Computing',
  'Telecommunications',
  'Travel',
];

export const interestSet = new Set(Interests);
export const isInterest = (input: unknown): input is Interest => interestSet.has(input as Interest);

export const isInterests = (input: unknown): input is Interest[] =>
  Array.isArray(input) && input.every(isInterest);

export type InterestLevel = 'h' | 'm' | 'l';

// Locations
export const isLocation = (input: unknown): input is string => typeof input === 'string';

export const isLocations = (input: unknown): input is string[] =>
  Array.isArray(input) && input.every(isLocation);

/*
  ---- RESULTS ----
*/

export type GenderResult = {
  [key in Gender]?: number;
};

export type AgeBandResult = {
  [key in AgeBand]?: number;
};

export type GenderAgeBandResult = {
  [key in Gender]?: AgeBandResult;
};

export type SpendingPowerResult = {
  [key in SpendingPower]?: number;
};

export interface InterestsResultData {
  l: number;
  m: number;
  h: number;
}

export type InterestsResult = {
  [key in Interest]?: InterestsResultData;
};
export type InterestsResultV2 = {
  [key: string]: InterestsResultData;
};

export type LocationResult = {
  [key: string]: number;
};

/*
  ---- QUERY ----
*/

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDateRange = (input: any): input is DateRange => {
  if (typeof input !== 'object' || input === null) {
    return false;
  }

  const { startDate, endDate } = input;

  if (!(isDate(startDate) && isDate(endDate) && isValid(startDate) && isValid(endDate))) {
    return false;
  }

  const diff = differenceInDays(endDate, startDate);

  return diff >= 0 && diff <= 92;
};

export const isDay = (day: Date) => {
  return isDate(day) && isValid(day);
};

export interface Interval {
  dateRange: DateRange;
  timeOfDay: TimeOfDay;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInterval = (input: any): input is Interval => {
  if (typeof input !== 'object' || input === null) {
    return false;
  }

  const { dateRange, timeOfDay } = input;

  return isDateRange(dateRange) && isTimeOfDay(timeOfDay);
};

export interface AudienceFilter {
  visitType: VisitType;
  gender: Gender;
  ageBand: AgeBand[];
  spendingPower: SpendingPower[];
  interests: Interest[];
  subInterests: string[];
  queryInterest: QueryInterest;
}

export interface ProfilesFilter {
  locations: string[];
  visitType: VisitType;
  gender: Gender;
  ageBand: AgeBand[];
  spendingPower: SpendingPower[];
  interests: Interest[];
  subInterests: string[];
  queryInterest: QueryInterest;
}

export interface CatchmentFilter {
  day: Date;
  locations: string[];
}

export type AudienceQuery = Interval & AudienceFilter;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAudienceFilter(input: any): input is AudienceFilter {
  if (typeof input !== 'object' || input === null) {
    return false;
  }

  const { visitType, gender, ageBand, spendingPower, interests, queryInterest } = input;

  return (
    isVisitType(visitType) &&
    isGender(gender) &&
    isAgeBands(ageBand) &&
    isSpendingPowers(spendingPower) &&
    isInterests(interests) &&
    isQueryInterest(queryInterest)
  );
}

export function isCatchmentFilter(input: any): input is CatchmentFilter {
  if (typeof input !== 'object' || input === null) {
    return false;
  }

  const { locations } = input;
  return isLocations(locations);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isProfilesFilter(input: any): input is ProfilesFilter {
  if (typeof input !== 'object' || input === null) {
    return false;
  }

  const { locations, visitType, gender, ageBand, spendingPower, interests, queryInterest } = input;

  return (
    isLocations(locations) &&
    isVisitType(visitType) &&
    isGender(gender) &&
    isAgeBands(ageBand) &&
    isSpendingPowers(spendingPower) &&
    isInterests(interests) &&
    isQueryInterest(queryInterest)
  );
}

export type ProfilesQuery = Interval & ProfilesFilter;

/*
  ---- RESULT -----
*/

export interface AudienceTreeResult {
  name: string;
  count: number;
  rp: number;
  ri: number;
  children?: AudienceTreeResult[];
}

export interface AudienceResult {
  count: number;
  search: AudienceQuery;
  results: AudienceTreeResult;
}

export interface ProfilesTreeResult {
  gender: GenderResult;
  age_band: AgeBandResult;
  gender_age_band: GenderAgeBandResult;
  spending_power: SpendingPowerResult;
  interests: InterestsResultV2;
  work_location: LocationResult;
  home_location: LocationResult;
}

export interface ProfilesResult {
  count: number;
  search: ProfilesQuery;
  results: ProfilesTreeResult;
}
