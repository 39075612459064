import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import Button from '@material-ui/core/Button';

import Logo from './Assets/Images/Logo';
import O2Logo from './Assets/Images/O2Logo';
import TheLabLogo from './Assets/Images/TheLabLogo';
import Logout from './Auth/Logout';

import { StyledPaper } from '../styles/MaterialComponents';
import HomeStyles from './Home.styles';
import { withTracking } from '../lib/insights';
import { HomeButton } from './Shared/HomeButton';

const Home: React.FC<RouteComponentProps> = () => (
  <HomeStyles className="motion-bg">
    <div className="content">
      <div className="headings">
        <Logo size="lg" align="center" />
      </div>
      <HomeButton />
      <StyledPaper className="paper">
        <p>
          This is a visualization tool for the Audience Insights API, developed for users who want a
          better understanding of their audiences, based on their geographical location and
          movements
        </p>
        <p className="sub">Please select what you would like to research:</p>
        <div className="buttons">
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => navigate('/audience')}
          >
            Audiences
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => navigate('/profiles')}
          >
            Postcodes
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => navigate('/catchment')}
          >
            Catchment Areas
          </Button>
        </div>
      </StyledPaper>
    </div>
    <div className="login">
      <O2Logo />
      <Logout />
      <TheLabLogo />
    </div>
  </HomeStyles>
);

export default withTracking(Home);
