import styled from 'styled-components';

const Loading = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 3rem;

  height: 100vh;

  color: #ffffff;

  > * {
    flex: 0 0 auto;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
`;

export default Loading;
