import React from 'react';
import ReactDOM from 'react-dom';
import StylesProvider from '@material-ui/styles/StylesProvider';
import MaterialThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import MaterialTheme from './styles/MaterialTheme';
import StyledGlobal from './styles/StyledGlobal';

import App from './components/App';
import './lib/insights';

ReactDOM.render(
  <StylesProvider injectFirst>
    <StyledThemeProvider theme={MaterialTheme}>
      <MaterialThemeProvider theme={MaterialTheme}>
        <CssBaseline />
        <StyledGlobal />
        <App />
      </MaterialThemeProvider>
    </StyledThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
);
