import styled from 'styled-components';

export const CatchmentStyles = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .content {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .headings {
      flex: 0 0 auto;

      display: flex;
      align-items: flex-end;

      max-width: 90rem;
      width: 100%;

      margin: 3rem 0;
      padding: 0 3rem;

      color: #ffffff;

      > .back-button {
        flex: 0 0 9rem;

        margin-bottom: -0.4rem;
        padding-right: 3rem;
      }

      > .logo {
        flex: 1 1 auto;

        display: flex;
        justify-content: center;

        /* To counter the size of the button, and still "appear" centered */
        margin-left: -9rem;

        ${(props) => props.theme.breakpoints.down('sm')} {
          > * {
            justify-content: center;
          }
        }
      }
    }
  }

  > .login {
    flex: 0 0 6rem;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 3rem 2rem 2rem;

    .o2logo-container {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .logout-container {
      flex: 1;

      text-align: center;
      line-height: 1.6rem;
    }

    .the-lab-logo-container {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        margin: 0 0.8rem;

        font-weight: 600;
        font-size: 1rem;
        color: white;
      }
    }
  }
`;
