import React from 'react';
import Paper from '@material-ui/core/Paper';

import { CatchmentFilter as CatchmentFilterState } from '../../../../types/smartStepsApi';
import { HelpView } from '../Help/HelpView';
import { CatchmentField } from '../CatchmentField/CatchmentField';
import { CatchmentHelp } from '../Help/CatchmentHelp';

import { StyledPaper } from '../../../../styles/MaterialComponents';
import { CatchmentFieldsStyles } from './CatchmentFields.styles';

type CatchmentFieldsProps = {
  catchmentFilter: CatchmentFilterState;
  includeHelp: boolean;
  onChange: (catchmentFilter: CatchmentFilterState) => void;
  toggleDrawer?: (open: boolean, event?: React.KeyboardEvent | React.MouseEvent) => void;
  helpView?: HelpView;
  setHelpView?: (helpView: HelpView | undefined) => void;
};

export const CatchmentFields: React.FC<CatchmentFieldsProps> = ({
  catchmentFilter,
  includeHelp,
  onChange,
  toggleDrawer,
  helpView,
  setHelpView,
}) => {
  // Render
  const filter = (
    <CatchmentField
      catchmentFilter={catchmentFilter}
      includeHelp={includeHelp}
      onChange={onChange}
      toggleDrawer={toggleDrawer}
      helpView={helpView}
      setHelpView={setHelpView}
    />
  );

  return (
    <CatchmentFieldsStyles includeHelp={includeHelp}>
      <div className="outerContainer">
        <div className="filterContainer">
          {includeHelp && <Paper className="filterPaper">{filter}</Paper>}
          {!includeHelp && filter}
        </div>
        {includeHelp ? (
          <div className="helpContainer">
            <StyledPaper>
              <CatchmentHelp display={helpView} />
            </StyledPaper>
          </div>
        ) : null}
      </div>
    </CatchmentFieldsStyles>
  );
};
