import React from 'react';

import PaperWithHeading from '../../Assets/Layout/PaperWithHeading';

import { GenderAgeBandResult, GenderResult, ProfilesFilter } from '../../../types/smartStepsApi';
import { capitaliseText } from '../../../lib/helpers';

import GenderStyles from './Gender.styles';

// Modal

type Props = {
  label: string;
  data?: GenderAgeBandResult;
  extraData?: GenderResult;
  isLoading: boolean;
  visitType: ProfilesFilter['visitType'] | undefined;
  ppl: number;
};

const Gender: React.FC<Props> = (props) => {
  const genderData = props.extraData;
  const ppl = props.ppl;

  return (
    <PaperWithHeading label="Gender" isLoading={props.isLoading}>
      {genderData && (
        <GenderStyles>
          {genderData.m && (
            <div className="gender male">
              <h5>Male</h5>

              <p className="people">{genderData.m.toLocaleString()}</p>
              <p className="people-label">
                {props.visitType ? `${capitaliseText(props.visitType)}s` : '-'}
              </p>

              <p className="percent">
                {((genderData.m / ppl) * 100).toFixed(1)} <span>%</span>
              </p>
            </div>
          )}

          {genderData.f && (
            <div className="gender female">
              <h5>Female</h5>

              <p className="people">{genderData.f.toLocaleString()}</p>
              <p className="people-label">
                {props.visitType ? `${capitaliseText(props.visitType)}s` : '-'}
              </p>

              <p className="percent">
                {((genderData.f / ppl) * 100).toFixed(1)} <span>%</span>
              </p>
            </div>
          )}
        </GenderStyles>
      )}
    </PaperWithHeading>
  );
};

export default Gender;
