import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ProfilesFilter from './Filter/Filter';
import Logo from '../Assets/Images/Logo';
import O2Logo from '../Assets/Images/O2Logo';
import TheLabLogo from '../Assets/Images/TheLabLogo';
import Logout from '../Auth/Logout';

import { defaultProfilesFilter } from '../../lib/filter';
import { ProfilesFilter as ProfilesFilterState } from '../../types/smartStepsApi';

import ProfilesHomeStyles from './HomeRoute.styles';
import { withTracking } from '../../lib/insights';
import { HomeButton } from '../Shared/HomeButton';

const ProfilesHome: React.FC<RouteComponentProps> = () => {
  // Handlers
  const handleSubmit = (profilesFilter: ProfilesFilterState) => {
    // Proceed to dashboard
    navigate('/profiles/dashboard', { state: profilesFilter });
  };

  return (
    <ProfilesHomeStyles className="motion-bg">
      <HomeButton />
      <div className="content">
        <div className="headings">
          <div className="back-button">
            <IconButton color="inherit" onClick={() => navigate('/')}>
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </div>

          <div className="logo">
            <Logo size="lg" align="center" />
          </div>
        </div>

        <ProfilesFilter profilesFilter={defaultProfilesFilter} onSubmit={handleSubmit} />
      </div>
      <div className="login">
        <O2Logo />
        <Logout />
        <TheLabLogo />
      </div>
    </ProfilesHomeStyles>
  );
};

export default withTracking(ProfilesHome);
