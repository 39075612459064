import React from 'react';

import theLabWht from '../../../assets/images/The_Lab_Wht.png';

import TheLabLogoStyles from './TheLabLogo.styles';

const TheLabLogo = () => (
  <TheLabLogoStyles className="the-lab-logo-container">
    <p>Powered by</p>
    <img src={theLabWht} alt="the-lab-logo" className="the-lab-logo" />
  </TheLabLogoStyles>
);

export default TheLabLogo;
