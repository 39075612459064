import Leaflet from 'leaflet';

import mapMarkerHover from '../../../../assets/images/map-marker-hover.svg';
import mapMarkerSelected from '../../../../assets/images/map-marker-selected.svg';
import { AggregationLevel } from '../../../../lib/gridResults';

import AreaGeoJson from '../../../../assets/geojson/areas.geojson';
import DistrictGeoJson from '../../../../assets/geojson/districts.geojson';
import NUTS1GeoJson from '../../../../assets/geojson/nuts_level_1.geojson';
import NUTS2GeoJson from '../../../../assets/geojson/nuts_level_2.geojson';
import NUTS3GeoJson from '../../../../assets/geojson/nuts_level_3.geojson';
import SectorGeoJson from '../../../../assets/geojson/sectors.geojson';

// Data
export const DEFAULT_BOUNDS = new Leaflet.LatLngBounds([
  [49.783259, -8.308926],
  [61.187114, 2.369785],
]);

export const DEFAULT_DEMO_BOUNDS = new Leaflet.LatLngBounds([
  [51, -5.2],
  [54, -2.7],
]);

export const MAX_BOUNDS = new Leaflet.LatLngBounds([
  [45.981934, -16.476746],
  [63.568273, 9.275208],
]);

export const BORDER_HOVER_STYLE: Leaflet.PathOptions = {
  stroke: true,
  color: '#303030',
  weight: 1,
  opacity: 1,
};

export const BORDER_SELECTED_STYLE: Leaflet.PathOptions = {
  stroke: true,
  color: '#0019A5',
  weight: 1,
  opacity: 1,
};

export const BORDER_OFF_STYLE: Leaflet.PathOptions = {
  stroke: false,
};

export const FLY_OPTIONS: Leaflet.ZoomPanOptions = {
  duration: 1,
};

export const markerIconHover = new Leaflet.Icon({
  iconUrl: mapMarkerHover,
  iconSize: [20, 20],
});

export const markerIconSelected = new Leaflet.Icon({
  iconUrl: mapMarkerSelected,
  iconSize: [20, 20],
  className: 'marker-click-through',
});

// Helpers
export const getGeoJsonPath = (aggregationLevel: AggregationLevel): string => {
  switch (aggregationLevel) {
    case AggregationLevel.NUTS1:
      return NUTS1GeoJson;
    case AggregationLevel.NUTS2:
      return NUTS2GeoJson;
    case AggregationLevel.NUTS3:
      return NUTS3GeoJson;
    case AggregationLevel.Area:
      return AreaGeoJson;
    case AggregationLevel.District:
      return DistrictGeoJson;
    case AggregationLevel.Sector:
      return SectorGeoJson;
  }
};

export const buildClearStyles: Leaflet.StyleFunction = () => ({
  stroke: false,
  fillColor: '*',
  fillOpacity: 0,
});

export const buildHeatmapStyles: Leaflet.StyleFunction = (feature) => ({
  stroke: false,
  fillColor: '#FF7F41',
  fillOpacity: feature?.properties?.opacity || 0,
});

export const buildUnderlayStyles: Leaflet.StyleFunction = (feature) => ({
  stroke: false,
  fillColor: feature?.properties?.opacity ? '#FFFFFF' : 'grey',
  fillOpacity: feature?.properties?.opacity ? 0.85 : 0.2,
});
