import styled, { css } from 'styled-components';

export const CatchmentFieldsStyles = styled.div<{ includeHelp: boolean }>`
  .outerContainer {
    ${(props) =>
      props.includeHelp &&
      css`
        display: flex;
        justify-content: center;
        min-height: 300px;
      `}

    > .filterContainer {
      ${(props) =>
        props.includeHelp &&
        css`
          ${(props) => props.theme.breakpoints.up('sm')} {
            flex: 0 0 44rem;
            max-width: 44rem;
            background: white;
            padding-right: 1rem;
          }

          ${(props) => props.theme.breakpoints.down('sm')} {
            flex: 0 0 50rem;

            max-width: 50rem;

            padding-right: 0rem;

            > div {
              margin: 0 3.5rem;
            }
          }

          > .filterPaper {
            padding: 2rem;
          }
        `}

      ${(props) =>
        !props.includeHelp &&
        css`
          .field:not(:first-of-type) {
            margin-top: 2rem;
          }
        `}
    }

    ${(props) =>
      props.includeHelp &&
      css`
        > .helpContainer {
          > div {
            padding: 2rem 3rem;
          }

          ${(props) => props.theme.breakpoints.up('md')} {
            flex: 0 0 44rem;
            max-width: 44rem;
            padding-left: 1rem;
          }

          ${(props) => props.theme.breakpoints.down('sm')} {
            flex: 0 0 38rem;
            padding-left: 1rem;
            display: none;
          }

          h3 {
            margin: 0.8rem 0 0.6rem;
            font-size: 2.6rem;
            font-weight: 600;
          }

          p {
            margin: 1rem 0;
            font-size: 1.8rem;
            font-weight: 300;
          }

          ul {
            margin: 1rem 0;
          }

          li {
            margin: 0;

            font-size: 1.8rem;
            font-weight: 300;
          }
        }
      `}
  }
  .demo-user-note {
    font-size: 1.2rem;
    margin: -5px 0 5px;
    color: ${(props) => props.theme.palette.warning.main};
  }
`;
