import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';
import { useTheme } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Button, Dialog, FormControl, MenuItem, Select, useMediaQuery } from '@material-ui/core';

import { AuthContext } from '../../../Auth/AuthProvider';
import { CatchmentFilter, DateRange } from '../../../../types/smartStepsApi';
import { IntervalModalPanelStyles } from './IntervalModalPanel.styles';
import {
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from '../../../../styles/MaterialComponents';
import { defaultDate } from '../../../../lib/filter';

type IntervalModalPanelProps = {
  dataFilter: CatchmentFilter;
  allowedDate: DateRange[];
  onSubmit: (day: Date) => void;
  onCancel: () => void;
  findRangeIndex: (ranges: DateRange[], date: Date) => number;
};

export const IntervalModalPanel: React.FC<IntervalModalPanelProps> = ({
  dataFilter,
  allowedDate,
  onSubmit,
  onCancel,
  findRangeIndex,
}) => {
  // Theme
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const defaultRangeIndex = useMemo(() => findRangeIndex(allowedDate, dataFilter.day), []);

  const { state: authState } = useContext(AuthContext);
  const [date, setDate] = useState<Date>(dataFilter.day);
  const [minDate, setMinDate] = useState<Date>(allowedDate[defaultRangeIndex]?.startDate);
  const [maxDate, setMaxDate] = useState<Date>(allowedDate[defaultRangeIndex]?.endDate);

  const handleYear = (event: ChangeEvent<{ value: unknown }>) => {
    const dataYear = event.target.value as number;
    const interval = allowedDate[dataYear];
    setMinDate(interval?.startDate);
    setMaxDate(interval?.endDate);
    setDate(interval?.startDate);
  };

  const handleDate = (date: MaterialUiPickersDate) => {
    if (date) {
      setDate(date);
    }
  };

  const handleDefault = () => setDate(defaultDate(authState?.isAuthorized));
  const handleSubmit = () => onSubmit(date);

  return (
    <Dialog open={true} onClose={() => onCancel()} fullScreen={fullScreen} className="dialog">
      <IntervalModalPanelStyles>
        <StyledDialogContent>
          {!authState?.isAuthorized && (
            <div className="demo-user-note">You are in demo mode. Date selection is limited</div>
          )}
          <StyledDialogTitle className="gutter-bottom">Range</StyledDialogTitle>
          <FormControl fullWidth>
            <Select
              id="simple-select"
              defaultValue={defaultRangeIndex}
              label="Year"
              onChange={handleYear}
            >
              {allowedDate.map((interval, index) => (
                <MenuItem value={index} key={interval.startDate.getUTCMilliseconds() + index}>
                  {format(interval.startDate, 'yyyy-MM-dd')} -{' '}
                  {format(interval.endDate, 'yyyy-MM-dd')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <StyledDialogTitle className="gutter-top">Date</StyledDialogTitle>
          <DatePicker
            id="from"
            label="From"
            value={date}
            format="dd/MM/yyyy"
            disableFuture
            onChange={handleDate}
            minDate={minDate}
            maxDate={maxDate}
            variant="inline"
            disableToolbar
            autoOk
            fullWidth
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <Button onClick={handleDefault} color="secondary">
            Default
          </Button>
          <div className="groupedButtons">
            <Button onClick={() => onCancel()} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Apply
            </Button>
          </div>
        </StyledDialogActions>
      </IntervalModalPanelStyles>
    </Dialog>
  );
};
