import styled from 'styled-components';

type Props = {
  containerHeight: number | undefined;
};

export default styled.div<Props>`
  display: flex;

  flex-direction: column;
  justify-content: center;

  height: 100%;
`;
