import { Theme } from '@nivo/core';

export const themeA: Theme = {
  labels: {
    text: {
      fontFamily: 'inherit',
      fontSize: '1.2rem',
      fontWeight: 300,
      color: '#000000',
    },
  },
  grid: {
    line: {
      stroke: '#000000',
      strokeWidth: 0.2,
    },
  },
  axis: {
    legend: {
      text: {
        fontFamily: 'inherit',
        fontSize: '1.2rem',
        fontWeight: 700,
      },
    },
    ticks: {
      text: {
        fontFamily: 'inherit',
        fontSize: '1.2rem',
        fontWeight: 300,
      },
    },
  },
  tooltip: {
    container: {
      padding: '1rem',
      borderRadius: 0,
    },
  },
};

interface StringArray {
  [index: string]: string;
}

export const o2colors: StringArray = {
  '18-24': '#2393CF',
  '25-34': '#48ACDE',
  '35-44': '#73C3E7',
  '45-54': '#95D3EB',
  '55-64': '#B5DDEC',
  '65+': '#CFE7EF',
};

export const o2colorsSpendingPower: StringArray = {
  'V. Low': '#2393CF',
  Low: '#48ACDE',
  Medium: '#73C3E7',
  High: '#95D3EB',
  'V. High': '#B5DDEC',
};

export const o2colorsGender: StringArray = {
  Female: '#FFE5D9',
  Male: '#E9F4C4',
};

export const o2colorsInterests = [
  'rgb(207, 231, 239)',
  'rgb(181, 221, 236)',
  'rgb(149, 211, 235)',
  'rgb(115, 195, 231)',
  'rgb(72, 172, 222)',
  'rgb(35, 147, 207)',
  'rgb(18, 122, 190)',
  'rgb(29, 99, 172)',
  'rgb(28, 74, 150)',
  'rgb(31, 50, 123)',
  'rgb(35, 36, 110)',
];
