import React, { useState, FormEvent } from 'react';
import Clear from '@material-ui/icons/Clear';
import Drawer from '@material-ui/core/Drawer';

import CustomButton from '../../../Assets/Form/Button';
import CustomSnackbar from '../../../Assets/Form/CustomSnackbar';
import DrawerStyles from '../../../../styles/Drawer.styles';

import { CatchmentFilter as CatchmentFilterState } from '../../../../types/smartStepsApi';
import { CatchmentFilterStyle } from './CatchmentFilter.styles';
import { CatchmentHelp } from '../Help/CatchmentHelp';
import { HelpView } from '../Help/HelpView';
import { CatchmentFields } from '../CatchmentFields/CatchmentFields';

type CatchmentFilterProps = {
  catchmentFilter: CatchmentFilterState;
  onSubmit: (catchmentFilter: CatchmentFilterState) => void;
};

export const CatchmentFilter: React.FC<CatchmentFilterProps> = ({ catchmentFilter, onSubmit }) => {
  // Local State
  const [catchment, setCatchment] = useState(catchmentFilter);
  const [mouseHover, setMouseHover] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [helpView, setHelpView] = useState<HelpView>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Handlers
  const submitForm = (e: FormEvent) => {
    e.preventDefault();

    //Check if at lease 1 location is selected
    if (!catchment.locations.length) {
      setSnackbarOpen(true);
    } else {
      // Continue to parent
      onSubmit(catchment);
    }
  };

  const toggleDrawer = (open: boolean, event?: React.KeyboardEvent | React.MouseEvent) => {
    // Copied accessibility example from Material-UI
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <CatchmentFilterStyle>
      <CustomSnackbar snackbarOpen={snackbarOpen} setSnackbarOpen={(r) => setSnackbarOpen(r)} />
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={(e: React.KeyboardEvent | React.MouseEvent) => toggleDrawer(false, e)}
        onMouseEnter={() => {
          // Resetting helpView to combat onMouseLeave
          setHelpView(helpView);
        }}
      >
        <DrawerStyles>
          <Clear onClick={(e) => toggleDrawer(false, e)} />
          <CatchmentHelp display={helpView} />
        </DrawerStyles>
      </Drawer>

      <form onSubmit={submitForm}>
        <div
          onMouseEnter={() => setMouseHover(true)}
          onMouseLeave={() => {
            setMouseHover(false);
            setHelpView(undefined);
          }}
        >
          <CatchmentFields
            catchmentFilter={catchment}
            includeHelp={true}
            onChange={setCatchment}
            toggleDrawer={toggleDrawer}
            helpView={helpView}
            setHelpView={setHelpView}
          />
        </div>
        <div className="submitButton">
          <CustomButton text="Results" active={mouseHover ? 'on' : 'off'} />
        </div>
      </form>
    </CatchmentFilterStyle>
  );
};
