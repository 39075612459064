import styled from 'styled-components';

const TheLabLogo = styled.section`
  > img {
    width: 2.2rem;
    height: auto;
  }
`;

export default TheLabLogo;
