import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';

import { SelectOption } from '../../../types/audienceFilter';

import { StyledToggleButtonGroup } from './ToggleButtons.styles';

// Types
interface Props<T> {
  data: SelectOption<T>[];
  label: string;
  selected?: T[];
  onClick: (value: T[]) => void;
}

// Component
const ToggleButtonsMulti = <T extends string>({ data, label, selected, onClick }: Props<T>) => {
  const [value, setValue] = useState<T[] | undefined>(selected);

  useEffect(() => setValue(selected), [selected]);

  const handleChange = (dataValue: T[]): void => {
    setValue(dataValue);

    // Continue to parent (sorted)
    onClick(dataValue.sort());
  };

  return (
    <>
      <Typography variant="subtitle2" gutterBottom>
        {label}
      </Typography>

      <StyledToggleButtonGroup
        value={value}
        size="small"
        onChange={(event: React.MouseEvent, value: T[]) => handleChange(value)}
      >
        {data.map((button: SelectOption<T>) => (
          <ToggleButton key={button.value} {...button}>
            {button.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </>
  );
};

export default ToggleButtonsMulti;
