import { AudienceTreeResult } from '../types/smartStepsApi';
import { findNUTSAreaForPath } from './nutsAreas';
import { Arrays, Iterables, pipe } from 'collection-fns';
import { PostalPermission } from './postal';

export enum AggregationLevel {
  'NUTS1' = 1,
  'NUTS2' = 2,
  'NUTS3' = 3,
  'Area' = 4,
  'District' = 5,
  'Sector' = 6,
}

export const defaultAudienceAggregationLevel = AggregationLevel.District;
export const defaultCatchmentAggregationLevel = AggregationLevel.Sector;

const formatDescription = (path: string[]): string => {
  const isNutsArea = path[path.length - 1].startsWith('UK');
  const nutsArea = findNUTSAreaForPath(path);
  if (nutsArea) {
    return nutsArea.description;
  } else if (!isNutsArea) {
    return Arrays.reverse(path).find((step) => step.startsWith('UK')) || '';
  }
  return '';
};

export interface GridResult {
  name: string;
  description: string;
  path: string[];
  count: number;
  rp: number;
  ri: number;
}

export type DataColumn = keyof GridResult | 'selected';

interface FlattenResult extends GridResult {
  parent: AudienceTreeResult;
}

export const flattenResult = (
  rootResult: AudienceTreeResult,
  level: AggregationLevel
): FlattenResult[] => {
  const flattenInternal = (
    currentResult: AudienceTreeResult,
    currentLevel: number,
    path: string[]
  ): FlattenResult[] => {
    if (!currentResult.children) {
      return [];
    }

    if (currentLevel <= 1) {
      return currentResult.children.map((child) => ({
        name: child.name,
        description: formatDescription([...path, child.name]),
        path,
        count: child.count,
        rp: child.rp,
        ri: child.ri,
        // Use the UK as the parent of postcode areas as they don't fit within NUTS 3 regions.
        parent: level === AggregationLevel.Area ? rootResult : currentResult,
      }));
    }

    const flattened = currentResult.children.map((child) =>
      flattenInternal(child, currentLevel - 1, [...path, child.name])
    );

    const output = [];
    for (const group of flattened) {
      output.push(...group);
    }
    return output;
  };
  return flattenInternal(rootResult, level, ['UK']);
};

export interface GridSort {
  field: DataColumn;
  direction: 'asc' | 'desc';
}

function getParentsRp(parents: AudienceTreeResult[]) {
  const distinctParents = Arrays.distinct(parents);
  if (distinctParents.length === 1) {
    return distinctParents[0].rp;
  }
  const total = Arrays.sumBy(distinctParents, (g) => g.count / g.rp);
  const count = Arrays.sumBy(distinctParents, (g) => g.count);
  const rp = count / total;
  return rp;
}

function mergeDuplicateResults(results: FlattenResult[]): GridResult[] {
  return pipe(
    results,
    Iterables.groupBy((r) => r.name),
    Iterables.map(([, grouped]) => {
      if (grouped.length === 1) {
        return grouped[0];
      } else {
        const primaryResult = Arrays.sortByDescending(grouped, (g) => g.count / g.rp)[0];
        const total = Arrays.sumBy(grouped, (g) => g.count / g.rp);
        const count = Arrays.sumBy(grouped, (g) => g.count);
        const rp = count / total;
        const parentRp = getParentsRp(grouped.map((g) => g.parent));
        const ri = (rp * 100) / parentRp;
        const merged = {
          name: primaryResult.name,
          description: primaryResult.description,
          path: primaryResult.path,
          count,
          rp,
          ri,
        };
        return merged;
      }
    }),
    Iterables.toArray
  );
}

export const buildFilter = (filter: string) => {
  const loweredFilter = filter.toLocaleLowerCase();
  return (item: GridResult) =>
    item.name.toLocaleLowerCase().startsWith(loweredFilter) ||
    item.description.toLocaleLowerCase().includes(loweredFilter) ||
    item.path.findIndex((crumb) => crumb.startsWith(filter)) > -1;
};

export function formatGridResults(
  results: AudienceTreeResult | undefined,
  aggregationLevel: AggregationLevel,
  filter: string,
  sorting: GridSort,
  postalPermission: PostalPermission[],
  selected?: string[]
): GridResult[] {
  const refinedData = results
    ? mergeDuplicateResults(flattenResult(results, aggregationLevel))
    : [];
  const filterFn = buildFilter(filter);
  let filteredData = refinedData.filter(filterFn);

  if (postalPermission.length > 0) {
    filteredData = filteredData.filter((item) =>
      postalPermission.some(
        (permission) =>
          item.name.split(' ')[0].toLocaleLowerCase() === permission.Postal.toLocaleLowerCase()
      )
    );
  }

  const sort = sorting.direction === 'asc' ? Arrays.sortBy : Arrays.sortByDescending;

  return sort(filteredData, (row) => {
    if (sorting.field === 'selected') {
      return selected && selected.includes(row.name) ? 1 : 0;
    } else {
      return row[sorting.field];
    }
  });
}

export const numberToLocale = (number: number) => number.toLocaleString();
export const numberToDecimal = (number: number) => number.toFixed(1).replace(/[.,]0$/, '');
export const numberToPercent = (number: number) => number.toFixed(1).replace(/[.,]0$/, '') + '%';
