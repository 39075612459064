import styled, { css } from 'styled-components';

export const CatchmentFieldStyles = styled.div<{ focused: boolean }>`
  padding: 1rem;

  ${(props) =>
    props.focused &&
    css`
      box-shadow: -5px 0px 0px 0px #41b6e6;
    `}
`;
