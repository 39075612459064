import styled from 'styled-components';

const Audience = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .content {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 3rem;

    > .headings {
      margin: 3rem 0;

      color: #ffffff;
    }

    > .paper {
      max-width: 45em;

      text-align: center;

      > p {
        margin: 0;

        font-size: 1.8rem;
        font-weight: 300;

        &.sub {
          font-size: 1.4rem;
          font-weight: 700;
        }

        &:not(:first-of-type) {
          margin-top: 2.4rem;
        }
      }

      > .buttons {
        display: flex;
        justify-content: center;

        margin: 2rem 0 0.4rem;

        > * {
          flex: 0 0 auto;

          &:not(:first-child) {
            margin-left: 1.5rem;
          }

          &:not(:last-child) {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }

  > .login {
    flex: 0 0 6rem;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 3rem 2rem 2rem;

    .o2logo-container {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .logout-container {
      flex: 1;

      text-align: center;
      line-height: 1.6rem;
    }

    .the-lab-logo-container {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        margin: 0 0.8rem;

        font-weight: 600;
        font-size: 1rem;
        color: white;
      }
    }
  }
`;

export default Audience;
