import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
//import InputLabel from '@mui/material/InputLabel';
import { getTimeRanges } from '../../../constants/filter';
import { defaultDates, findRangeIndex } from '../../../lib/filter';
import {
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from '../../../styles/MaterialComponents';
import { DateRange, Hours, Interval, isHours, isInterval } from '../../../types/smartStepsApi';
import { AuthContext } from '../../Auth/AuthProvider';
import { IntervalModalStyles } from './DateTimeModal.styles';

// Types
interface Props {
  interval: Interval;
  allowedDateRanged: DateRange[];
  onSubmit: (interval: Interval) => void;
  onCancel: () => void;
}

// Component
export const IntervalModal = (props: Props) => {
  // Theme
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // Local state
  const [startDate, setStartDate] = useState<Date>(props.interval.dateRange.startDate);
  const [endDate, setEndDate] = useState<Date>(props.interval.dateRange.endDate);
  const [startHour, setStartHour] = useState<number>(props.interval.timeOfDay.startHour);
  const [hours, setHours] = useState<Hours>(props.interval.timeOfDay.hours);
  const [showError, setShowError] = useState(false);
  const { state: authState } = useContext(AuthContext);

  const defaultRangeIndex = useMemo(
    () => findRangeIndex(props.allowedDateRanged, props.interval.dateRange),
    []
  );
  const [minStartDate, setMinStartDate] = useState<Date>(
    props.allowedDateRanged[defaultRangeIndex].startDate
  );
  const [maxEndDate, setMaxEndDate] = useState<Date>(
    props.allowedDateRanged[defaultRangeIndex].endDate
  );

  // Handlers

  const handleYear = (event: ChangeEvent<{ value: unknown }>) => {
    const dataYear = event.target.value as number;
    const interval = props.allowedDateRanged[dataYear];
    setMinStartDate(interval.startDate);
    setMaxEndDate(interval.endDate);
    setStartDate(interval.startDate);
    setEndDate(interval.endDate);
  };

  const handleStartDate = (date: MaterialUiPickersDate) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDate = (date: MaterialUiPickersDate) => {
    if (date) {
      setEndDate(date);
    }
  };

  const handleStartHour = (event: ChangeEvent<{ value: unknown }>) => {
    const dataStartTime = event.target.value as number;
    setStartHour(dataStartTime);
  };

  const handleHours = (event: ChangeEvent<{}>, hoursString: string) => {
    const dataHours = parseInt(hoursString);

    if (isHours(dataHours)) {
      setHours(dataHours);
      setStartHour((start) => start - (start % dataHours)); // Round to previous valid option.
    }
  };

  const makeInterval = () => ({
    dateRange: {
      startDate,
      endDate,
    },
    timeOfDay: {
      hours,
      startHour,
    },
  });

  const handleDefault = () => {
    const dates = defaultDates(authState?.isAuthorized, authState?.username);

    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
    setHours(24);
    setStartHour(0);
  };

  const handleSubmit = () => {
    const interval = makeInterval();

    if (isInterval(interval)) {
      props.onSubmit(interval);
    }
  };

  // Callbacks
  const makeIntervalCallback = useCallback(makeInterval, [startDate, endDate, startHour, hours]);

  // Effects
  useEffect(() => {
    const interval = makeIntervalCallback();
    setShowError(!isInterval(interval));
  }, [makeIntervalCallback, props.interval]);

  return (
    <Dialog open={true} onClose={() => props.onCancel()} fullScreen={fullScreen} className="dialog">
      <IntervalModalStyles>
        <StyledDialogContent>
          {!authState?.isAuthorized && (
            <div className="demo-user-note">You are in demo mode. Date selection is limited</div>
          )}
          <StyledDialogTitle className="gutter-bottom">Year</StyledDialogTitle>
          <FormControl fullWidth>
            <Select
              id="simple-select"
              defaultValue={defaultRangeIndex}
              label="Year"
              onChange={handleYear}
            >
              {props.allowedDateRanged.map((interval, index) => (
                <MenuItem value={index} key={index}>
                  {format(interval.startDate, 'yyyy-MM-dd')} -{' '}
                  {format(interval.endDate, 'yyyy-MM-dd')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <StyledDialogTitle className="gutter-top">Date</StyledDialogTitle>
          <DatePicker
            id="from"
            label="From"
            value={startDate}
            format="dd/MM/yyyy"
            disableFuture
            onChange={handleStartDate}
            minDate={minStartDate}
            maxDate={maxEndDate}
            variant="inline"
            disableToolbar
            autoOk
            fullWidth
          />
          <DatePicker
            id="to"
            label="To"
            value={endDate}
            format="dd/MM/yyyy"
            disableFuture
            onChange={handleEndDate}
            minDate={minStartDate}
            maxDate={maxEndDate}
            className="spacingTop"
            variant="inline"
            disableToolbar
            autoOk
            fullWidth
            error={showError}
          />

          {differenceInDays(endDate, startDate) > 90 && (
            <p>The date range can't be higher than three months</p>
          )}
          {startDate > endDate && <p>The end date can't be before the start date</p>}

          <StyledDialogTitle className="gutter-top">Time</StyledDialogTitle>
          <FormControl fullWidth>
            <RadioGroup aria-label="hours" value={hours.toString()} onChange={handleHours}>
              <FormControlLabel value="24" control={<Radio />} label="All Day" />
              <FormControlLabel value="3" control={<Radio />} label="3 Hour" />
              <FormControlLabel value="1" control={<Radio />} label="1 Hour" />
            </RadioGroup>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <Select value={startHour} onChange={handleStartHour}>
              {getTimeRanges(hours).map((time) => (
                <MenuItem key={time.value} value={time.value}>
                  {time.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button onClick={handleDefault} color="secondary">
            Default
          </Button>
          <div className="groupedButtons">
            <Button onClick={() => props.onCancel()} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Apply
            </Button>
          </div>
        </StyledDialogActions>
      </IntervalModalStyles>
    </Dialog>
  );
};
