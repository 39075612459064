import React, { PropsWithChildren, Dispatch } from 'react';
import { AuthState } from '../../lib/authAzure';

export interface AuthAction {
  type: 'SET_AUTH';
  payload: AuthState;
}

export type AuthStoreState = AuthState | undefined;

export interface AuthStore {
  state: AuthStoreState;
  dispatch: Dispatch<AuthAction>;
}

const reducer = (state: AuthStoreState, action: AuthAction): AuthStoreState => {
  switch (action.type) {
    case 'SET_AUTH':
      return action.payload;
  }
  return state;
};

const initialState: AuthStoreState = undefined;

export const AuthContext = React.createContext<AuthStore>({
  state: initialState,
  dispatch: () => {},
});

export const AuthProvider: React.FC = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return <AuthContext.Provider value={{ state, dispatch }}>{props.children}</AuthContext.Provider>;
};
