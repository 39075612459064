import React, { useState, useEffect } from 'react';
import { Bar } from '@nivo/bar';
import { AutoSizer } from 'react-virtualized';
import { Arrays } from 'collection-fns';

import PaperWithHeading from '../../Assets/Layout/PaperWithHeading';

import { SPENDING_POWERS } from '../../../constants/filter';
import { capitaliseText } from '../../../lib/helpers';
import { SpendingPowerResult, ProfilesFilter } from '../../../types/smartStepsApi';

import { themeA, o2colorsSpendingPower } from '../../../styles/NivoTheme';
import SpendingPowerStyles from './SpendingPower.styles';

type Props = {
  label: string;
  data?: SpendingPowerResult;
  visitType: ProfilesFilter['visitType'] | undefined;
  isLoading: boolean;
  ppl: number;
};

type formattedData = {
  group: string | undefined;
} & {
  [key: string]: number | undefined | string;
};

const formatKey = (key: string) => {
  return SPENDING_POWERS.find((s) => s.value === key)?.label || '';
};

const formatData = (data: SpendingPowerResult): formattedData[] => {
  const entries = Object.entries(data);
  const sorted = Arrays.sortBy(entries, ([key]) => key);
  return sorted.map(([key, value]) => ({
    group: formatKey(key),
    [formatKey(key)]: value,
  }));
};

const DataBarSpendingPower: React.FC<Props> = (props) => {
  const formattedData = (props.data && formatData(props.data)) || [];
  const ppl = props.ppl;

  // Local States
  const [containerHeight, setContainerHeight] = useState(0);

  // Effects
  useEffect(() => {
    if (window.innerHeight) {
      setContainerHeight(window.innerHeight - window.innerHeight * 0.62);
    }
  }, [containerHeight]);

  // Render
  return (
    <PaperWithHeading label={props.label} isLoading={props.isLoading}>
      <SpendingPowerStyles containerHeight={containerHeight}>
        <div className="chart" style={{ height: containerHeight }}>
          <AutoSizer>
            {({ height, width }) => (
              <Bar
                height={height}
                width={width}
                data={formattedData}
                indexBy="group"
                theme={themeA}
                keys={['V. Low', 'Low', 'Medium', 'High', 'V. High']}
                margin={{ top: 30, right: 20, bottom: 55, left: 65 }}
                padding={0.1}
                colors={(bar) => o2colorsSpendingPower[bar.id]}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 0,
                  tickPadding: 8,
                  tickRotation: 0,
                  legend: props.visitType ? `${capitaliseText(props.visitType)}s` : 'People',
                  legendPosition: 'middle',
                  legendOffset: -55,
                  format: '~s',
                }}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 8,
                  tickRotation: 0,
                  legend: 'Spending Group',
                  legendPosition: 'middle',
                  legendOffset: 45,
                }}
                enableGridY={true}
                label={(d) =>
                  (typeof d.value === 'number' ? Math.round((d.value / ppl) * 100) : d.value) + '%'
                }
                labelSkipWidth={12}
                labelSkipHeight={12}
                legends={[]}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                tooltip={(data) => (
                  <div className="tooltip-container">
                    <h5>{data.id}</h5>
                    <p className="people">{data.value.toLocaleString()}</p>
                    <p className="people-label">
                      {props.visitType ? `${capitaliseText(props.visitType)}s` : '-'}
                    </p>
                    <p className="percent">{((data.value / ppl) * 100).toFixed(1)} %</p>
                  </div>
                )}
              />
            )}
          </AutoSizer>
        </div>
      </SpendingPowerStyles>
    </PaperWithHeading>
  );
};

export default DataBarSpendingPower;
