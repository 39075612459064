import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AudienceFilter from './Filter/Filter';
import Logo from '../Assets/Images/Logo';
import O2Logo from '../Assets/Images/O2Logo';
import TheLabLogo from '../Assets/Images/TheLabLogo';
import Logout from '../Auth/Logout';

import { AudienceFilter as AudienceFilterState } from '../../types/smartStepsApi';
import { defaultAudienceFilter } from '../../lib/filter';

import AudienceStyles from './HomeRoute.styles';
import { withTracking } from '../../lib/insights';
import { HomeButton } from '../Shared/HomeButton';

const Audience: React.FC<RouteComponentProps> = () => {
  // Handlers
  const handleSubmit = (audienceFilter: AudienceFilterState) => {
    // Proceed to dashboard
    navigate('/audience/dashboard', { state: audienceFilter });
  };

  return (
    <AudienceStyles className="motion-bg">
      <HomeButton />
      <div className="content">
        <div className="headings">
          <div className="back-button">
            <IconButton color="inherit" onClick={() => navigate('/')}>
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </div>

          <div className="logo">
            <Logo size="lg" align="center" />
          </div>
        </div>

        <AudienceFilter audienceFilter={defaultAudienceFilter} onSubmit={handleSubmit} />
      </div>
      <div className="login">
        <O2Logo />
        <Logout />
        <TheLabLogo />
      </div>
    </AudienceStyles>
  );
};

export default withTracking(Audience);
