import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import Logo from '../Assets/Images/Logo';

import LoadingStyles from './Loading.styles';

const Loading = () => (
  <LoadingStyles>
    <Logo size="lg" align="center" />
    <CircularProgress color="inherit" />
  </LoadingStyles>
);

export default Loading;
