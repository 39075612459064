import React, { useState, useEffect, CSSProperties, HTMLAttributes, useCallback } from 'react';
import Select, { createFilter } from 'react-select';
import useTheme from '@material-ui/core/styles/useTheme';
import NoSsr from '@material-ui/core/NoSsr';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import Cancel from '@material-ui/icons/Cancel';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ControlProps } from 'react-select/src/components/Control';
import { MenuProps, NoticeProps, MenuListComponentProps } from 'react-select/src/components/Menu';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { OptionProps } from 'react-select/src/components/Option';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueType } from 'react-select/src/types';
import { Omit } from '@material-ui/types';
import { FixedSizeList } from 'react-window';

import { SelectOption } from '../../../types/audienceFilter';

import MultiSelectStyles, { StyledChip } from './MultiSelect.styles';

// Types
interface Props<T> {
  data: SelectOption<T>[];
  label: string;
  placeholder: string;
  limit?: number;
  selected?: T[];
  onChange: (value: T[]) => void;
  position: 'top' | 'bottom';
  showError?: boolean;
}

type MuiPlaceholderProps<T> = Omit<PlaceholderProps<SelectOption<T>>, 'innerProps'> &
  Partial<Pick<PlaceholderProps<SelectOption<T>>, 'innerProps'>>;

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

// Internal components
const NoOptionsMessage = <T extends string>(props: NoticeProps<SelectOption<T>>) => (
  <Typography color="textSecondary" className="noOptionsMessage" {...props.innerProps}>
    {props.children}
  </Typography>
);

const inputComponent = ({ inputRef, ...props }: InputComponentProps) => (
  <div ref={inputRef} {...props} />
);

const Control = <T extends string>(props: ControlProps<SelectOption<T>>) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: 'textField',
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

const MenuList = <T extends string>(props: MenuListComponentProps<SelectOption<T>>) => {
  const { options, children, maxHeight, getValue } = props;

  if (!children || !Array.isArray(children)) return null;

  const height = 40;
  const selectedValues = getValue() as SelectOption<T>[];
  const initialOffset = selectedValues[0] ? options.indexOf(selectedValues[0]) * height : 0;

  return (
    <FixedSizeList
      width={''}
      itemSize={height}
      height={maxHeight}
      itemCount={children.length}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => (
        <div className="option-wrapper" style={style}>
          {children[index]}
        </div>
      )}
    </FixedSizeList>
  );
};

const Option = <T extends string>(props: OptionProps<SelectOption<T>>) => {
  // Speed optimisation for large datasets
  delete (props.innerProps as any).onMouseMove;
  delete (props.innerProps as any).onMouseOver;

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        // TO DO
        // Work out how to pass this prop to styled components
        // and handle this styling there
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
};

const Placeholder = <T extends string>(props: MuiPlaceholderProps<T>) => {
  const { innerProps = {}, children } = props;

  return (
    <Typography color="textSecondary" className="placeholder" {...innerProps}>
      {children}
    </Typography>
  );
};

const SingleValue = <T extends string>(props: SingleValueProps<SelectOption<T>>) => (
  <Typography {...props.innerProps}>{props.children}</Typography>
);

const ValueContainer = <T extends string>(props: ValueContainerProps<SelectOption<T>>) => (
  <div className="valueContainer">{props.children}</div>
);

const MultiValue = <T extends string>(props: MultiValueProps<SelectOption<T>>) => (
  <StyledChip
    tabIndex={-1}
    label={props.children}
    onDelete={props.removeProps.onClick}
    deleteIcon={<Cancel {...props.removeProps} />}
  />
);

const Menu = <T extends string>(props: MenuProps<SelectOption<T>>) => {
  const selectedValues = props.getValue() as SelectOption<T>[];
  const { limit } = props.selectProps;

  return !limit || (limit && selectedValues && selectedValues.length < limit) ? (
    <Paper square className="paper" {...props.innerProps}>
      {props.children}
    </Paper>
  ) : null;
};

// Component
const MultiSelect = <T extends string>(props: Props<T>) => {
  const theme = useTheme();

  // Helpers
  const buildSelected = useCallback(
    (selected: T[] | undefined, data: SelectOption<T>[]) =>
      selected ? data.filter((d) => selected.includes(d.value)) : null,
    []
  );

  const isValidNewOption = (inputValue: SelectOption<T>[], selectValue: SelectOption<T>[]) =>
    !props.limit || (props.limit && inputValue.length > 0 && selectValue.length < props.limit);

  // Local state
  const [values, setValues] = useState<ValueType<SelectOption<T>>>(
    buildSelected(props.selected, props.data)
  );

  // Effects
  useEffect(() => setValues(buildSelected(props.selected, props.data)), [
    props.selected,
    props.data,
    buildSelected,
  ]);

  // Handlers
  const handleChange = (value: ValueType<SelectOption<T>>) => {
    setValues(value);

    // Continue to parent
    props.onChange(
      value && Array.isArray(value)
        ? props.data
            .filter((d) => value.includes(d))
            .map((d) => d.value)
            .sort()
        : []
    );
  };

  // TO DO
  // Do we need this?
  // Can it be worked into StyledComponents
  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  // Render
  return (
    <NoSsr>
      <MultiSelectStyles position={props.position} showError={props.showError}>
        <Typography variant="subtitle2" gutterBottom>
          {props.label}
        </Typography>
        {props.showError ? <span>Select at least 1 location</span> : null}

        <Select
          styles={selectStyles}
          placeholder={props.placeholder}
          options={
            !props.limit || (values && Array.isArray(values) && values.length < props.limit)
              ? props.data
              : undefined
          }
          components={{
            Control,
            Menu,
            MenuList,
            MultiValue,
            NoOptionsMessage,
            Option,
            Placeholder,
            SingleValue,
            ValueContainer,
          }}
          value={values}
          onChange={handleChange}
          isDisabled={!props.data?.length}
          isMulti
          closeMenuOnSelect={false}
          filterOption={createFilter({ ignoreAccents: false })}
          limit={props.limit}
          isValidNewOption={isValidNewOption}
        />
      </MultiSelectStyles>
    </NoSsr>
  );
};

export default MultiSelect;
