import { Arrays, Iterables, pipe } from 'collection-fns';
import { CatchmentItemResult } from '../../types/Catchment';

export function mergeDuplicateResults(results: CatchmentItemResult[]): CatchmentItemResult[] {
  const path = ['UK'];
  return pipe(
    results,
    Iterables.groupBy((r) => r.origin),
    Iterables.map(([, grouped]) => {
      if (grouped.length === 1) {
        const item = grouped[0];
        return { ...item, path };
      } else {
        const item = Arrays.sortByDescending(grouped, (g) => g.num_visits)[0];
        const num_visits = Arrays.sumBy(grouped, (g) => g.num_visits);
        return { ...item, num_visits, path };
      }
    }),
    Iterables.toArray
  );
}
