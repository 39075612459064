import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleButton from '@material-ui/lab/ToggleButton';
import LayersIcon from '@material-ui/icons/Layers';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import PeopleIcon from '@material-ui/icons/People';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Tooltip from '@material-ui/core/Tooltip';
import { Arrays } from 'collection-fns';
import geojson from 'geojson';
import Leaflet, { geoJSON } from 'leaflet';
import { GeoJSON, MapContainer, TileLayer, Marker, useMap, useMapEvent } from 'react-leaflet';
import { debounce } from 'lodash';

import AreaGeoJson from '../../../assets/geojson/areas.geojson';
import DistrictGeoJson from '../../../assets/geojson/districts.geojson';
import NUTS1GeoJson from '../../../assets/geojson/nuts_level_1.geojson';
import NUTS2GeoJson from '../../../assets/geojson/nuts_level_2.geojson';
import NUTS3GeoJson from '../../../assets/geojson/nuts_level_3.geojson';
import SectorGeoJson from '../../../assets/geojson/sectors.geojson';

import {
  AggregationLevel,
  formatGridResults,
  GridResult,
  buildFilter,
} from '../../../lib/gridResults';
import { mergeMapData } from '../../../lib/mapData';
import { ApiAudienceDataState } from '../../../types/apiData';
import { numberToLocale, numberToPercent, numberToDecimal } from '../../../lib/gridResults';
import { HeatmapFields } from '../../../types/map';

import mapMarkerHover from '../../../assets/images/map-marker-hover.svg';
import mapMarkerSelected from '../../../assets/images/map-marker-selected.svg';

import 'leaflet/dist/leaflet.css';
import { StyledToggleButtonGroup } from '../../Assets/Form/ToggleButtons.styles';
import MapStyles from './Map.styles';

import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import { AuthContext } from '../../Auth/AuthProvider';
import { PostalPermission } from '../../../lib/postal';

// Types
interface Props {
  aggregationLevel: AggregationLevel;
  filter: string;
  apiData: ApiAudienceDataState;
  selected: string[];
  setSelected: (value: string) => void;
  hovered: string | undefined;
  onMapSizeChange: any;
  resize: boolean;
  viewStat: any;
  postalPermission: PostalPermission[];
}

interface MapContentProps extends Props {
  heatmapOn: boolean;
  setHeatMapOn: React.Dispatch<React.SetStateAction<boolean>>;
  heatmapField: HeatmapFields;
}

// Data
const DEFAULT_BOUNDS = new Leaflet.LatLngBounds([
  [49.783259, -8.308926],
  [61.187114, 2.369785],
]);
const DEFAULT_DEMO_BOUNDS = new Leaflet.LatLngBounds([
  [51, -5.2],
  [54, -2.7],
]);
const MAX_BOUNDS = new Leaflet.LatLngBounds([
  [45.981934, -16.476746],
  [63.568273, 9.275208],
]);

const BORDER_HOVER_STYLE: Leaflet.PathOptions = {
  stroke: true,
  color: '#303030',
  weight: 1,
  opacity: 1,
};
const BORDER_SELECTED_STYLE: Leaflet.PathOptions = {
  stroke: true,
  color: '#0019A5',
  weight: 1,
  opacity: 1,
};
const BORDER_OFF_STYLE: Leaflet.PathOptions = {
  stroke: false,
};
const FLY_OPTIONS: Leaflet.ZoomPanOptions = {
  duration: 1,
};

const markerIconHover = new Leaflet.Icon({
  iconUrl: mapMarkerHover,
  iconSize: [20, 20],
});
const markerIconSelected = new Leaflet.Icon({
  iconUrl: mapMarkerSelected,
  iconSize: [20, 20],
  className: 'marker-click-through',
});

// Helpers
const getGeoJsonPath = (aggregationLevel: AggregationLevel): string => {
  switch (aggregationLevel) {
    case AggregationLevel.NUTS1:
      return NUTS1GeoJson;
    case AggregationLevel.NUTS2:
      return NUTS2GeoJson;
    case AggregationLevel.NUTS3:
      return NUTS3GeoJson;
    case AggregationLevel.Area:
      return AreaGeoJson;
    case AggregationLevel.District:
      return DistrictGeoJson;
    case AggregationLevel.Sector:
      return SectorGeoJson;
  }
};

const buildClearStyles: Leaflet.StyleFunction = () => ({
  stroke: false,
  fillColor: '*',
  fillOpacity: 0,
});

const buildHeatmapStyles: Leaflet.StyleFunction = (feature) => ({
  stroke: false,
  fillColor: '#FF7F41',
  fillOpacity: (feature && feature.properties && feature.properties.opacity) || 0,
});

const buildUnderlayStyles: Leaflet.StyleFunction = (feature) => ({
  stroke: false,
  fillColor: feature && feature.properties && feature.properties.opacity ? '#FFFFFF' : 'grey',
  fillOpacity: feature && feature.properties && feature.properties.opacity ? 0.85 : 0.2,
});

// Render
const MapContent = (props: MapContentProps) => {
  // Not in state to prevent unnecessary useEffect calls
  const layerIDs = useRef(new Map<string, Leaflet.Layer>());
  const hoveredLayer = useRef<Leaflet.Layer | undefined>();
  const selectedLayers = useRef<Leaflet.Layer[]>([]);
  const showHeatmap = useRef(true);
  const { state: authState } = useContext(AuthContext);
  const defaultBound = useMemo(
    () => (authState?.isAuthorized ? DEFAULT_BOUNDS : DEFAULT_DEMO_BOUNDS),
    []
  );
  // Local state
  const [geoData, setGeoData] = useState<GeoJSON.FeatureCollection | null>(null);
  const [formattedApiData, setFormattedApiData] = useState<GridResult[]>([]);
  const [mapData, setMapData] = useState<GeoJSON.FeatureCollection>({
    type: 'FeatureCollection',
    features: [],
  });
  const [minZoom, setMinZoom] = useState<number | null>(null);
  const [resetEnabled, setResetEnabled] = useState<boolean>(false);
  const [resizeEnabled, setResizeEnabled] = useState<boolean>(true);
  const [infoPanel, setInfoPanel] = useState<string | undefined>(undefined);
  const [bounds, setBounds] = useState<Leaflet.LatLngBounds>(defaultBound);
  const [filter, setFilter] = useState<string>(props.filter);
  const setFilterDebounced = useRef(debounce(setFilter, 500));

  // Refs
  const geoJsonUnderlayRef = useRef<Leaflet.GeoJSON>(null);
  const geoJsonHeatmapRef = useRef<Leaflet.GeoJSON>(null);

  // Hooks
  const mapRef = useMap();

  // Helpers
  function getIconPosition(location: string | undefined): Leaflet.LatLng {
    if (!geoData || !location) {
      return new Leaflet.LatLng(0, 0);
    }

    const feature = geoData.features.find((f) => f.properties && f.properties.name === location);
    if (!feature) {
      return new Leaflet.LatLng(0, 0);
    }

    const gj = geoJSON(feature);
    const bounds = gj.getBounds();
    if (!bounds.isValid()) {
      return new Leaflet.LatLng(0, 0);
    }
    return bounds.getCenter();
  }

  const findBounds = (locations: string[]): Leaflet.LatLngBounds => {
    if (!geoData || locations.length === 0) {
      return defaultBound;
    }

    const features = geoData.features.filter(
      (f) => f.properties && locations.includes(f.properties.name)
    );
    if (features.length === 0) {
      return defaultBound;
    }

    const featureCollection: geojson.FeatureCollection = {
      type: 'FeatureCollection',
      features,
    };
    const gj = geoJSON(featureCollection);
    const bounds = gj.getBounds();
    if (!bounds.isValid()) {
      return defaultBound;
    }
    return bounds;
  };

  /*
     ---- EVENTS ----
   */

  useMapEvent('zoomend', () => {
    // Enable/disable reset zoom
    setResetEnabled(mapRef.getZoom() !== minZoom);
  });

  function resetZoom() {
    // Reset and zoom to default bounds
    if (resetEnabled) {
      setBounds(defaultBound);

      mapRef.flyToBounds(defaultBound);
    }
  }

  const zoomToSelected = () => {
    // Set map boundaries from all selected layers
    if (props.selected.length > 0) {
      setBounds(findBounds(props.selected));
    } else if (filter !== '') {
      const filterFn = buildFilter(filter);
      const layers = Arrays.choose(formattedApiData, (d) => {
        if (!filterFn(d)) {
          return undefined;
        }
        return layerIDs.current.get(d.name);
      });
      const tempFeatureGroup = new Leaflet.FeatureGroup(layers);
      setBounds(tempFeatureGroup.getBounds());
    }
  };

  const onEachFeature = (
    feature: geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties>,
    layer: Leaflet.Layer
  ) => {
    // Add ID to reference object
    if (feature.properties && feature.properties.name) {
      layerIDs.current.set(feature.properties.name, layer);
    }

    // Events
    layer.on({
      mouseover: (e: Leaflet.LeafletEvent) => {
        const layer = e.target;

        // Highlight item
        layer.setStyle(BORDER_HOVER_STYLE);

        if (!Leaflet.Browser.ie && !Leaflet.Browser.opera && !Leaflet.Browser.edge) {
          layer.bringToFront();
        }

        // Update info panel
        setInfoPanel(layer.feature.properties.name);
      },
      mouseout: (e: Leaflet.LeafletEvent) => {
        // Restore
        if (!selectedLayers.current.includes(e.target)) {
          e.target.setStyle(BORDER_OFF_STYLE);
        } else {
          e.target.setStyle(BORDER_SELECTED_STYLE);
        }

        // Remove info panel
        setInfoPanel(undefined);
      },
      click: (e: Leaflet.LeafletEvent) => {
        // Add/remove from selectedLayers
        props.setSelected(e.target.feature.properties.name);
      },
    });
  };

  const toggleHeatmap = () => {
    if (!geoJsonUnderlayRef.current || !geoJsonHeatmapRef.current) {
      return;
    }
    const underlayLeaflet = geoJsonUnderlayRef.current;
    const heatmapLeaflet = geoJsonHeatmapRef.current;
    // Update heatmap
    showHeatmap.current = !showHeatmap.current;
    underlayLeaflet.setStyle(showHeatmap.current ? buildUnderlayStyles : buildClearStyles);
    heatmapLeaflet.setStyle(showHeatmap.current ? buildHeatmapStyles : buildClearStyles);
    // Update local state (for icon - find a better way to do this?)
    props.setHeatMapOn(showHeatmap.current);
    // Restore selected outlines
    if (selectedLayers.current.length) {
      selectedLayers.current.forEach((l) => (l as Leaflet.Path).setStyle(BORDER_SELECTED_STYLE));
    }
  };

  /*
     ---- EFFECTS ----
   */

  // disable resize map when display is too small
  useEffect(() => {
    if (props.viewStat === 'mini') {
      setResizeEnabled(false);
    }
  }, [props.viewStat]);

  // re=render map when prop.resize changes
  useEffect(() => {
    mapRef.invalidateSize();
  }, [mapRef, props.resize]);

  useEffect(() => {
    // Set minimum zoomn to current (auto-zoomed based on defaultBound)

    const tempMinZoom = mapRef.getZoom();

    mapRef.setMinZoom(tempMinZoom);

    // Update local state (so we can enable/disable reset zoom)
    setMinZoom(tempMinZoom);
  }, [mapRef]);

  useEffect(() => {
    setFilterDebounced.current(props.filter);
  }, [props.filter]);

  useEffect(() => {
    mapRef.flyToBounds(bounds, FLY_OPTIONS);
  }, [mapRef, bounds]);

  // Fetch GeoJSON data
  useEffect(() => {
    fetch(getGeoJsonPath(props.aggregationLevel))
      .then((response) => response.json())
      .then((data) => setGeoData(data));
  }, [props.aggregationLevel]);

  // Format API data
  useEffect(
    () =>
      setFormattedApiData(
        formatGridResults(
          props.apiData.results,
          props.aggregationLevel,
          '',
          {
            direction: 'desc',
            field: 'count',
          },
          props.postalPermission
        )
      ),
    [props.aggregationLevel, props.apiData.results]
  );

  // Merge Geo and Formatted data
  useEffect(() => {
    if (!geoData) {
      return;
    }

    setMapData(mergeMapData(geoData, formattedApiData, filter, props.heatmapField));
  }, [geoData, formattedApiData, filter, props.heatmapField]);

  // Re-render map
  useEffect(() => {
    if (!geoJsonUnderlayRef.current || !geoJsonHeatmapRef.current) {
      return;
    }

    const underlayLeaflet = geoJsonUnderlayRef.current;
    const heatmapLeaflet = geoJsonHeatmapRef.current;

    // Clear ID's pbject
    layerIDs.current.clear();

    // Set new underlay data and styles
    underlayLeaflet.clearLayers().addData(mapData);

    underlayLeaflet.setStyle(showHeatmap.current ? buildUnderlayStyles : buildClearStyles);

    // Set new heatmap data and styles
    heatmapLeaflet.off().clearLayers().addData(mapData);

    heatmapLeaflet.setStyle(showHeatmap.current ? buildHeatmapStyles : buildClearStyles);
  }, [mapData]);

  // Set selected
  useEffect(() => {
    // Retrieve selected layers
    const newSelectedLayers: Leaflet.Layer[] = props.selected
      .map((s) => layerIDs.current.get(s) as Leaflet.Layer)
      .filter((l) => l !== undefined);

    // Clear any old selected highlights
    const oldSelectedLayers = selectedLayers.current.filter((l) => !newSelectedLayers.includes(l));

    oldSelectedLayers.forEach((l) => (l as Leaflet.Path).setStyle(BORDER_OFF_STYLE));

    // Default if no new selected
    if (!newSelectedLayers.length) {
      // Clear selected layers
      selectedLayers.current = [];

      // Revert to default zoom
      setBounds(defaultBound);
    } else {
      // Highlight everything new
      newSelectedLayers.forEach((l) => (l as Leaflet.Path).setStyle(BORDER_SELECTED_STYLE));

      // Update selected layers
      selectedLayers.current = newSelectedLayers;
    }
  }, [props.selected]);

  // Set hovered outline
  useEffect(() => {
    if (hoveredLayer.current === props.hovered) {
      return;
    }

    // Remove existing hover style
    const currentHoveredLayer = hoveredLayer.current;

    if (currentHoveredLayer && currentHoveredLayer instanceof Leaflet.Path) {
      if (!selectedLayers.current.includes(currentHoveredLayer)) {
        currentHoveredLayer.setStyle(BORDER_OFF_STYLE);
      } else {
        (currentHoveredLayer as Leaflet.Path).setStyle(BORDER_SELECTED_STYLE);
      }

      hoveredLayer.current = undefined;
    }

    // Add new hover style
    if (props.hovered) {
      const layer = layerIDs.current.get(props.hovered);

      if (layer && layer instanceof Leaflet.Path) {
        layer.setStyle(BORDER_HOVER_STYLE);

        hoveredLayer.current = layer;
      }
    }
  }, [props.hovered]);

  //console.log({ mapData });

  const infoPanelData = infoPanel ? formattedApiData.find((r) => r.name === infoPanel) : undefined;
  return (
    <>
      {' '}
      {/* Map layer */}
      {/* <TileLayer
      url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}{r}.png"
      attribution='<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>'
    /> */}
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      {/* GeoJSON layer (and heatmap) */}
      <GeoJSON ref={geoJsonUnderlayRef} data={mapData} />
      <GeoJSON ref={geoJsonHeatmapRef} data={mapData} onEachFeature={onEachFeature} />
      {/* Reset zoom & toggle heatmap */}
      <div className="leaflet-top leaflet-right">
        <div className="leaflet-control leaflet-bar info-pane buttons">
          <div className="button" data-enabled={resizeEnabled} onClick={props.onMapSizeChange}>
            <Tooltip title={props.resize ? 'Increase size' : 'Decrease size'} placement="right">
              {props.resize ? (
                <SettingsEthernetIcon fontSize="inherit" />
              ) : (
                <CompareArrowsIcon fontSize="inherit" />
              )}
            </Tooltip>
          </div>
        </div>

        <div className="leaflet-control leaflet-bar info-pane buttons">
          {/* change size of map*/}
          <div className="button" data-enabled={resetEnabled} onClick={resetZoom}>
            <Tooltip title="Reset zoom" placement="right">
              <PublicIcon fontSize="inherit" />
            </Tooltip>
          </div>
          <div
            className="button"
            data-enabled={props.selected.length > 0 || filter !== ''}
            onClick={zoomToSelected}
          >
            <Tooltip title="Zoom to selected" placement="right">
              <FilterCenterFocusIcon fontSize="inherit" />
            </Tooltip>
          </div>
        </div>

        <div className="leaflet-control leaflet-bar info-pane buttons">
          <div className="button" data-enabled="true" onClick={toggleHeatmap}>
            <Tooltip title={props.heatmapOn ? 'Hide heatmap' : 'Show heatmap'} placement="right">
              {props.heatmapOn ? (
                <LayersIcon fontSize="inherit" />
              ) : (
                <LayersClearIcon fontSize="inherit" />
              )}
            </Tooltip>
          </div>
        </div>
      </div>
      {/* Info panel */}
      <div className="leaflet-bottom leaflet-left">
        {infoPanelData && (
          <div className="leaflet-control leaflet-bar info-pane">
            <h3>{infoPanelData.name}</h3>

            <div className="row">
              <div className="left icon">
                <PeopleIcon fontSize="inherit" />
              </div>
              <p className="right data">{numberToLocale(infoPanelData.count)}</p>
            </div>

            <div className="row">
              <p className="left label">R.P</p>
              <p className="right data">{numberToDecimal(infoPanelData.rp)}</p>
            </div>

            <div className="row">
              <p className="left label">R.I</p>
              <p className="right data">{numberToPercent(infoPanelData.ri)}</p>
            </div>
          </div>
        )}
      </div>
      {/* Hover marker */}
      <Marker icon={markerIconHover} position={getIconPosition(props.hovered)} />
      {/* Selected markers */}
      {props.selected
        .map((selected) => {
          const position = getIconPosition(selected);
          return <Marker key={selected} icon={markerIconSelected} position={position} />;
        })
        .filter((el) => el !== undefined)}
    </>
  );
};

const TheMap = (props: Props) => {
  const [heatmapOn, setHeatMapOn] = useState<boolean>(true);
  const [heatmapField, setHeatmapField] = useState<HeatmapFields>('count');
  const { state: authState } = useContext(AuthContext);
  const defaultBound = useMemo(
    () => (authState?.isAuthorized ? DEFAULT_BOUNDS : DEFAULT_DEMO_BOUNDS),
    []
  );
  /*
    ---- RENDER ----
  */

  return (
    <MapStyles heatmapOn={heatmapOn}>
      <MapContainer
        fullscreenControl={true}
        bounds={defaultBound}
        maxBounds={MAX_BOUNDS}
        zoomSnap={0.25}
        //whenReady={updateMinZoom}
        //onzoomend={isMinZoom}
      >
        <MapContent
          {...props}
          heatmapOn={heatmapOn}
          setHeatMapOn={setHeatMapOn}
          heatmapField={heatmapField}
          postalPermission={props.postalPermission}
        />
      </MapContainer>

      {/* Heatmap content */}
      {heatmapOn && (
        <div className="heatmap-select">
          <StyledToggleButtonGroup
            exclusive
            size="small"
            value={heatmapField}
            onChange={(event, value) => value && setHeatmapField(value)}
            className="heatmap"
          >
            <ToggleButton value="count">People</ToggleButton>
            <ToggleButton value="rp">R.P</ToggleButton>
            <ToggleButton value="ri">R.I</ToggleButton>
          </StyledToggleButtonGroup>
        </div>
      )}

      {props.apiData.loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
    </MapStyles>
  );
};

export default TheMap;
