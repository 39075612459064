import styled, { css } from 'styled-components';

import { ViewProps } from '../../../types/global';

export const AsideStyles = styled.section<ViewProps>`
  flex: 0 0 25rem;

  display: flex;

  ${(props) =>
    props.viewStatus !== 'full' &&
    css`
      z-index: 1001;
    `}

  > .profiles-summary {
    flex: 0 0 25rem;

    min-height: 100vh;

    display: flex;

    flex-direction: column;

    background: #003;
    box-shadow: 0rem 0 1rem 0 rgba(0, 0, 0, 0.5);

    padding: 3rem;

    color: #ffffff;

    h1 {
      margin: 0;

      font-size: 3.4rem;
      font-weight: 900;
    }

    h3 {
      margin: 0;

      font-size: 1.8rem;
      font-weight: 600;
    }

    h4 {
      margin: 0 0 0.2rem;

      font-size: 1.2rem;
      font-weight: 600;
      opacity: 0.6;
    }

    > .logo-container {
      > .brand-logo {
        flex: 0 0 auto;

        padding-bottom: 0.2rem;
        border-bottom: 0.1rem solid rgba(255, 255, 255, 0.3);
      }

      > #open-btn {
        display: flex;
        justify-content: center;

        padding: 1rem 0;

        > svg {
          font-size: 3rem;
          color: white;
        }

        &:hover svg {
          color: #0090d0;
          cursor: pointer;
        }
      }
    }

    > .scroller {
      flex: 1 1 auto;

      position: relative;

      > .scroller-content {
        position: absolute;

        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        overflow-y: scroll;

        > .date-and-time,
        .filter-summary {
          padding-top: 3rem;
        }
      }
    }
  }

  ${(props) =>
    props.viewStatus === 'mini' &&
    css`
      flex: 0 0 10rem;

      > .profiles-summary {
        flex: 0 0 10rem;

        > .logo-container {
          position: fixed;
        }

        > .scroller {
          display: none;
        }
      }
    `}

  ${(props) =>
    props.viewStatus === 'full-with-screen' &&
    css`
      position: fixed;

      > .profiles-summary {
        z-index: 1002;
      }

      > #close-btn {
        z-index: 1002;

        height: 100%;

        background-color: #0090d0;

        padding: 1rem;

        > svg {
          color: white;
          font-size: 1.8rem;
        }

        &:hover {
          cursor: pointer;
        }
      }
    `}

  > .screen {
    flex: 1 1 auto;

    display: flex;

    position: fixed;

    height: 100vh;
    width: 100%;

    background-color: black;
    opacity: 0.5;
  }
`;

const Dashboard = styled.section<ViewProps>`
  display: flex;

  min-height: 100vh;

  > main {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;

    padding: 2rem 2rem 0;

    ${(props) =>
      props.viewStatus === 'full-with-screen' &&
      css`
        padding-left: 27rem;
      `}

    >.download {
      margin-bottom: 1rem;
      text-align: right;

      span,
      svg {
        color: #ffffff;
        opacity: 0.9;
      }

      button {
        padding: 0 0 0 1rem;
      }
    }

    > .columns {
      flex: 1 1 auto;

      height: 100%;

      display: flex;

      ${(props) => props.theme.breakpoints.down('md')} {
        flex-direction: column;
      }

      > .column-one {
        flex: 1 1 57%;

        &.no-second-inner-column {
          flex: 1 1 14%;
        }
        min-height: 100%;

        display: flex;

        ${(props) => props.theme.breakpoints.up('lg')} {
          flex: 1 1 57%;
        }

        ${(props) => props.theme.breakpoints.down('md')} {
          flex: 0 0 auto;

          min-height: 90vh;
        }

        > .inner-one {
          flex: 1 1 30%;

          display: flex;
          flex-direction: column;

          > .totals {
            flex: 0 0 auto;

            padding-bottom: 2rem;
          }

          > .genders {
            flex: 1 1 auto;
          }
        }

        > .inner-two {
          flex: 1 1 70%;

          display: flex;
          flex-direction: column;

          padding-left: 2rem;

          > .age-bands {
            flex: 1 1 50%;

            min-height: 30rem;

            padding-bottom: 1rem;
          }

          > .spending-power {
            flex: 1 1 50%;

            min-height: 30rem;

            padding-top: 1rem;
          }
        }
      }

      > .column-two {
        flex: 1 1 42%;

        ${(props) => props.theme.breakpoints.up('lg')} {
          padding-left: 2rem;
        }

        ${(props) => props.theme.breakpoints.down('md')} {
          padding-top: 2rem;

          min-height: 60rem;
        }
      }

      .tooltip-container {
        > h5 {
          display: inline-block;

          margin: 0 0 1rem;

          border-bottom: 1px solid #efefef;

          font-size: 1.4rem;
          font-weight: 700;
        }

        > p {
          margin: 0;

          &.people {
            font-size: 2rem;
            line-height: 2rem;
            font-weight: 300;
          }

          &.people-label {
            margin-top: 0.2rem;

            text-transform: capitalize;
            font-size: 1.2rem;
            font-weight: 300;
          }

          &.percent {
            margin-top: 1rem;

            font-size: 1.6rem;
            font-weight: 700;
          }
        }
      }
    }

    > .login {
      flex: 0 0 4rem;

      display: flex;

      justify-content: center;
      align-items: center;
    }
  }
`;

export default Dashboard;
