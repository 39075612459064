import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { getAuthState, login } from '../../lib/authAzure';
import { AuthContext } from './AuthProvider';
import { SignIn } from './SignIn';
import Loading from './Loading';
import { withTracking } from '../../lib/insights';

const RequireLogin = (props: {
  children?: ((props: PropsWithChildren<{}>) => React.ReactNode) | React.ReactNode;
}) => {
  const { state, dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (state === undefined) {
      getAuthState().then((authState) => {
        return dispatch({ type: 'SET_AUTH', payload: authState });
      });
    }
  }, [state, dispatch]);

  if (state === undefined) {
    return <Loading />;
  }

  if (state.isAuthenticated) {
    return <>{props.children}</>;
  }

  if (window.location.pathname === '/sign-in' && !state.isAuthenticated) {
    login();
    return <></>;
  }
  return <SignIn onSignIn={(authState) => dispatch({ type: 'SET_AUTH', payload: authState })} />;
};

export default withTracking(RequireLogin);
