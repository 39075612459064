import React, { useEffect, useRef, useState } from 'react';
import { ApiCatchmentDataState } from '../../../../types/apiData';
import { RefinementState } from '../../../../types/refinement';
import { DataGridStyles } from './DataGrid.styles';
import {
  Button,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { AutoSizer, Column, RowMouseEventHandlerParams, Table } from 'react-virtualized';
import { getColumnDef } from './DataGrid.columns';
import { GetApp, Room } from '@material-ui/icons';
import { DataGridHeader } from './DataGrid.header';
import { DataGridCell } from './DataGrid.cell';
import { CatchmentGridSort, CatchmentItemResult, ColumnWidths } from '../../../../types/Catchment';
import { formatCatchmentGridResults } from '../../../../lib/Catchment';

type DataGridProps = {
  apiData: ApiCatchmentDataState;
  refinement: RefinementState;
  setHovered: (hovered: string | undefined) => void;
  selected: string[];
  toggleSelected: (selected: string) => void;
  clearSelected: () => void;
  lastSelected?: string;
  resize: boolean;
};

export const DataGrid: React.FC<DataGridProps> = ({
  apiData,
  refinement,
  setHovered,
  selected,
  toggleSelected,
  clearSelected,
  lastSelected,
  resize,
}) => {
  const rowHeight = 48;
  const { loading, results } = apiData;
  const { aggregationLevel, filter } = refinement;

  const [componentWidth, setComponentWidth] = useState<number>(0);
  const [columnWidths, setColumnWidths] = useState<ColumnWidths>({
    type: 0,
    origin: 0,
    visits: 0,
    marker: 0,
  });

  const [sorting, setSorting] = useState<CatchmentGridSort>({
    field: 'num_visits',
    direction: 'desc',
  });

  const [formattedResults, setFormattedResults] = useState<CatchmentItemResult[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>();

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const mediaSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const columns = getColumnDef(columnWidths, {
    dataKey: 'selected',
    label: <Room fontSize="small" />,
    align: 'right',
    width: columnWidths.marker,
  });

  const onRowClick = (info: RowMouseEventHandlerParams) => {
    toggleSelected(info.rowData.origin);
  };

  const handleExport = () => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += `Origin,Type,Visits \r\n`;

    formattedResults.forEach((r) => {
      csvContent += `${r.origin},${r.origin_type},${r.num_visits} \r\n`;
    });

    const a = document.createElement('a');
    a.download = `audienceinsights-catchment-${Date.now()}.csv`;
    a.href = encodeURI(csvContent);
    a.dispatchEvent(new MouseEvent('click'));
  };

  const onMouseOverHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const el = e.target as HTMLElement;
    const row = el.closest('.row');
    const postcode = row?.firstElementChild?.textContent;
    const role = row?.firstElementChild?.getAttribute('role');

    if (postcode && role !== 'columnheader') {
      setHovered(postcode);
    } else {
      setHovered(undefined);
    }
  };

  const getContainerWidth = () => (containerRef.current ? containerRef.current.offsetWidth : 0);

  // Effects
  useEffect(() => {
    // Set initial size
    // HACK: Delay calculating initial size ... it's a bit too small otherwise.
    setTimeout(() => setComponentWidth(getContainerWidth()), 0);

    // Update on resize
    window.addEventListener('resize', () => {
      setComponentWidth(getContainerWidth());
    });

    // Cleanup
    return () => {
      window.removeEventListener('resize', () => {
        setComponentWidth(getContainerWidth());
      });
    };
  }, [resize]);

  useEffect(() => {
    const widths: ColumnWidths =
      !resize || mediaSmall
        ? {
            origin: componentWidth * 0.2,
            type: componentWidth * 0.3,
            visits: componentWidth * 0.2,
            marker: componentWidth * 0.16,
          }
        : {
            origin: componentWidth * 0.2,
            type: componentWidth * 0.35,
            visits: componentWidth * 0.2,
            marker: componentWidth * 0.16,
          };

    setColumnWidths(widths);
  }, [componentWidth, mediaSmall, resize]);

  useEffect(() => {
    // Re-format the results
    if (results) {
      setFormattedResults(formatCatchmentGridResults(results, filter, sorting, selected));
    }
  }, [results, aggregationLevel, filter, sorting, selected]);

  useEffect(() => {
    const index = formattedResults.findIndex((r) => r.origin === lastSelected);

    setSelectedIndex(index);
  }, [lastSelected, formattedResults]);

  return (
    <DataGridStyles>
      {loading && <LinearProgress variant="indeterminate" color="secondary" className="loader" />}

      <div className="fixed-grid">
        <div className="scroll-grid">
          <div
            ref={containerRef}
            className="outer-grid"
            onMouseOver={onMouseOverHandler}
            onMouseLeave={() => setHovered(undefined)}
          >
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  height={height}
                  width={width}
                  columns={columns}
                  gridClassName="inner-grid"
                  headerClassName="header"
                  headerHeight={rowHeight}
                  rowCount={formattedResults.length}
                  rowGetter={({ index }) => formattedResults[index]}
                  rowHeight={rowHeight}
                  rowClassName="row"
                  scrollToIndex={selectedIndex}
                  onRowClick={onRowClick}
                >
                  {columns.map(({ dataKey, ...other }) => {
                    return (
                      <Column
                        key={dataKey}
                        dataKey={dataKey}
                        headerRenderer={(headerProps) =>
                          DataGridHeader({ ...headerProps, columns, sorting, setSorting })
                        }
                        className="column"
                        cellRenderer={(cellPros) =>
                          DataGridCell({ ...cellPros, columns, selected })
                        }
                        {...other}
                      />
                    );
                  })}
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
      <div className="summary">
        <div className="selected">
          <Typography variant="caption" display="inline" color="textSecondary">
            {selected.length} selected
          </Typography>
          <Button
            size="small"
            disabled={!selected.length}
            className="clear-button"
            onClick={clearSelected}
          >
            Clear
          </Button>
        </div>
        <div className="download">
          <Typography variant="caption" display="inline" color="textSecondary" className="total">
            Rows: {Number(formattedResults.length).toLocaleString()}
          </Typography>
          <IconButton
            color="secondary"
            onClick={handleExport}
            disabled={loading || !formattedResults.length}
          >
            <GetApp />
          </IconButton>
        </div>
      </div>
    </DataGridStyles>
  );
};
