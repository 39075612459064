import { Arrays } from 'collection-fns';
import { FeatureCollection } from 'geojson';
import { HeatmapCatchmentFields } from '../../types/map';
import { buildCatchmentFilter } from '.';
import { CatchmentItemResult } from '../../types/Catchment';

export function mergeCatchmentMapData(
  geoData: FeatureCollection,
  formattedApiData: CatchmentItemResult[],
  filter: string,
  heatmapField: HeatmapCatchmentFields
): FeatureCollection {
  if (!formattedApiData.length) {
    // Default to unedited GeoData
    return {
      ...geoData,
      features: geoData.features.filter((f) => f.geometry !== null),
    };
  }

  // Setup filter
  const filterFn = buildCatchmentFilter(filter);
  const filteredData = formattedApiData.filter(filterFn);

  // Calculate percentage range
  const lowestValue = filteredData.length
    ? Math.round(Arrays.minBy(filteredData, (row) => row[heatmapField]) * 10) / 10
    : Number.NaN;
  const highestValue = filteredData.length
    ? Math.round(Arrays.maxBy(filteredData, (row) => row[heatmapField]) * 10) / 10
    : Number.NaN;
  const range = highestValue - lowestValue + 0.01;

  // Restructure data for easy reference
  const indexed = new Map(formattedApiData.map((d) => [d.origin, d]));

  // Add opacity to data
  const features = Arrays.choose(geoData.features, (d) => {
    // Validate data
    if (!d.geometry || !d.properties || !d.properties.name) {
      return undefined;
    }

    const apiRow = indexed.get(d.properties.name);

    if (!apiRow) {
      return undefined;
    }

    // Set visibility
    const isVisible = filterFn(apiRow);

    // Set heatmap opacity
    if (apiRow[heatmapField] && range) {
      d.properties.opacity = isVisible ? (apiRow[heatmapField] + 0.01 - lowestValue) / range : 0;
    } else {
      d.properties.opacity = 0;
    }

    return d;
  });

  return {
    ...geoData,
    features,
  };
}
