import React, { useState, useEffect, memo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Clear from '@material-ui/icons/Clear';
import Tune from '@material-ui/icons/Tune';
import GetApp from '@material-ui/icons/GetApp';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import useTheme from '@material-ui/core/styles/useTheme';
import XLSX from 'xlsx/xlsx.mini';

import Logo from '../../Assets/Images/Logo';
import ChangeDashboard from '../../Assets/Images/ChangeDashboard';
import DateTime from '../../Shared/DateTimeSummary';
import Summary from './FilterSummary';
import SpendingPower from './SpendingPower';
import Interests from './Interests';
import Gender from './Gender';
import GenderAgeBand from './GenderAgeBand';
import Logout from '../../Auth/Logout';

import { serializeProfilesQueryString } from '../../../lib/queryString';
import { queryProfiles } from '../../../lib/smartStepsApi';
import { ApiProfilesDataState } from '../../../types/apiData';
import {
  ProfilesQuery,
  Interval,
  AgeBand as AgeBandType,
  InterestsResult,
  Interest,
  InterestsResultData,
  interestSet,
  InterestsResultV2,
} from '../../../types/smartStepsApi';
import { ViewStatus } from '../../../types/global';
import {
  interestsValueToLabel,
  SPENDING_POWERS,
  subInterestValueToLabel,
} from '../../../constants/filter';

import DashboardStyles, { AsideStyles } from './Dashboard.styles';
import TotalAndVisitType from './TotalAndVisitType';
import { appInsights } from '../../../lib/insights';

// Memoize child components
const DateTimeMemo = memo(DateTime);
const SummaryMemo = memo(Summary);

// Types
type ReportGender = {
  Gender: string;
  Total: number;
};

type ReportAgeBand = {
  'Age Band': AgeBandType;
  Male?: number;
  Female?: number;
};

// Component
const Dashboard = (props: { initialQuery: ProfilesQuery }) => {
  // Local State
  const [apiData, setApiData] = useState<ApiProfilesDataState>({
    loading: false,
    count: 0,
    results: undefined,
    search: undefined,
  });
  const [interestsData, setInterestsData] = useState<InterestsResult>({});
  const [subInterestsData, setSubInterestsData] = useState<
    { [key in Interest]?: InterestsResultV2 }
  >({});
  const [dataFilter, setDataFilter] = useState<ProfilesQuery>(props.initialQuery);
  const [viewStatus, setViewStatus] = useState<ViewStatus>(undefined);
  const [category, setCategory] = useState<string>();
  // Handlers
  const handleDateTime = (interval: Interval) => {
    setDataFilter((old) => ({
      ...old,
      dateRange: interval.dateRange,
      timeOfDay: interval.timeOfDay,
    }));
  };

  // Effects
  const theme = useTheme();
  const miniView = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    miniView ? setViewStatus('mini') : setViewStatus('full');
  }, [miniView]);

  useEffect(() => {
    // Update query string
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${serializeProfilesQueryString(dataFilter)}`
    );
    setCategory('');
  }, [dataFilter]);

  useEffect(() => {
    // Query API
    setApiData((data) => ({ ...data, loading: true }));

    queryProfiles(dataFilter)
      .then((data) => {
        setApiData({ ...data, loading: false });
        const allResultInterests = Object.keys(data.results.interests);
        const resultInterestsKeys = allResultInterests.filter((k) =>
          interestSet.has(k as any)
        ) as Interest[];
        const resultInterests = resultInterestsKeys.reduce((a, interestKey) => {
          a[interestKey as Interest] = data.results.interests[interestKey as Interest];
          return a;
        }, {} as InterestsResult);
        setInterestsData(resultInterests);
        const subInterest = resultInterestsKeys.reduce(
          (a, interestKey) => {
            a[interestKey] = allResultInterests
              .filter((k) => k.startsWith(interestKey) && k.includes('__'))
              .reduce((res, subInterestKey) => {
                res[subInterestKey] = (data.results.interests as any)[subInterestKey];
                return res;
              }, {} as InterestsResultV2);
            return a;
          },
          {} as {
            [key in Interest]: {
              [key: string]: InterestsResultData;
            };
          }
        );

        setSubInterestsData(subInterest);
      })
      .catch((err) => {
        appInsights?.trackException({ exception: err });
        setApiData((data) => ({ ...data, count: 0, results: undefined, loading: false }));
        setInterestsData({});
      });
  }, [dataFilter]);

  // Handlers
  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    // Genders
    const gender = apiData.results?.gender;

    if (gender) {
      const gendersData: ReportGender[] = [];

      if (gender.m) {
        gendersData.push({
          Gender: 'Male',
          Total: gender.m,
        });
      }

      if (gender.f) {
        gendersData.push({
          Gender: 'Female',
          Total: gender.f,
        });
      }

      const gendersWorksheet = XLSX.utils.json_to_sheet(gendersData);

      XLSX.utils.book_append_sheet(workbook, gendersWorksheet, 'Gender');
    }

    // Age Band
    const ageBand = apiData.results?.gender_age_band;

    if (ageBand) {
      const ageBandHeaders = ['Age Band'];
      const ageBandData: ReportAgeBand[] = [];

      if (ageBand.m) {
        ageBandHeaders.push('Male');

        Object.entries(ageBand.m).forEach(([key, val]) => {
          if (val) {
            const ageBandIndex = ageBandData.findIndex((r) => r['Age Band'] === key);

            if (ageBandIndex !== -1) {
              ageBandData[ageBandIndex].Male = val;
            } else {
              ageBandData.push({
                'Age Band': key as AgeBandType,
                Male: val,
              });
            }
          }
        });
      }

      if (ageBand.f) {
        ageBandHeaders.push('Female');

        Object.entries(ageBand.f).forEach(([key, val]) => {
          if (val) {
            const ageBandIndex = ageBandData.findIndex((r) => r['Age Band'] === key);

            if (ageBandIndex !== -1) {
              ageBandData[ageBandIndex].Female = val;
            } else {
              ageBandData.push({
                'Age Band': key as AgeBandType,
                Female: val,
              });
            }
          }
        });
      }

      const ageBandWorksheet = XLSX.utils.json_to_sheet(ageBandData, {
        header: ageBandHeaders,
      });

      XLSX.utils.book_append_sheet(workbook, ageBandWorksheet, 'Age Band');
    }

    // Spending Power
    const spendingPower = apiData.results?.spending_power;

    if (spendingPower) {
      const spendingPowerHeaders = ['Spending Group', 'Total'];
      const spendingPowerData = Object.entries(spendingPower).map(([key, val]) => ({
        'Spending Group': SPENDING_POWERS.find((s) => s.value === key)?.label || '',
        Total: val,
      }));

      const spendingPowerWorksheet = XLSX.utils.json_to_sheet(spendingPowerData, {
        header: spendingPowerHeaders,
      });

      XLSX.utils.book_append_sheet(workbook, spendingPowerWorksheet, 'Spending Power');
    }

    // Interests
    const interests = apiData.results?.interests;

    if (interests) {
      const interestsHeaders = ['Interest', 'Low', 'Medium', 'High'];
      const interestsData = Object.entries(interests).map(([key, val]) => ({
        Interest: key,
        Low: val?.l || 0,
        Medium: val?.m || 0,
        High: val?.h || 0,
      }));

      const interestsWorksheet = XLSX.utils.json_to_sheet(interestsData, {
        header: interestsHeaders,
      });

      XLSX.utils.book_append_sheet(workbook, interestsWorksheet, 'Interests');
    }

    // Download
    XLSX.writeFile(workbook, `audienceinsights-profiles-${Date.now()}.xlsx`);
  };

  // Render
  return (
    <DashboardStyles viewStatus={viewStatus}>
      <AsideStyles viewStatus={viewStatus}>
        <div className="profiles-summary">
          <div className="logo-container">
            <div className="brand-logo">
              <Logo size="md" align="left" viewStatus={viewStatus} linkToHome />
            </div>

            {viewStatus === 'mini' ? (
              <div id="open-btn">
                <Tune
                  onClick={() => {
                    setViewStatus('full-with-screen');
                  }}
                />
              </div>
            ) : null}
          </div>

          <div className="scroller">
            <div className="scroller-content">
              <div className="date-and-time">
                <DateTimeMemo interval={dataFilter} onSubmit={handleDateTime} />
              </div>
              <div className="filter-summary">
                <SummaryMemo
                  profilesFilter={dataFilter}
                  onChange={(profilesFilter) => {
                    setDataFilter((filter) => ({ ...filter, ...profilesFilter }));
                  }}
                />
              </div>
              <ChangeDashboard linkTo="audience" />
              <ChangeDashboard linkTo="catchment" />
            </div>
          </div>
        </div>
        {viewStatus === 'full-with-screen' ? (
          <>
            <div id="close-btn">
              <Clear
                onClick={() => {
                  setViewStatus('mini');
                }}
              />
            </div>
            <div
              className="screen"
              onClick={() => {
                setViewStatus('mini');
              }}
            ></div>
          </>
        ) : null}
      </AsideStyles>

      <main className="motion-bg">
        <div className="download">
          <Typography variant="caption" display="inline">
            Download
          </Typography>
          <IconButton onClick={() => handleExport()} disabled={apiData.loading}>
            <GetApp />
          </IconButton>
        </div>
        <div className="columns">
          <section className={(category ? 'no-second-inner-column ' : '') + 'column-one'}>
            <div className="inner-one">
              <div className="totals">
                <TotalAndVisitType
                  isLoading={apiData.loading}
                  count={apiData.count}
                  visitType={apiData.search ? apiData.search.visitType : undefined}
                />
              </div>

              <div className="genders">
                <Gender
                  label="Gender"
                  isLoading={apiData.loading}
                  data={apiData.results?.gender_age_band}
                  extraData={apiData.results?.gender}
                  visitType={apiData.search ? apiData.search.visitType : undefined}
                  ppl={apiData.count}
                />
              </div>
            </div>
            {!category && (
              <div className="inner-two">
                <div className="age-bands">
                  <GenderAgeBand
                    label="Age Band"
                    isLoading={apiData.loading}
                    search={apiData.search?.gender}
                    data={apiData.results?.gender_age_band}
                    visitType={apiData.search ? apiData.search.visitType : undefined}
                    ppl={apiData.count}
                  />
                </div>

                <div className="spending-power">
                  <SpendingPower
                    label="Spending Power"
                    data={apiData.results?.spending_power}
                    visitType={apiData.search ? apiData.search.visitType : undefined}
                    isLoading={apiData.loading}
                    ppl={apiData.count}
                  />
                </div>
              </div>
            )}
          </section>

          <section className="column-two">
            <Interests
              label="Interests"
              data={interestsData}
              subInterests={subInterestsData}
              onClick={(v) => setCategory(v)}
              visitType={apiData.search ? apiData.search.visitType : undefined}
              isLoading={apiData.loading}
              valueToLabelMap={interestsValueToLabel}
              ppl={apiData.count}
            />
          </section>
          {category && (
            <section className="column-two">
              <Interests
                label="Sub Interests"
                data={(subInterestsData as any)[category] as any}
                subInterests={{}}
                onClick={() => {}}
                visitType={apiData.search ? apiData.search.visitType : undefined}
                isLoading={apiData.loading}
                valueToLabelMap={subInterestValueToLabel}
                ppl={apiData.count}
                showCloseButton={true}
                onClose={() => setCategory('')}
              />
            </section>
          )}
          {/*
          <DataTable label="Home Location" data={apiData.results?.home_location} />
          <DataTable label="Work Location" data={apiData.results?.work_location} />
          */}
        </div>

        <div className="login">
          <Logout />
        </div>
      </main>
    </DashboardStyles>
  );
};

export default Dashboard;
