import HomeIcon from '@material-ui/icons/Home';
import { HomeButtonStyles } from './HomeButton.styles';
import React from 'react';

export const HomeButton = () => {
  return (
    <HomeButtonStyles href={process.env.REACT_APP_O2MOTION_PORTAL}>
      <HomeIcon />
    </HomeButtonStyles>
  );
};
