import React, { ChangeEvent, useContext } from 'react';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { AggregationLevel } from '../../../lib/gridResults';
import { RefinementState } from '../../../types/refinement';

import { StyledFormControl } from '../../../styles/MaterialComponents';
import SentenceStyles from './Sentence.styles';
import { AuthContext } from '../../Auth/AuthProvider';

const Sentence = (props: {
  refinement: RefinementState;
  onChange: (refinement: RefinementState) => void;
  isLoading: boolean;
}) => {
  const values = props.refinement;
  const { state: authState } = useContext(AuthContext);
  // Handlers
  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const aggregationLevel = event.target.value as AggregationLevel;
    props.onChange({
      ...values,
      aggregationLevel,
    });
  };

  const handlePostcodeChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
    const value = event.target.value;
    //console.log({ value });
    console.log({ values });
    props.onChange({ ...values, filter: value });
  };

  return (
    <SentenceStyles>
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <div>
          {!authState?.isAuthorized && (
            <div className="demo-user-note">
              You are in demo mode. Only locations in Wales region available.
            </div>
          )}
          <Typography variant="h5" className="text">
            Show me
          </Typography>
          <StyledFormControl>
            <Select
              className="field"
              value={values.aggregationLevel}
              onChange={handleChange}
              displayEmpty
              input={<Input name="searchScope" id="age-auto-width" />}
              autoWidth
              disabled={props.isLoading}
            >
              <MenuItem value={AggregationLevel.Sector}>postcode sectors</MenuItem>
              <MenuItem value={AggregationLevel.District}>postcode districts</MenuItem>
              <MenuItem value={AggregationLevel.Area}>postcode areas</MenuItem>
              <MenuItem value={AggregationLevel.NUTS3}>NUTS3 areas</MenuItem>
              <MenuItem value={AggregationLevel.NUTS2}>NUTS2 areas</MenuItem>
              <MenuItem value={AggregationLevel.NUTS1}>NUTS1 areas</MenuItem>
            </Select>
            <FormHelperText>Level of detail</FormHelperText>
          </StyledFormControl>
        </div>
        <div>
          <Typography variant="h5" className="text">
            within
          </Typography>
          <StyledFormControl>
            <TextField
              className="field"
              value={values.filter}
              name="searchScope"
              onChange={handlePostcodeChange}
              placeholder="search..."
              disabled={props.isLoading}
            />
            <FormHelperText>Postcode or NUTS area</FormHelperText>
          </StyledFormControl>
        </div>
      </form>
    </SentenceStyles>
  );
};

export default Sentence;
