import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import LayersIcon from '@material-ui/icons/Layers';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import PeopleIcon from '@material-ui/icons/People';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Tooltip from '@material-ui/core/Tooltip';
import { Arrays } from 'collection-fns';
import geojson from 'geojson';
import Leaflet, { geoJSON } from 'leaflet';
import { GeoJSON, TileLayer, Marker, useMap, useMapEvent } from 'react-leaflet';
import { debounce } from 'lodash';
import { AggregationLevel } from '../../../../lib/gridResults';
import { ApiCatchmentDataState } from '../../../../types/apiData';
import { ViewStatus } from '../../../../types/global';
import { HeatmapCatchmentFields } from '../../../../types/map';

import {
  BORDER_HOVER_STYLE,
  BORDER_OFF_STYLE,
  BORDER_SELECTED_STYLE,
  DEFAULT_BOUNDS,
  DEFAULT_DEMO_BOUNDS,
  FLY_OPTIONS,
  buildClearStyles,
  buildHeatmapStyles,
  buildUnderlayStyles,
  getGeoJsonPath,
  markerIconHover,
  markerIconSelected,
} from './HeatMap.constant';
import { AuthContext } from '../../../Auth/AuthProvider';
import { CatchmentItemResult } from '../../../../types/Catchment';
import {
  buildCatchmentFilter,
  formatCatchmentGridResults,
  mergeCatchmentMapData,
} from '../../../../lib/Catchment';

interface HeatMapContentProps {
  aggregationLevel: AggregationLevel;
  filter: string;
  apiData: ApiCatchmentDataState;
  selected: string[];
  setSelected: (value: string) => void;
  hovered: string | undefined;
  onMapSizeChange: React.MouseEventHandler<HTMLDivElement>;
  resize: boolean;
  viewStatus: ViewStatus;
  heatmapOn: boolean;
  setHeatMapOn: React.Dispatch<React.SetStateAction<boolean>>;
  heatmapField: HeatmapCatchmentFields;
}

export const HeatMapContent: React.FC<HeatMapContentProps> = (props) => {
  const { state: authState } = useContext(AuthContext);
  const defaultBound = useMemo(
    () => (authState?.isAuthorized ? DEFAULT_BOUNDS : DEFAULT_DEMO_BOUNDS),
    []
  );

  // Local state
  const [geoData, setGeoData] = useState<GeoJSON.FeatureCollection>();
  const [formattedApiData, setFormattedApiData] = useState<CatchmentItemResult[]>([]);
  const [mapData, setMapData] = useState<GeoJSON.FeatureCollection>({
    type: 'FeatureCollection',
    features: [],
  });

  const [minZoom, setMinZoom] = useState<number | null>(null);
  const [resetEnabled, setResetEnabled] = useState<boolean>(false);
  const [resizeEnabled, setResizeEnabled] = useState<boolean>(true);
  const [infoPanel, setInfoPanel] = useState<string>();
  const [bounds, setBounds] = useState<Leaflet.LatLngBounds>(defaultBound);
  const [filter, setFilter] = useState<string>(props.filter);
  const setFilterDebounced = useRef(debounce(setFilter, 500));

  // Refs
  const geoJsonUnderlayRef = useRef<Leaflet.GeoJSON>(null);
  const geoJsonHeatmapRef = useRef<Leaflet.GeoJSON>(null);
  const layerIDs = useRef(new Map<string, Leaflet.Layer>());
  const hoveredLayer = useRef<Leaflet.Layer>();
  const selectedLayers = useRef<Leaflet.Layer[]>([]);
  const showHeatmap = useRef(true);

  // Hooks
  const mapRef = useMap();

  // Helpers
  function getIconPosition(location: string | undefined): Leaflet.LatLng {
    if (!geoData || !location) {
      return new Leaflet.LatLng(0, 0);
    }

    const feature = geoData.features.find((f) => f?.properties?.name === location);
    if (!feature) {
      return new Leaflet.LatLng(0, 0);
    }

    const gj = geoJSON(feature);
    const bounds = gj.getBounds();
    if (!bounds.isValid()) {
      return new Leaflet.LatLng(0, 0);
    }
    return bounds.getCenter();
  }

  const findBounds = (locations: string[]): Leaflet.LatLngBounds => {
    if (!geoData || locations.length === 0) {
      return defaultBound;
    }

    const features = geoData.features.filter(
      (f) => f.properties && locations.includes(f.properties.name)
    );
    if (features.length === 0) {
      return defaultBound;
    }

    const featureCollection: geojson.FeatureCollection = {
      type: 'FeatureCollection',
      features,
    };
    const gj = geoJSON(featureCollection);
    const bounds = gj.getBounds();
    if (!bounds.isValid()) {
      return defaultBound;
    }
    return bounds;
  };

  useMapEvent('zoomend', () => {
    // Enable/disable reset zoom
    setResetEnabled(mapRef.getZoom() !== minZoom);
  });

  function resetZoom() {
    // Reset and zoom to default bounds
    if (resetEnabled) {
      setBounds(defaultBound);

      mapRef.flyToBounds(defaultBound);
    }
  }

  const zoomToSelected = () => {
    // Set map boundaries from all selected layers
    if (props.selected.length > 0) {
      setBounds(findBounds(props.selected));
    } else if (filter !== '') {
      const filterFn = buildCatchmentFilter(filter);
      const layers = Arrays.choose(formattedApiData, (d) => {
        if (!filterFn(d)) {
          return undefined;
        }
        return layerIDs.current.get(d.origin);
      });
      const tempFeatureGroup = new Leaflet.FeatureGroup(layers);
      setBounds(tempFeatureGroup.getBounds());
    }
  };

  const onEachFeature = (
    feature: geojson.Feature<geojson.Geometry, geojson.GeoJsonProperties>,
    layer: Leaflet.Layer
  ) => {
    // Add ID to reference object
    if (feature?.properties?.name) {
      layerIDs.current.set(feature.properties.name, layer);
    }

    // Events
    layer.on({
      mouseover: (e: Leaflet.LeafletEvent) => {
        const layer = e.target;

        // Highlight item
        layer.setStyle(BORDER_HOVER_STYLE);

        if (!Leaflet.Browser.ie && !Leaflet.Browser.opera && !Leaflet.Browser.edge) {
          layer.bringToFront();
        }

        // Update info panel
        setInfoPanel(layer.feature.properties.name);
      },
      mouseout: (e: Leaflet.LeafletEvent) => {
        // Restore
        if (!selectedLayers.current.includes(e.target)) {
          e.target.setStyle(BORDER_OFF_STYLE);
        } else {
          e.target.setStyle(BORDER_SELECTED_STYLE);
        }

        // Remove info panel
        setInfoPanel(undefined);
      },
      click: (e: Leaflet.LeafletEvent) => {
        // Add/remove from selectedLayers
        props.setSelected(e.target.feature.properties.name);
      },
    });
  };

  const toggleHeatmap = () => {
    if (!geoJsonUnderlayRef.current || !geoJsonHeatmapRef.current) {
      return;
    }
    const underlayLeaflet = geoJsonUnderlayRef.current;
    const heatmapLeaflet = geoJsonHeatmapRef.current;
    // Update heatmap
    showHeatmap.current = !showHeatmap.current;
    underlayLeaflet.setStyle(showHeatmap.current ? buildUnderlayStyles : buildClearStyles);
    heatmapLeaflet.setStyle(showHeatmap.current ? buildHeatmapStyles : buildClearStyles);
    // Update local state (for icon - find a better way to do this?)
    props.setHeatMapOn(showHeatmap.current);
    // Restore selected outlines
    if (selectedLayers.current.length) {
      selectedLayers.current.forEach((l) => (l as Leaflet.Path).setStyle(BORDER_SELECTED_STYLE));
    }
  };

  useEffect(() => {
    if (props.viewStatus === 'mini') {
      setResizeEnabled(false);
    }
  }, [props.viewStatus]);

  useEffect(() => {
    mapRef.invalidateSize();
  }, [mapRef, props.resize]);

  useEffect(() => {
    // Set minimum zoomn to current (auto-zoomed based on defaultBound)
    const tempMinZoom = mapRef.getZoom();
    mapRef.setMinZoom(tempMinZoom);

    // Update local state (so we can enable/disable reset zoom)
    setMinZoom(tempMinZoom);
  }, [mapRef]);

  useEffect(() => {
    setFilterDebounced.current(props.filter);
  }, [props.filter]);

  useEffect(() => {
    mapRef.flyToBounds(bounds, FLY_OPTIONS);
  }, [mapRef, bounds]);

  // Fetch GeoJSON data
  useEffect(() => {
    fetch(getGeoJsonPath(props.aggregationLevel))
      .then((response) => response.json())
      .then((data) => setGeoData(data));
  }, [props.aggregationLevel]);

  // Format API data
  useEffect(
    () =>
      setFormattedApiData(
        formatCatchmentGridResults(props.apiData.results, '', {
          direction: 'desc',
          field: 'num_visits',
        })
      ),
    [props.aggregationLevel, props.apiData.results]
  );

  // Merge Geo and Formatted data
  useEffect(() => {
    if (!geoData) {
      return;
    }

    setMapData(mergeCatchmentMapData(geoData, formattedApiData, filter, props.heatmapField));
  }, [geoData, formattedApiData, filter, props.heatmapField]);

  // Re-render map
  useEffect(() => {
    if (!geoJsonUnderlayRef.current || !geoJsonHeatmapRef.current) {
      return;
    }

    const underlayLeaflet = geoJsonUnderlayRef.current;
    const heatmapLeaflet = geoJsonHeatmapRef.current;

    // Clear ID's pbject
    layerIDs.current.clear();

    // Set new underlay data and styles
    underlayLeaflet.clearLayers().addData(mapData);

    underlayLeaflet.setStyle(showHeatmap.current ? buildUnderlayStyles : buildClearStyles);

    // Set new heatmap data and styles
    heatmapLeaflet.off().clearLayers().addData(mapData);

    heatmapLeaflet.setStyle(showHeatmap.current ? buildHeatmapStyles : buildClearStyles);
  }, [mapData]);

  // Set selected
  useEffect(() => {
    // Retrieve selected layers
    const newSelectedLayers: Leaflet.Layer[] = props.selected
      .map((s) => layerIDs.current.get(s) as Leaflet.Layer)
      .filter((l) => l !== undefined);

    // Clear any old selected highlights
    const oldSelectedLayers = selectedLayers.current.filter((l) => !newSelectedLayers.includes(l));

    oldSelectedLayers.forEach((l) => (l as Leaflet.Path).setStyle(BORDER_OFF_STYLE));

    // Default if no new selected
    if (!newSelectedLayers.length) {
      // Clear selected layers
      selectedLayers.current = [];

      // Revert to default zoom
      setBounds(defaultBound);
    } else {
      // Highlight everything new
      newSelectedLayers.forEach((l) => (l as Leaflet.Path).setStyle(BORDER_SELECTED_STYLE));

      // Update selected layers
      selectedLayers.current = newSelectedLayers;
    }
  }, [props.selected]);

  // Set hovered outline
  useEffect(() => {
    if (hoveredLayer.current === props.hovered) {
      return;
    }

    // Remove existing hover style
    const currentHoveredLayer = hoveredLayer.current;

    if (currentHoveredLayer && currentHoveredLayer instanceof Leaflet.Path) {
      if (!selectedLayers.current.includes(currentHoveredLayer)) {
        currentHoveredLayer.setStyle(BORDER_OFF_STYLE);
      } else {
        (currentHoveredLayer as Leaflet.Path).setStyle(BORDER_SELECTED_STYLE);
      }

      hoveredLayer.current = undefined;
    }

    // Add new hover style
    if (props.hovered) {
      const layer = layerIDs.current.get(props.hovered);

      if (layer && layer instanceof Leaflet.Path) {
        layer.setStyle(BORDER_HOVER_STYLE);

        hoveredLayer.current = layer;
      }
    }
  }, [props.hovered]);

  const infoPanelData = infoPanel
    ? formattedApiData.find((r) => r.origin === infoPanel)
    : undefined;

  return (
    <>
      {/* Map layer */}
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      />
      {/* GeoJSON layer (and heatmap) */}
      <GeoJSON ref={geoJsonUnderlayRef} data={mapData} />
      <GeoJSON ref={geoJsonHeatmapRef} data={mapData} onEachFeature={onEachFeature} />
      {/* Reset zoom & toggle heatmap */}
      <div className="leaflet-top leaflet-right">
        <div className="leaflet-control leaflet-bar info-pane buttons">
          <div className="button" data-enabled={resizeEnabled} onClick={props.onMapSizeChange}>
            <Tooltip title={props.resize ? 'Increase size' : 'Decrease size'} placement="right">
              {props.resize ? (
                <SettingsEthernetIcon fontSize="inherit" />
              ) : (
                <CompareArrowsIcon fontSize="inherit" />
              )}
            </Tooltip>
          </div>
        </div>

        <div className="leaflet-control leaflet-bar info-pane buttons">
          {/* change size of map*/}
          <div className="button" data-enabled={resetEnabled} onClick={resetZoom}>
            <Tooltip title="Reset zoom" placement="right">
              <PublicIcon fontSize="inherit" />
            </Tooltip>
          </div>
          <div
            className="button"
            data-enabled={props.selected.length > 0 || filter !== ''}
            onClick={zoomToSelected}
          >
            <Tooltip title="Zoom to selected" placement="right">
              <FilterCenterFocusIcon fontSize="inherit" />
            </Tooltip>
          </div>
        </div>

        <div className="leaflet-control leaflet-bar info-pane buttons">
          <div className="button" data-enabled="true" onClick={toggleHeatmap}>
            <Tooltip title={props.heatmapOn ? 'Hide heatmap' : 'Show heatmap'} placement="right">
              {props.heatmapOn ? (
                <LayersIcon fontSize="inherit" />
              ) : (
                <LayersClearIcon fontSize="inherit" />
              )}
            </Tooltip>
          </div>
        </div>
      </div>
      {/* Info panel */}
      <div className="leaflet-bottom leaflet-left">
        {infoPanelData && (
          <div className="leaflet-control leaflet-bar info-pane">
            <h3>{infoPanelData.origin}</h3>
            <div className="row">
              <div className="left icon">
                <PeopleIcon fontSize="inherit" />
              </div>
              <p className="right data">{infoPanelData.num_visits}</p>
            </div>
          </div>
        )}
      </div>
      {/* Hover marker */}
      <Marker icon={markerIconHover} position={getIconPosition(props.hovered)} />
      {/* Selected markers */}
      {props.selected
        .map((selected) => {
          const position = getIconPosition(selected);
          return <Marker key={selected} icon={markerIconSelected} position={position} />;
        })
        .filter((el) => el !== undefined)}
    </>
  );
};
