import Link from '@material-ui/core/Link';
import { Bar } from '@nivo/bar';
import { Arrays } from 'collection-fns';
import React, { useEffect, useState } from 'react';
import { AutoSizer } from 'react-virtualized';
import CloseIcon from '@material-ui/icons/Close';
import PaperWithHeading from '../../Assets/Layout/PaperWithHeading';

import { capitaliseText } from '../../../lib/helpers';
import {
  Interest,
  InterestLevel,
  InterestsResult,
  InterestsResultData,
  InterestsResultV2,
  ProfilesFilter,
} from '../../../types/smartStepsApi';

import { o2colorsInterests, themeA } from '../../../styles/NivoTheme';
import InterestsStyles from './Interests.styles';
import IconButton from '@material-ui/core/IconButton';

// Types
type Props = {
  label: string;
  data?: InterestsResult;
  subInterests?: { [key in Interest]?: InterestsResultV2 };
  visitType: ProfilesFilter['visitType'] | undefined;
  isLoading: boolean;
  ppl: number;
  onClick: (interest: Interest) => void;
  valueToLabelMap: Record<string, string>;
  showCloseButton?: boolean;
  onClose?: Function;
};

type FormattedData = {
  field: string;
  h: number | undefined;
};

function keyLabelByKey(key: string): string {
  const parts = key.split('__');
  return (parts.length === 1 ? parts[0] : parts[1]).replace(/_/g, ' ');
}

const getValue = (o: InterestsResultData | undefined, key: InterestLevel): number => o?.[key] ?? 0;
// Helpers
const formatData = (
  data: InterestsResult,
  valueToLabelMap: Record<string, string>
): FormattedData[] => {
  const entries = Object.entries(data);
  const sorted = Arrays.sortBy(entries, ([key]) => key);

  return sorted.map(([key, value]) => ({
    field: valueToLabelMap[key] || keyLabelByKey(key),
    h: getValue(value, 'h') + getValue(value, 'm') + getValue(value, 'l'),
    key,
  }));
};

// Component
const Interests: React.FC<Props> = (props) => {
  const ppl = props.ppl;

  // TODO: Refactor?
  const dataAtoZ =
    (props.data &&
      Object.values(formatData(props.data, props.valueToLabelMap))
        .filter((interest) => interest.h !== undefined)
        .sort(function (a, b) {
          const nameA = a.field.toUpperCase(); // ignore upper and lowercase
          const nameB = b.field.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }

          // names must be equal
          return 0;
        })) ||
    [];

  const dataHighToLow =
    (props.data &&
      Object.values(formatData(props.data, props.valueToLabelMap))
        .filter((interest) => !!interest.h)
        .sort(function (a, b) {
          if (a.h && b.h) {
            return a.h - b.h;
          }
          return 1;
        })) ||
    [];

  // Local States
  const [orderBy, setOrderBy] = useState<'a-z' | 'high-low'>('high-low');
  const [containerHeight, setContainerHeight] = useState(0);
  const [valueRotation, setValueRotation] = useState(0);

  // Effects
  useEffect(() => {
    if (window.innerHeight) {
      setContainerHeight(window.innerHeight - window.innerHeight * 0.19);
    }
  }, [containerHeight]);

  // rotate ticks labels if window width is too narrow
  useEffect(() => {
    if (window.innerWidth > 1100 && window.innerWidth < 1400) {
      setValueRotation(-45);
    }
  }, [containerHeight]);

  //const sizeFont = themeA?.axis?.ticks?.text?.fontSize;

  // Render
  return (
    <InterestsStyles>
      <PaperWithHeading label={props.label} isLoading={props.isLoading}>
        {props.showCloseButton && (
          <IconButton
            size="small"
            color="inherit"
            className="close-button"
            onClick={props.onClose as any}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
        <div className="container">
          <div className="filter">
            <Link
              component="button"
              disabled={orderBy === 'high-low'}
              className={orderBy === 'high-low' ? 'active' : undefined}
              underline="none"
              onClick={() => setOrderBy('high-low')}
            >
              High-Low
            </Link>

            <Link
              component="button"
              disabled={orderBy === 'a-z'}
              className={orderBy === 'a-z' ? 'active' : undefined}
              underline="none"
              onClick={() => setOrderBy('a-z')}
            >
              A-Z
            </Link>
          </div>

          <div className="chart" style={{ height: containerHeight }}>
            <AutoSizer>
              {({ height, width }) => (
                <Bar
                  height={height}
                  width={width}
                  data={orderBy === 'high-low' ? dataHighToLow : dataAtoZ}
                  keys={['h']}
                  theme={themeA}
                  indexBy={'field'}
                  margin={{ top: 40, right: 20, bottom: 55, left: 170 }}
                  padding={0.24}
                  layout="horizontal"
                  colors={(d) => {
                    const dataTotal =
                      orderBy === 'high-low' ? dataHighToLow.length : dataAtoZ.length;
                    const single = o2colorsInterests.length / dataTotal;
                    const index = Math.round(single * d.index);

                    return o2colorsInterests[
                      index === o2colorsInterests.length ? index - 1 : index
                    ];
                  }}
                  enableGridX={true}
                  enableGridY={false}
                  axisTop={{
                    tickSize: 0,
                    tickPadding: 8,
                    tickRotation: valueRotation,
                    format: '~s',
                  }}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 0,
                    tickPadding: 8,
                    tickRotation: valueRotation,
                    legend: props.visitType ? `${capitaliseText(props.visitType)}s` : 'People',
                    legendPosition: 'middle',
                    legendOffset: 45,
                    format: '~s',
                  }}
                  axisLeft={{
                    tickSize: 2,
                    tickPadding: 8,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: -50,
                  }}
                  enableLabel={false}
                  tooltip={(data) => (
                    <div className="tooltip-container">
                      <h5>{data.indexValue}</h5>
                      <p className="people">{data.value.toLocaleString()}</p>
                      <p className="people-label">
                        {props.visitType ? `${capitaliseText(props.visitType)}s` : '-'}
                      </p>
                      <p className="percent">{((data.value / ppl) * 100).toFixed(1)} %</p>
                      {props.showCloseButton ||
                      Object.keys(props.subInterests![data.data.key as Interest]! || {}).length ? (
                        ''
                      ) : (
                        <p>No sub interests available</p>
                      )}
                    </div>
                  )}
                  onMouseEnter={(data, event) => {
                    const hasSubCategories = Object.keys(
                      props.subInterests![data.data.key as Interest]! || {}
                    ).length;
                    (event.target as SVGRectElement).style.cursor = hasSubCategories
                      ? 'pointer'
                      : 'default';
                  }}
                  onMouseLeave={(data, event) => {
                    (event.target as SVGRectElement).style.cursor = 'default';
                  }}
                  onClick={(data) => {
                    const hasSubCategories = Object.keys(
                      props.subInterests![data.data.key as Interest] || {}
                    ).length;
                    if (hasSubCategories) {
                      props.onClick(data.data.key as Interest);
                    }
                  }}
                  animate={true}
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      </PaperWithHeading>
    </InterestsStyles>
  );
};

export default Interests;
