import React, { useState, useRef, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TableCellProps as MaterialTableCellProps } from '@material-ui/core/TableCell';
import useTheme from '@material-ui/core/styles/useTheme';
import GetApp from '@material-ui/icons/GetApp';
import Room from '@material-ui/icons/Room';
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
  RowMouseEventHandlerParams,
  TableCellProps,
} from 'react-virtualized';

import {
  formatGridResults,
  GridResult,
  AggregationLevel,
  GridSort,
  numberToLocale,
  numberToPercent,
  numberToDecimal,
  DataColumn,
} from '../../../lib/gridResults';

import { ApiAudienceDataState } from '../../../types/apiData';
import { RefinementState } from '../../../types/refinement';

import DataGridStyles from './DataGrid.styles';
import { PostalPermission } from '../../../lib/postal';

// Types
interface Props {
  apiData: ApiAudienceDataState;
  refinement: RefinementState;
  setHovered: (hovered: string | undefined) => void;
  selected: string[];
  toggleSelected: (selected: string) => void;
  clearSelected: () => void;
  lastSelected?: string;
  resize: boolean;
  postalPermission: PostalPermission[];
}

interface ColumnDef {
  dataKey: DataColumn;
  label: string | React.ReactElement;
  align?: MaterialTableCellProps['align'];
  formatter?: (value: number) => string;
  width: number;
}

interface ColumnWidths {
  name: number;
  description: number;
  count: number;
  rp: number;
  ri: number;
  marker: number;
}

// Component
const DataGrid = ({
  apiData,
  refinement,
  setHovered,
  selected,
  toggleSelected,
  clearSelected,
  lastSelected,
  resize,
  postalPermission,
}: Props) => {
  const { loading, results } = apiData;
  const { aggregationLevel, filter } = refinement;

  // Local state
  const [componentWidth, setComponentWidth] = useState<number>(0);
  const [columnWidths, setColumnWidths] = useState<ColumnWidths>({
    name: 0,
    description: 0,
    count: 0,
    rp: 0,
    ri: 0,
    marker: 0,
  });
  const [sorting, setSorting] = useState<GridSort>({
    field: 'count',
    direction: 'desc',
  });
  const [formattedResults, setFormattedResults] = useState<GridResult[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const mediaSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Constants
  const rowHeight = 48;

  const schemaType = aggregationLevel > AggregationLevel.NUTS3 ? 'POSTCODE' : 'NUTS';

  const columns: ColumnDef[] = [
    {
      dataKey: 'name',
      label:
        schemaType === 'POSTCODE'
          ? `Postal ${AggregationLevel[aggregationLevel]}`
          : `${AggregationLevel[aggregationLevel]} Area`,
      width: columnWidths.name,
    },
    {
      dataKey: 'description',
      label: 'Description',
      width: columnWidths.description,
    },
    {
      dataKey: 'count',
      label: 'People',
      align: 'right',
      formatter: numberToLocale,
      width: columnWidths.count,
    },
    {
      dataKey: 'rp',
      label: 'R.P',
      align: 'right',
      formatter: numberToPercent,
      width: columnWidths.rp,
    },
    {
      dataKey: 'ri',
      label: 'R.I',
      align: 'right',
      formatter: numberToDecimal,
      width: columnWidths.ri,
    },
    {
      dataKey: 'selected',
      label: <Room fontSize="small" />,
      align: 'right',
      width: columnWidths.marker,
    },
  ];

  // Helpers
  const changeSort = (dataKey: DataColumn) => {
    const isDesc = sorting.field === dataKey && sorting.direction === 'desc';

    setSorting({
      field: dataKey,
      direction: isDesc ? 'asc' : 'desc',
    });
  };

  const onRowClick = (info: RowMouseEventHandlerParams) => {
    toggleSelected(info.rowData.name);
  };

  const handleExport = () => {
    let csvContent = 'Name,Description,Count,RP,RI \r\n';
    formattedResults.forEach((r) => {
      csvContent += `${r.name},${r.description.replaceAll(',', '')},${r.count},${r.rp},${r.ri}\r\n`;
    });

    const a = document.createElement('a');
    a.download = `audienceinsights-audience-${Date.now()}.csv`;
    a.href = window.URL.createObjectURL(new Blob([csvContent], { type: 'text/csv' }));
    a.dispatchEvent(new MouseEvent('click'));
  };

  const getContainerWidth = () => (containerRef.current ? containerRef.current.offsetWidth : 0);

  // Effects
  useEffect(() => {
    // Set initial size
    // HACK: Delay calculating initial size ... it's a bit too small otherwise.
    setTimeout(() => setComponentWidth(getContainerWidth()), 0);

    // Update on resize
    window.addEventListener('resize', () => {
      setComponentWidth(getContainerWidth());
    });

    // Cleanup
    return () => {
      window.removeEventListener('resize', () => {
        setComponentWidth(getContainerWidth());
      });
    };
  }, [resize]);

  useEffect(() => {
    const widths: ColumnWidths =
      !resize || mediaSmall
        ? {
            name: componentWidth * 0.24,
            description: 0,
            count: componentWidth * 0.22,
            rp: componentWidth * 0.19,
            ri: componentWidth * 0.19,
            marker: componentWidth * 0.16,
          }
        : {
            name: componentWidth * 0.19,
            description: componentWidth * 0.32,
            count: componentWidth * 0.13,
            rp: componentWidth * 0.13,
            ri: componentWidth * 0.13,
            marker: componentWidth * 0.1,
          };

    setColumnWidths(widths);
  }, [componentWidth, mediaSmall, resize]);

  useEffect(() => {
    // Re-format the results
    setFormattedResults(
      formatGridResults(results, aggregationLevel, filter, sorting, postalPermission, selected)
    );
  }, [results, aggregationLevel, filter, sorting, selected]);

  useEffect(() => {
    const index = formattedResults.findIndex((r) => r.name === lastSelected);

    setSelectedIndex(index);
  }, [lastSelected, formattedResults]);

  // Renders
  // eslint-disable-next-line react/prop-types
  const renderCell: TableCellRenderer = ({ cellData, rowData, dataKey }: TableCellProps) => {
    // Find column
    const column = columns.find((c) => c.dataKey === dataKey);

    if (!column) {
      return null;
    }

    const { align, formatter } = column;

    // Set selected
    const isSelected = selected.indexOf(rowData.name) !== -1;

    // Build classes
    let classes = 'cell';

    if (isSelected) {
      classes += ' selected';
    }

    // Format value
    let value = cellData;

    if (value) {
      // Format text
      if (formatter !== undefined) {
        value = formatter(value);
      }

      // Truncate long text
      if (value.length > 57) {
        value = <abbr title={value}>{value.substring(0, 57)}...</abbr>;
      }
    } else if (dataKey === 'selected') {
      // TO DO
      // Find a better way of targeting this fie
      value = <Room fontSize="small" className="marker" />;
    }

    // Render
    return (
      <TableCell component="div" variant="body" className={classes} align={align ? align : 'left'}>
        {value}
      </TableCell>
    );
  };

  const renderHeader = ({ dataKey, label }: TableHeaderProps) => {
    // Find column
    const column = columns.find((c) => c.dataKey === dataKey);

    if (!column) {
      return null;
    }

    // Set sorting
    const isSortedColumn = sorting.field === dataKey;

    // Render
    return (
      <TableCell
        component="div"
        variant="head"
        className="cell"
        align={column.align ? column.align : 'left'}
        sortDirection={isSortedColumn ? sorting.direction : false}
      >
        <TableSortLabel
          active={isSortedColumn}
          direction={sorting.direction}
          onClick={() => changeSort(column.dataKey)}
        >
          {label}
          {isSortedColumn ? (
            <span className="sortHidden">
              {sorting.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  };

  const onMouseOverHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const el = e.target as HTMLElement;
    const row = el.closest('.row');
    const postcode = row?.firstElementChild?.textContent;
    const role = row?.firstElementChild?.getAttribute('role');

    if (postcode && role !== 'columnheader') {
      setHovered(postcode);
    } else {
      setHovered(undefined);
    }
  };

  return (
    <DataGridStyles>
      {loading && <LinearProgress variant="indeterminate" color="secondary" className="loader" />}

      <div className="fixed-grid">
        <div className="scroll-grid">
          <div
            ref={containerRef}
            className="outer-grid"
            onMouseOver={onMouseOverHandler}
            onMouseLeave={() => setHovered(undefined)}
          >
            <AutoSizer>
              {({ height, width }) => (
                <Table
                  height={height}
                  width={width}
                  columns={columns}
                  gridClassName="inner-grid"
                  headerClassName="header"
                  headerHeight={rowHeight}
                  rowCount={formattedResults.length}
                  rowGetter={({ index }) => formattedResults[index]}
                  rowHeight={rowHeight}
                  rowClassName="row"
                  scrollToIndex={selectedIndex}
                  onRowClick={onRowClick}
                >
                  {columns.map(({ dataKey, ...other }) => {
                    // Exclude description on <= SM
                    if ((mediaSmall || !resize) && dataKey === 'description') {
                      return null;
                    }

                    return (
                      <Column
                        key={dataKey}
                        headerRenderer={(headerProps) => renderHeader(headerProps)}
                        className="column"
                        cellRenderer={renderCell}
                        dataKey={dataKey}
                        {...other}
                      />
                    );
                  })}
                </Table>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>

      <div className="summary">
        <div className="selected">
          <Typography variant="caption" display="inline" color="textSecondary">
            {selected.length} selected
          </Typography>
          <Button
            size="small"
            disabled={!selected.length}
            className="clear-button"
            onClick={clearSelected}
          >
            Clear
          </Button>
        </div>
        <div className="download">
          <Typography variant="caption" display="inline" color="textSecondary" className="total">
            Rows: {Number(formattedResults.length).toLocaleString()}
          </Typography>
          <IconButton
            color="secondary"
            onClick={handleExport}
            disabled={loading || !formattedResults.length}
          >
            <GetApp />
          </IconButton>
        </div>
      </div>
    </DataGridStyles>
  );
};

export default DataGrid;
