import React, { useContext } from 'react';
import Link from '@material-ui/core/Link';

import { AuthContext } from './AuthProvider';

import { signOut } from '../../lib/authAzure';

import LogoutStyles from './Logout.styles';

const Logout = () => {
  // Global State
  const { state: authState, dispatch } = useContext(AuthContext);

  // Handlers
  const signOutHandler = async () => {
    await signOut();

    dispatch({ type: 'SET_AUTH', payload: { isAuthenticated: false } });
  };

  // Render
  return (
    <LogoutStyles className="logout-container">
      {authState && authState.isAuthenticated && (
        <>
          <p>
            Signed in as <strong>{authState.username}</strong>
          </p>
          <Link component="button" variant="caption" display="inline" onClick={signOutHandler}>
            Sign out
          </Link>
        </>
      )}
      <div className="link-container">
        <div className="link">
          <Link color="inherit" href="https://www.o2.co.uk/termsandconditions" target="_blank">
            Terms &amp; Conditions
          </Link>
        </div>
        <div className="link">
          <Link
            color="inherit"
            href="https://www.o2.co.uk/termsandconditions/privacy-policy"
            target="_blank"
          >
            Privacy
          </Link>
        </div>
        <div className="link">
          <Link color="inherit" href="mailto:support-ai@o2.com" target="_blank">
            Contact Us
          </Link>
        </div>
      </div>
    </LogoutStyles>
  );
};

export default Logout;
