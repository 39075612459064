import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

import { ButtonProps } from '../../../types/global';

import ButtonStyles from './Button.styles';

const O2Button = (props: ButtonProps) => (
  <ButtonStyles {...props}>
    <Button type="submit">
      <span className="text">{props.text}</span>
      <span className="arrow">
        <ArrowForwardIos />
      </span>
    </Button>
  </ButtonStyles>
);

export default O2Button;
