import styled from 'styled-components';

const Drawer = styled.div`
  width: 50vw;
  padding: 3rem;
  overflow: scroll;

  > svg {
    font-size: 3.5rem;
  }
  > h3 {
    font-size: 3.2rem;
    margin: 2rem 0;
    line-height: 5rem;
  }
  > p {
    font-size: 2.2rem;
    color: #484848;
    line-height: 140%;
  }
  > ul {
    > li {
      font-size: 1.8rem;
      color: #484848;
    }
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 65vw;
  }
  ${(props) => props.theme.breakpoints.down('xs')} {
    width: 100vw;
    > h3 {
      font-size: 2.5rem;
    }
    > p {
      font-size: 1.8rem;
    }
    > ul {
      > li {
        font-size: 1.5rem;
      }
    }
  }
`;

export default Drawer;
