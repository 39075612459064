import styled from 'styled-components';

const Sentence = styled.div`
  position: relative;

  > form {
    display: flex;
    flex-wrap: wrap;

    > div {
      display: flex;

      > * {
        flex: 0 0 auto;
      }

      .text {
        margin-top: 0.4rem;
      }

      .field {
        font-size: 2.2rem;
        font-weight: 600;

        .MuiInput-input {
          font-size: 2.2rem;
          max-width: 20rem;
          font-weight: 600;

          &:not(.Mui-disabled) {
            color: ${(props) => props.theme.palette.secondary.main};
          }

          &.Mui-disabled {
            color: ${(props) => props.theme.palette.text.main};
          }
        }
      }

      ${(props) => props.theme.breakpoints.down('xs')} {
        .text,
        .field {
          font-size: 1.8rem;
          .MuiInput-input {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
  .demo-user-note {
    position: absolute;
    font-size: 1.2rem;
    top: -15px;
    color: ${(props) => props.theme.palette.warning.main};
  }
`;

export default Sentence;
