import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export const StyledPaper = styled(Paper)`
  height: 100%;
  padding: 3rem;
`;

export const StyledFormControl = styled(FormControl)`
  margin: 0 1rem;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 0.8rem;
`;

export const StyledDialogContent = styled(DialogContent)`
  min-height: 300px;
  &:first-child {
    padding-top: 3rem;
  }
  padding: 3rem 3rem 2.2rem;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 0;
  &.gutter-top {
    margin-top: 2rem;
  }
  &.gutter-bottom {
    margin-bottom: 1rem;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
  padding: 0 2.2rem 2.2rem;
  > .groupedButtons {
    * + * {
      margin-left: 0.8rem;
    }
  }
`;
