import Paper from '@material-ui/core/Paper';
import React, { useEffect, useRef, useState } from 'react';

import {
  AGE_BANDS,
  GENDERS,
  INTERESTS,
  QUERYS,
  SPENDING_POWERS,
  VISIT_TYPES,
} from '../../../constants/filter';
import {
  AgeBand,
  AudienceFilter as AudienceFilterState,
  Gender,
  Interest,
  QueryInterest,
  SpendingPower,
  VisitType,
} from '../../../types/smartStepsApi';

import MultiSelect from '../../Assets/Form/MultiSelect';
import SingleSelect from '../../Assets/Form/SingleSelect';
import ToggleButtons from '../../Assets/Form/ToggleButtons';
import Info from '../../Assets/Images/InfoIcon';
import { AudienceHelp, HelpView } from './Help';

import { filterSubInterests, getAvailableSubInterests } from '../../../lib/filter';
import { StyledPaper } from '../../../styles/MaterialComponents';
import { SelectOption } from '../../../types/audienceFilter';
import AudienceFieldStyles, { AudienceField } from './Fields.styles';

// Types
interface Props {
  audienceFilter: AudienceFilterState;
  includeHelp: boolean;
  onChange: (audienceFilter: AudienceFilterState) => void;
  toggleDrawer?: (open: boolean, event?: React.KeyboardEvent | React.MouseEvent) => void;
  helpView?: HelpView | undefined;
  setHelpView?: (helpView: HelpView | undefined) => void;
}

export const AudienceFields: React.FC<Props> = ({
  audienceFilter,
  includeHelp,
  onChange,
  toggleDrawer,
  helpView,
  setHelpView,
}: Props) => {
  // Local State
  const [visitType, setVisitType] = useState<VisitType>(audienceFilter.visitType);
  const [gender, setGender] = useState<Gender>(audienceFilter.gender);
  const [ageBand, setAgeBand] = useState<AgeBand[]>(audienceFilter.ageBand);
  const [spendingPower, setSpendingPower] = useState<SpendingPower[]>(audienceFilter.spendingPower);
  const [queryInterest, setQueryInterest] = useState<QueryInterest>(audienceFilter.queryInterest);
  const [interests, setInterests] = useState<Interest[]>(audienceFilter.interests);
  const [subInterests, setSubInterests] = useState<string[]>(audienceFilter.subInterests);

  const [availableSubInterest, setAvailableSubInterest] = useState<SelectOption<string>[]>([]);
  // Refs
  const visitTypeRef = useRef<HTMLDivElement>(null);
  const genderRef = useRef<HTMLDivElement>(null);
  const ageBandRef = useRef<HTMLDivElement>(null);
  const spendingPowerRef = useRef<HTMLDivElement>(null);
  const interestRef = useRef<HTMLDivElement>(null);

  // Effects
  useEffect(() => {
    setVisitType(audienceFilter.visitType);
    setGender(audienceFilter.gender);
    setAgeBand(audienceFilter.ageBand);
    setSpendingPower(audienceFilter.spendingPower);
    setInterests(audienceFilter.interests);
    const availableSubInterests = getAvailableSubInterests(audienceFilter.interests);
    setSubInterests(filterSubInterests(audienceFilter.subInterests, availableSubInterests));

    setQueryInterest(audienceFilter.queryInterest);

    setAvailableSubInterest(availableSubInterests);
  }, [audienceFilter]);

  useEffect(() => {
    onChange({
      visitType,
      gender,
      ageBand,
      spendingPower,
      queryInterest,
      interests,
      subInterests,
    });
  }, [visitType, gender, ageBand, spendingPower, queryInterest, interests, subInterests, onChange]);

  useEffect(() => {
    // Refs
    const visitTypeRefLocal = visitTypeRef.current;
    const genderRefLocal = genderRef.current;
    const ageBandRefLocal = ageBandRef.current;
    const spendingPowerRefLocal = spendingPowerRef.current;
    const interestRefLocal = interestRef.current;

    // Helpers
    const addListeners = (ref: HTMLDivElement | null, helpView: HelpView) => {
      if (ref && setHelpView) {
        ref.addEventListener('mouseenter', () => setHelpView(helpView));
        ref.addEventListener('focusin', () => setHelpView(helpView));
      }
    };

    const removeListeners = (ref: HTMLDivElement | null, helpView: HelpView) => {
      if (ref && setHelpView) {
        ref.removeEventListener('mouseenter', () => setHelpView(helpView));
        ref.removeEventListener('focusin', () => setHelpView(helpView));
      }
    };

    // Add help listeners if required
    if (includeHelp) {
      addListeners(visitTypeRefLocal, 'visitType');
      addListeners(genderRefLocal, 'gender');
      addListeners(ageBandRefLocal, 'ageBand');
      addListeners(spendingPowerRefLocal, 'spendingPower');
      addListeners(interestRefLocal, 'interest');
      addListeners(interestRefLocal, 'subInterest');
    }

    // Cleanup to remove help listeners (if required)
    return () => {
      if (includeHelp) {
        removeListeners(visitTypeRefLocal, 'visitType');
        removeListeners(genderRefLocal, 'gender');
        removeListeners(ageBandRefLocal, 'ageBand');
        removeListeners(spendingPowerRefLocal, 'spendingPower');
        removeListeners(interestRefLocal, 'interest');
        removeListeners(interestRefLocal, 'subInterest');
      }
    };
  }, [includeHelp, setHelpView]);

  // Render
  const filter = (
    <>
      <AudienceField focused={helpView === 'visitType'} ref={visitTypeRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}
        <SingleSelect
          label="Visitor Type"
          data={VISIT_TYPES}
          selected={visitType}
          onChange={(value) => setVisitType(value)}
        />
      </AudienceField>

      <AudienceField focused={helpView === 'gender'} ref={genderRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}
        <SingleSelect
          label="Gender"
          data={GENDERS}
          selected={gender}
          onChange={(value) => setGender(value)}
        />
      </AudienceField>

      <AudienceField focused={helpView === 'ageBand'} ref={ageBandRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}

        <ToggleButtons
          label="Age Bands"
          data={AGE_BANDS}
          selected={ageBand}
          onClick={(ageBand) => setAgeBand(ageBand)}
        />
      </AudienceField>

      <AudienceField focused={helpView === 'spendingPower'} ref={spendingPowerRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}

        <ToggleButtons
          label="Spending Power"
          data={SPENDING_POWERS}
          selected={spendingPower}
          onClick={(spendingPower) => setSpendingPower(spendingPower)}
        />
      </AudienceField>

      <AudienceField focused={helpView === 'interest'} ref={interestRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}

        <SingleSelect
          label="Interests"
          data={QUERYS}
          selected={queryInterest}
          onChange={(value) => setQueryInterest(value)}
        />

        <MultiSelect
          //label="Interests"
          label=""
          placeholder="Please select all that apply"
          data={INTERESTS}
          selected={interests}
          onChange={(interests) => setInterests(interests)}
          position="top"
        />
        <MultiSelect
          //label="Interests"
          label=""
          placeholder="Please select sub category"
          data={availableSubInterest}
          selected={subInterests}
          onChange={(subInterest) => setSubInterests(subInterest)}
          position="top"
        />
      </AudienceField>
    </>
  );

  return (
    <AudienceFieldStyles includeHelp={includeHelp}>
      <div className="outerContainer">
        <div className="filterContainer">
          {includeHelp && <Paper className="filterPaper">{filter}</Paper>}
          {!includeHelp && filter}
        </div>
        {includeHelp ? (
          <div className="helpContainer">
            <StyledPaper>
              <AudienceHelp display={helpView} />
            </StyledPaper>
          </div>
        ) : null}
      </div>
    </AudienceFieldStyles>
  );
};
