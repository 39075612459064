import { Arrays } from 'collection-fns';
import { buildCatchmentFilter, mergeDuplicateResults } from '.';
import { CatchmentGridSort, CatchmentItemResult } from '../../types/Catchment';

export function formatCatchmentGridResults(
  results: CatchmentItemResult[],
  filter: string,
  sorting: CatchmentGridSort,
  selected?: string[]
): CatchmentItemResult[] {
  const refinedData = results ? mergeDuplicateResults(results) : [];

  const filterFn = buildCatchmentFilter(filter);
  const filteredData = refinedData.filter(filterFn);
  const sort = sorting.direction === 'asc' ? Arrays.sortBy : Arrays.sortByDescending;

  return sort(filteredData, (row) => {
    if (sorting.field === 'selected') {
      return selected?.includes(row.origin) ? 1 : 0;
    } else {
      return row[sorting.field];
    }
  });
}
