/* eslint-disable @typescript-eslint/no-empty-function */

import fetch, { Response } from 'node-fetch';
import format from 'date-fns/format';

import { calculateTimeTo } from './filter';

import {
  AudienceQuery,
  TimeOfDay,
  TimeInterval,
  Gender,
  AgeBand,
  SpendingPower,
  AudienceResult,
  VisitType,
  AudienceTreeResult,
  Interest,
  InterestLevel,
  ProfilesQuery,
  ProfilesResult,
  ProfilesTreeResult,
  CatchmentFilter,
} from '../types/smartStepsApi';
import { getJwtToken } from './authAzure';
import { SUB_INTERESTS } from '../constants/filter';
import { CatchmentResult, CatchmentSearchResult, CatchmentTreeResult } from '../types/Catchment';

/*
  ----- TYPES -----
*/

export interface ApiDependencies {
  log?: (message: string) => void;
  fetch?: typeof fetch;
  baseUrl: URL;
}

type FetchArgs = Parameters<typeof fetch>;

interface DateIntervalPayload {
  start_date: string;
  end_date: string;
}

type IntervalPayload = DateIntervalPayload & TimeInterval;

type NonEmptyArray<T> = [T, ...T[]];
// type Interests = { [key in Interest]?: NonEmptyArray<InterestLevel> };
type InterestsV2 = { [key: string]: NonEmptyArray<InterestLevel> };

// Audience
interface AudienceQueryPayloadFilters {
  gender?: NonEmptyArray<'m' | 'f'>;
  age_band?: NonEmptyArray<AgeBand>;
  spending_power?: NonEmptyArray<SpendingPower>;
  //interests?: Interests;
  interests?: any;
}

interface AudienceQueryPayload {
  interval: IntervalPayload;
  visit_type: VisitType;
  filters?: AudienceQueryPayloadFilters;
}

interface AudienceFlatResult {
  [name: string]: {
    count: number;
    rp: number;
  };
}

interface AudienceQueryTreeParams {
  format_output: 'tree';
}

interface AudienceQueryFlatParams {
  format_output: 'flat';
}

type AudienceQueryParams = AudienceQueryTreeParams | AudienceQueryFlatParams;

interface AudienceTreeResultPayload {
  count: number;
  search: AudienceQueryTreeParams & AudienceQueryPayload;
  results: AudienceTreeResult;
}

interface AudienceFlatResultPayload {
  count: number;
  search: AudienceQueryFlatParams & AudienceQueryPayload;
  results: AudienceFlatResult;
}

interface CatchmentQueryPayload {
  day: string;
  locations: string[];
}

interface CatchmentResultPayload {
  search: CatchmentSearchResult;
  results: CatchmentTreeResult;
}

// Profiles
type ProfilesQueryParams = {
  results_by: 'all';
  geo_input: 'postal_sector';
};

interface ProfilesQueryPayloadFilters {
  gender?: NonEmptyArray<'m' | 'f'>;
  age_band?: NonEmptyArray<AgeBand>;
  interests?: any;
}

interface ProfilesQueryPayload {
  locations: string[];
  interval: IntervalPayload;
  visit_type: VisitType;
  filters?: ProfilesQueryPayloadFilters;
}

interface ProfilesResultPayload {
  count: number;
  search: ProfilesQueryPayload;
  results: ProfilesTreeResult;
}

/*
  ----- FILTER -----
*/

function isNonEmptyArray<T>(input?: T[]): input is NonEmptyArray<T> {
  return input !== undefined && input.length > 0;
}

const makeGenderFilter = (gender: Gender): Pick<AudienceQueryPayloadFilters, 'gender'> =>
  gender === '*' ? {} : { gender: [gender] };

const makeAgeBandFilter = (ageBand?: AgeBand[]): Pick<AudienceQueryPayloadFilters, 'age_band'> =>
  isNonEmptyArray(ageBand) ? { age_band: ageBand } : {};

const makeSpendingPowerFilter = (
  spendingPower?: SpendingPower[]
): Pick<AudienceQueryPayloadFilters, 'spending_power'> =>
  isNonEmptyArray(spendingPower) ? { spending_power: spendingPower } : {};

const makeInterestsFilter = (
  interests: Interest[],
  subInterests: string[]
): Pick<AudienceQueryPayloadFilters, 'interests'> => {
  if (!isNonEmptyArray(interests)) {
    return {};
  }

  const filter: InterestsV2 = {};
  const filteredInterests = interests.filter(
    (i) => !SUB_INTERESTS.get(i)!.some((s) => subInterests.includes(s.value))
  );
  for (const interest of filteredInterests) {
    filter[interest] = ['h'];
  }
  for (const interest of subInterests) {
    filter[interest] = ['h'];
  }

  if (Object.keys(filter).length === 0) {
    return {};
  }

  return { interests: filter };
};

const makeAudienceFilters = (query: AudienceQuery): AudienceQueryPayloadFilters => {
  const interestsFilter = makeInterestsFilter(query.interests, query.subInterests);
  const valueANDORQuery = query.queryInterest;
  const subsetInterests = interestsFilter['interests'];
  let andOrInterests = {};

  if (!subsetInterests) {
    andOrInterests = { interests: subsetInterests };
  } else {
    andOrInterests = { interests: { query: [valueANDORQuery, subsetInterests] } };
  }

  return {
    ...makeGenderFilter(query.gender),
    ...makeAgeBandFilter(query.ageBand),
    ...makeSpendingPowerFilter(query.spendingPower),
    ...andOrInterests,
  };
};

const makeProfilesFilters = (query: ProfilesQuery): ProfilesQueryPayloadFilters => {
  const interestsFilter = makeInterestsFilter(query.interests, query.subInterests);
  const valueANDORQuery = query.queryInterest;
  const subsetInterests = interestsFilter['interests'];
  let andOrInterests = {};

  if (!subsetInterests) {
    andOrInterests = { interests: subsetInterests };
  } else {
    andOrInterests = { interests: { query: [valueANDORQuery, subsetInterests] } };
  }
  return {
    ...makeGenderFilter(query.gender),
    ...makeAgeBandFilter(query.ageBand),
    ...makeSpendingPowerFilter(query.spendingPower),
    ...andOrInterests,
  };
};

const makeTimeInterval = (timeOfDay?: TimeOfDay): TimeInterval => {
  if (!timeOfDay) {
    return {
      start_hour: 0,
      end_hour: 24,
    };
  }

  return {
    start_hour: timeOfDay.startHour,
    end_hour: calculateTimeTo(timeOfDay),
  } as TimeInterval; // Casting is safe because all combinations will be valid.
};

/*
  ----- ARGUMENTS -----
*/

const buildAudienceFetchArgs = (
  baseUrl: URL,
  jwtToken: string,
  payload: AudienceQueryPayload,
  params?: AudienceQueryParams
): FetchArgs => {
  const url = new URL('audience', baseUrl);

  if (params && params.format_output) {
    url.searchParams.append('format_output', params.format_output);
  }

  // const payload_test = {
  //   interval: {
  //     start_date: '2019-01-01',
  //     end_date: '2019-01-31',
  //     start_hour: 0,
  //     end_hour: 24,
  //   },
  //   visit_type: 'all',
  //   filters: {
  //     age_band: ['3544'],
  //     spending_power: ['4059'],
  //     interests: {
  //       query: [
  //         'OR',
  //         {
  //           Business: ['h'],
  //           Career_and_Education: ['h'],
  //         },
  //       ],
  //     },
  //   },
  // };

  // const payload_test2 = {
  //   interval: {
  //     start_date: '2019-01-01',
  //     end_date: '2019-01-31',
  //     start_hour: 0,
  //     end_hour: 24,
  //   },
  //   visit_type: 'all',
  //   filters: {
  //     age_band: ['3544'],
  //     spending_power: ['4059'],
  //     interests: {
  //       Business: ['h'],
  //       Career_and_Education: ['h'],
  //     },
  //   },
  // };

  // const body = JSON.stringify(payload_test);

  const body = JSON.stringify(payload);

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };

  const originApi = localStorage.getItem('origin-api');
  if (originApi) {
    headers['origin-api'] = originApi;
  }
  // console.log(body);
  // console.log(payload);
  // console.log(payload.filters?.interests);
  return [url.href, { method: 'POST', body, headers }];
};

const buildCatchmentFetchArgs = (
  baseUrl: URL,
  jwtToken: string,
  payload: CatchmentQueryPayload
): FetchArgs => {
  const url = new URL('catchment', baseUrl);
  const body = JSON.stringify(payload);
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };

  const originApi = localStorage.getItem('origin-api');
  if (originApi) {
    headers['origin-api'] = originApi;
  }

  return [url.href, { method: 'POST', body, headers }];
};

const buildProfilesFetchArgs = (
  baseUrl: URL,
  jwtToken: string,
  payload: ProfilesQueryPayload,
  params: ProfilesQueryParams
): FetchArgs => {
  const url = new URL('profiles', baseUrl);

  url.searchParams.append('results_by', params.results_by);
  url.searchParams.append('geo_input', params.geo_input);

  const body = JSON.stringify(payload);

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };

  const originApi = localStorage.getItem('origin-api');
  if (originApi) {
    headers['origin-api'] = originApi;
  }

  return [url.href, { method: 'POST', body, headers }];
};

const printFetchArgs = (args: FetchArgs): string => {
  if (args[1]) {
    const method = args[1].method || 'GET';
    const postfix = typeof args[1].body === 'string' ? '\n' + args[1].body : '';

    return `${method} ${args[0]}${postfix}`;
  }

  return `GET ${args[0]}`;
};

/*
  ----- ERRORS -----
*/

const buildHttpError = async (response: Response): Promise<Error> => {
  const responseText = await response.text();
  const error = new Error(responseText);

  error.name = `${response.status} ${response.statusText}`;

  return error;
};

/*
  ----- QUERY -----
*/

export interface QueryOptions {
  baseUrl?: URL;
  enableLog?: boolean;
}

// Audience
async function queryAudienceApi(
  payload: AudienceQueryPayload,
  params: AudienceQueryFlatParams,
  dependencies: ApiDependencies
): Promise<AudienceFlatResultPayload>;

async function queryAudienceApi(
  payload: AudienceQueryPayload,
  params: AudienceQueryTreeParams,
  dependencies: ApiDependencies
): Promise<AudienceTreeResultPayload>;

/**
 * An internal 1-to-1 representation of the SmartSteps API
 * @param payload Body of the query
 * @param params URL query params
 * @param dependencies Injectable dependencies to allow easier testing.
 */
async function queryAudienceApi(
  payload: AudienceQueryPayload,
  params: AudienceQueryParams,
  dependencies: ApiDependencies
) {
  const enableLog = dependencies && dependencies.log;
  const deps = { fetch, log: () => {}, ...dependencies };
  const jwtToken = await getJwtToken();
  const fetchArgs = buildAudienceFetchArgs(deps.baseUrl, jwtToken, payload, params);

  if (enableLog) {
    deps.log(printFetchArgs(fetchArgs));
  }

  const response = await fetch(...fetchArgs);

  if (enableLog) {
    deps.log(
      `${response.status} ${response.statusText}\n${Array.from(response.headers).join('\n')}`
    );
  }

  if (!response.ok) {
    // Disabled for styling
    throw await buildHttpError(response);
  }

  return await response.json();
}

// Catchment
async function queryCatchmentApi(
  payload: CatchmentQueryPayload,
  dependencies: ApiDependencies
): Promise<CatchmentResultPayload>;

/**
 * An internal 1-to-1 representation of the SmartSteps API
 * @param payload Body of the query
 * @param params URL query params
 * @param dependencies Injectable dependencies to allow easier testing.
 */
async function queryCatchmentApi(payload: CatchmentQueryPayload, dependencies: ApiDependencies) {
  const enableLog = dependencies?.log;
  const deps = { fetch, log: console.log, ...dependencies };
  const jwtToken = await getJwtToken();
  const fetchArgs = buildCatchmentFetchArgs(deps.baseUrl, jwtToken, payload);

  if (enableLog) {
    deps.log(printFetchArgs(fetchArgs));
  }

  const response = await fetch(...fetchArgs);

  if (enableLog) {
    deps.log(
      `${response.status} ${response.statusText}\n${Array.from(response.headers).join('\n')}`
    );
  }

  if (!response.ok) {
    // Disabled for styling
    throw await buildHttpError(response);
  }

  return await response.json();
}

// Profiles
async function queryProfilesApi(
  payload: ProfilesQueryPayload,
  params: ProfilesQueryParams,
  dependencies: ApiDependencies
): Promise<ProfilesResultPayload>;

/**
 * An internal 1-to-1 representation of the SmartSteps API
 * @param payload Body of the query
 * @param params URL query params
 * @param dependencies Injectable dependencies to allow easier testing.
 */
async function queryProfilesApi(
  payload: ProfilesQueryPayload,
  params: ProfilesQueryParams,
  dependencies: ApiDependencies
) {
  const enableLog = dependencies && dependencies.log;
  const deps = { fetch, log: () => {}, ...dependencies };
  const jwtToken = await getJwtToken();
  const fetchArgs = buildProfilesFetchArgs(deps.baseUrl, jwtToken, payload, params);

  if (enableLog) {
    deps.log(printFetchArgs(fetchArgs));
  }

  const response = await fetch(...fetchArgs);

  if (enableLog) {
    deps.log(
      `${response.status} ${response.statusText}\n${Array.from(response.headers).join('\n')}`
    );
  }

  if (!response.ok) {
    // Disabled for styling
    throw await buildHttpError(response);
  }

  return await response.json();
}

/*
  ----- EXPORTS -----
*/

const defaultBaseUrl = process.env.REACT_APP_API_BASE
  ? new URL(process.env.REACT_APP_API_BASE)
  : new URL('/api/', window.location.origin);

// Audience
export async function queryAudience(
  query: AudienceQuery,
  options?: QueryOptions
): Promise<AudienceResult> {
  const dependencies = {
    log: options && options.enableLog ? console.log : undefined,
    baseUrl: options?.baseUrl ?? defaultBaseUrl,
  };
  const filters = makeAudienceFilters(query);
  const filtersProp = Object.keys(filters).length > 0 ? { filters } : {};
  const payloadQuery = {
    interval: {
      start_date: format(query.dateRange.startDate, 'yyyy-MM-dd'),
      end_date: format(query.dateRange.endDate, 'yyyy-MM-dd'),
      ...makeTimeInterval(query.timeOfDay),
    },
    visit_type: query.visitType,
    ...filtersProp,
  };

  // console.log({ filters });

  const result = await queryAudienceApi(payloadQuery, { format_output: 'tree' }, dependencies);

  return {
    results: result.results,
    search: query,
    count: result.count,
  };
}

// Profiles
export async function queryProfiles(
  query: ProfilesQuery,
  options?: QueryOptions
): Promise<ProfilesResult> {
  const dependencies = {
    log: options && options.enableLog ? console.log : undefined,
    baseUrl: options?.baseUrl ?? defaultBaseUrl,
  };
  const filters = makeProfilesFilters(query);
  const filtersProp = Object.keys(filters).length > 0 ? { filters } : {};
  const payloadQuery = {
    locations: query.locations,
    interval: {
      start_date: format(query.dateRange.startDate, 'yyyy-MM-dd'),
      end_date: format(query.dateRange.endDate, 'yyyy-MM-dd'),
      ...makeTimeInterval(query.timeOfDay),
    },
    visit_type: query.visitType,
    ...filtersProp,
  };

  const result = await queryProfilesApi(
    payloadQuery,
    { results_by: 'all', geo_input: 'postal_sector' },
    dependencies
  );

  return {
    results: result.results,
    search: query,
    count: result.count,
  };
}

export async function queryCatchment(
  query: CatchmentFilter,
  options?: QueryOptions
): Promise<CatchmentResult> {
  const dependencies = {
    log: options?.enableLog ? console.log : undefined,
    baseUrl: options?.baseUrl ?? defaultBaseUrl,
  };
  const payloadQuery = { day: format(query.day, 'yyyy-MM-dd'), locations: query.locations };
  const result = await queryCatchmentApi(payloadQuery, dependencies);

  return {
    results: result.results,
    search: result.search,
    count: result.search.result_items,
  };
}
