import { getJwtToken } from './authAzure';

const buildHttpError = async (response: Response): Promise<Error> => {
  const responseText = await response.text();
  const error = new Error(responseText);

  error.name = `${response.status} ${response.statusText}`;

  return error;
};

export interface PostalPermission {
  Postal: string;
}

export interface PostalResult {
  output: object;
  recordset: PostalPermission[];
  recordsets: PostalPermission[];
  rowsAffected: number[];
}

/**
 * An internal 1-to-1 representation of the SmartSteps API
 * @param payload Body of the query
 * @param params URL query params
 * @param dependencies Injectable dependencies to allow easier testing.
 */
export async function queryPostalApi(payload: { username: string }): Promise<PostalResult> {
  const defaultBaseUrl = process.env.REACT_APP_API_BASE
    ? new URL(process.env.REACT_APP_API_BASE)
    : new URL('/api/', window.location.origin);
  const url = new URL('permission', defaultBaseUrl);
  const jwtToken = await getJwtToken();

  const body = JSON.stringify(payload);
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };

  const originApi = localStorage.getItem('origin-api');
  if (originApi) {
    headers['origin-api'] = originApi;
  }

  const response = await fetch(url.href, { method: 'POST', body, headers });

  if (!response.ok) {
    // Disabled for styling
    throw await buildHttpError(response);
  }

  return await response.json();
}
