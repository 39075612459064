import Paper from '@material-ui/core/Paper';
import React, { useContext, useEffect, useRef, useState } from 'react';

import MultiSelect from '../../Assets/Form/MultiSelect';
import SingleSelect from '../../Assets/Form/SingleSelect';
import ToggleButtons from '../../Assets/Form/ToggleButtons';
import Info from '../../Assets/Images/InfoIcon';
import ProfilesHelp, { HelpView } from './Help';

import {
  AGE_BANDS,
  demoUserLocationPrefixes,
  GENDERS,
  INTERESTS,
  LOCATIONS,
  QUERYS,
  SPENDING_POWERS,
  VISIT_TYPES,
} from '../../../constants/filter';
import {
  AgeBand,
  Gender,
  Interest,
  ProfilesFilter as ProfilesFilterState,
  QueryInterest,
  SpendingPower,
  VisitType,
} from '../../../types/smartStepsApi';

import { getAvailableSubInterests } from '../../../lib/filter';
import { StyledPaper } from '../../../styles/MaterialComponents';
import { SelectOption } from '../../../types/audienceFilter';
import { AuthContext } from '../../Auth/AuthProvider';
import ProfilesFieldStyles, { ProfilesField } from './Fields.styles';
import { queryPostalApi } from '../../../lib/postal';

// Types
interface Props {
  profilesFilter: ProfilesFilterState;
  includeHelp: boolean;
  onChange: (profilesFilter: ProfilesFilterState) => void;
  toggleDrawer?: (open: boolean, event?: React.KeyboardEvent | React.MouseEvent) => void;
  helpView?: HelpView | undefined;
  setHelpView?: (helpView: HelpView | undefined) => void;
}

export const ProfilesFields: React.FC<Props> = ({
  profilesFilter,
  includeHelp,
  onChange,
  toggleDrawer,
  helpView,
  setHelpView,
}: Props) => {
  // Local State
  const [filteredLocations, setFilteredLocations] = useState<SelectOption<string>[]>(LOCATIONS);
  const [locations, setLocations] = useState<string[]>(profilesFilter.locations);
  const [visitType, setVisitType] = useState<VisitType>(profilesFilter.visitType);
  const [gender, setGender] = useState<Gender>(profilesFilter.gender);
  const [ageBand, setAgeBand] = useState<AgeBand[]>(profilesFilter.ageBand);
  const [interests, setInterests] = useState<Interest[]>(profilesFilter.interests);
  const [subInterests, setSubInterests] = useState<string[]>(profilesFilter.subInterests);
  const [availableSubInterest, setAvailableSubInterest] = useState<SelectOption<string>[]>([]);
  const [queryInterest, setQueryInterest] = useState<QueryInterest>(profilesFilter.queryInterest);
  const [spendingPower, setSpendingPower] = useState<SpendingPower[]>(profilesFilter.spendingPower);
  const [showError, setShowError] = useState<boolean | undefined>(undefined);
  const { state: authState } = useContext(AuthContext);

  // Refs
  const locationsRef = useRef<HTMLDivElement>(null);
  const visitTypeRef = useRef<HTMLDivElement>(null);
  const genderRef = useRef<HTMLDivElement>(null);
  const ageBandRef = useRef<HTMLDivElement>(null);
  const interestRef = useRef<HTMLDivElement>(null);
  const spendingPowerRef = useRef<HTMLDivElement>(null);

  // Effects
  useEffect(() => {
    const fetch = async () => {
      if (authState?.username) {
        const postalResult = await queryPostalApi({ username: authState.username });
        if (postalResult.recordset.length > 0) {
          const filter = LOCATIONS.filter((item) =>
            postalResult.recordset.some(
              (permission) =>
                item.value.split(' ')[0].toLocaleLowerCase() ===
                permission.Postal.toLocaleLowerCase()
            )
          );

          setFilteredLocations(filter);
        }
      } else if (!authState?.isAuthorized) {
        const filter = LOCATIONS.filter((l) =>
          demoUserLocationPrefixes.some((p) => l.value.startsWith(p))
        );
        setFilteredLocations(filter);
      }
    };

    fetch().catch(console.error);
  }, [authState?.username]);

  useEffect(() => {
    setLocations(profilesFilter.locations);
    setVisitType(profilesFilter.visitType);
    setGender(profilesFilter.gender);
    setAgeBand(profilesFilter.ageBand);
    setSpendingPower(profilesFilter.spendingPower);
    setInterests(profilesFilter.interests);
    setSubInterests(profilesFilter.subInterests);

    setAvailableSubInterest(getAvailableSubInterests(profilesFilter.interests));
  }, [profilesFilter]);

  useEffect(() => {
    if (locations.length) {
      setShowError(false);
    } else {
      setShowError(true);
    }

    onChange({
      locations,
      visitType,
      gender,
      ageBand,
      spendingPower,
      interests,
      subInterests,
      queryInterest,
    });
  }, [
    locations,
    visitType,
    gender,
    ageBand,
    spendingPower,
    interests,
    subInterests,
    queryInterest,
    onChange,
  ]);

  useEffect(() => {
    // Refs
    const locationsRefLocal = locationsRef.current;
    const visitTypeRefLocal = visitTypeRef.current;
    const genderRefLocal = genderRef.current;
    const ageBandRefLocal = ageBandRef.current;
    const spendingPowerRefLocal = spendingPowerRef.current;
    const interestRefLocal = interestRef.current;

    // Helpers
    const addListeners = (ref: HTMLDivElement | null, helpView: HelpView) => {
      if (ref && setHelpView) {
        ref.addEventListener('mouseenter', () => setHelpView(helpView));
        ref.addEventListener('focusin', () => setHelpView(helpView));
      }
    };

    const removeListeners = (ref: HTMLDivElement | null, helpView: HelpView) => {
      if (ref && setHelpView) {
        ref.removeEventListener('mouseenter', () => setHelpView(helpView));
        ref.removeEventListener('focusin', () => setHelpView(helpView));
      }
    };

    // Add help listeners if required
    if (includeHelp) {
      addListeners(locationsRefLocal, 'locations');
      addListeners(visitTypeRefLocal, 'visitType');
      addListeners(genderRefLocal, 'gender');
      addListeners(ageBandRefLocal, 'ageBand');
      addListeners(spendingPowerRefLocal, 'spendingPower');
      addListeners(interestRefLocal, 'interest');
      addListeners(interestRefLocal, 'subInterests');
    }

    // Cleanup to remove help listeners (if required)
    return () => {
      if (includeHelp) {
        removeListeners(locationsRefLocal, 'locations');
        removeListeners(visitTypeRefLocal, 'visitType');
        removeListeners(genderRefLocal, 'gender');
        removeListeners(ageBandRefLocal, 'ageBand');
        removeListeners(spendingPowerRefLocal, 'spendingPower');
        removeListeners(interestRefLocal, 'interest');
        removeListeners(interestRefLocal, 'subInterests');
      }
    };
  }, [includeHelp, setHelpView]);

  // Render
  const filter = (
    <>
      <ProfilesField focused={helpView === 'locations'} ref={locationsRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}
        {!authState?.isAuthorized && (
          <div className="demo-user-note">
            You are in demo mode. Only locations in Wales region available.
          </div>
        )}
        <MultiSelect
          showError={showError}
          label="Locations"
          placeholder="Please select up to 20 locations"
          data={filteredLocations}
          selected={locations}
          onChange={(locations) => setLocations(locations)}
          position="bottom"
          limit={20}
        />
      </ProfilesField>

      <ProfilesField focused={helpView === 'visitType'} ref={visitTypeRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}
        <SingleSelect
          label="Visitor Type"
          data={VISIT_TYPES}
          selected={visitType}
          onChange={(value) => setVisitType(value)}
        />
      </ProfilesField>

      <ProfilesField focused={helpView === 'gender'} ref={genderRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}
        <SingleSelect
          label="Gender"
          data={GENDERS}
          selected={gender}
          onChange={(value) => setGender(value)}
        />
      </ProfilesField>

      <ProfilesField focused={helpView === 'ageBand'} ref={ageBandRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}

        <ToggleButtons
          label="Age Bands"
          data={AGE_BANDS}
          selected={ageBand}
          onClick={(ageBand) => setAgeBand(ageBand)}
        />
      </ProfilesField>

      <ProfilesField focused={helpView === 'spendingPower'} ref={spendingPowerRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}

        <ToggleButtons
          label="Spending Power"
          data={SPENDING_POWERS}
          selected={spendingPower}
          onClick={(spendingPower) => setSpendingPower(spendingPower)}
        />
      </ProfilesField>

      <ProfilesField focused={helpView === 'interest'} ref={interestRef}>
        {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}

        <SingleSelect
          label="Interests"
          data={QUERYS}
          selected={queryInterest}
          onChange={(value) => setQueryInterest(value)}
        />

        <MultiSelect
          label="Interests"
          placeholder="Please select all that apply"
          data={INTERESTS}
          selected={interests}
          onChange={(interests) => setInterests(interests)}
          position="top"
        />
        <MultiSelect
          //label="Interests"
          label=""
          placeholder="Please select sub category"
          data={availableSubInterest}
          selected={subInterests}
          onChange={(subInterest) => setSubInterests(subInterest)}
          position="top"
        />
      </ProfilesField>
    </>
  );

  return (
    <ProfilesFieldStyles includeHelp={includeHelp}>
      <div className="outerContainer">
        <div className="filterContainer">
          {includeHelp && <Paper className="filterPaper">{filter}</Paper>}
          {!includeHelp && filter}
        </div>
        {includeHelp ? (
          <div className="helpContainer">
            <StyledPaper>
              <ProfilesHelp display={helpView} />
            </StyledPaper>
          </div>
        ) : null}
      </div>
    </ProfilesFieldStyles>
  );
};
