import React, { useState, FormEvent } from 'react';
import Clear from '@material-ui/icons/Clear';
import Drawer from '@material-ui/core/Drawer';

import { AudienceFields } from './Fields';
import { AudienceHelp, HelpView } from './Help';
import Button from '../../Assets/Form/Button';

import { AudienceFilter as AudienceFilterState } from '../../../types/smartStepsApi';

import AudienceFilterStyles from './Filter.styles';
import DrawerStyles from '../../../styles/Drawer.styles';

// Types
interface Props {
  audienceFilter: AudienceFilterState;
  onSubmit: (audienceFilter: AudienceFilterState) => void;
}

const AudienceFilter: React.FC<Props> = ({ audienceFilter, onSubmit }: Props) => {
  // Local State
  const [audience, setAudience] = useState(audienceFilter);
  const [mouseHover, setMouseHover] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [helpView, setHelpView] = useState<HelpView | undefined>(undefined);

  // Handlers
  const submitForm = (e: FormEvent) => {
    e.preventDefault();

    // Continue to parent
    onSubmit(audience);
  };

  const toggleDrawer = (open: boolean, event?: React.KeyboardEvent | React.MouseEvent) => {
    // Copied accessibility example from Material-UI
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  // Render
  return (
    <AudienceFilterStyles>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={(e: React.KeyboardEvent | React.MouseEvent) => toggleDrawer(false, e)}
        onMouseEnter={() => {
          // Resetting helpView to combat onMouseLeave
          setHelpView(helpView);
        }}
      >
        <DrawerStyles>
          <Clear onClick={(e: React.MouseEvent) => toggleDrawer(false, e)} />
          <AudienceHelp display={helpView} />
        </DrawerStyles>
      </Drawer>

      <form onSubmit={submitForm}>
        <div
          onMouseEnter={() => setMouseHover(true)}
          onMouseLeave={() => {
            setMouseHover(false);
            setHelpView(undefined);
          }}
        >
          <AudienceFields
            audienceFilter={audience}
            includeHelp={true}
            onChange={setAudience}
            toggleDrawer={toggleDrawer}
            helpView={helpView}
            setHelpView={setHelpView}
          />
        </div>
        <div className="submitButton">
          <Button text="Results" active={mouseHover ? 'on' : 'off'} />
        </div>
      </form>
    </AudienceFilterStyles>
  );
};

export default AudienceFilter;
