import styled from 'styled-components';

export const SignInStyles = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .content {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0 3rem;

    > .headings {
      flex: 0 0 auto;
      margin: 3rem 0;

      color: #ffffff;
    }

    > .paper {
      flex: 0 0 auto;

      > form {
        > .intro {
          margin-bottom: 3rem;
        }
        > .buttons {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  > .login {
    flex: 0 0 6rem;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 3rem 2rem 2rem;

    .o2logo-container {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .logout-container {
      flex: 1;

      text-align: center;
      line-height: 1.6rem;
    }

    .the-lab-logo-container {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        margin: 0 0.8rem;

        font-weight: 600;
        font-size: 1rem;
        color: white;
      }
    }
  }

  .errorSnack {
    background-color: ${(props) => props.theme.palette.error.dark};

    color: #ffffff;

    .message {
      display: flex;
      align-items: center;

      > .icon {
        font-size: 2rem;
        opacity: 0.9;
        margin-right: 1rem;
      }
    }
  }
`;
