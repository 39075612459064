import { Iterables, Maps, pipe } from 'collection-fns';

export interface NUTSArea {
  type: 'NUTS1' | 'NUTS2' | 'NUTS3' | 'Sector';
  code: string;
  description: string;
}

export const NUTS1Areas: NUTSArea[] = [
  { type: 'NUTS1', code: 'UKC', description: 'NORTH EAST (ENGLAND)' },
  { type: 'NUTS1', code: 'UKD', description: 'NORTH WEST (ENGLAND)' },
  { type: 'NUTS1', code: 'UKE', description: 'YORKSHIRE AND THE HUMBER' },
  { type: 'NUTS1', code: 'UKF', description: 'EAST MIDLANDS (ENGLAND)' },
  { type: 'NUTS1', code: 'UKG', description: 'WEST MIDLANDS (ENGLAND)' },
  { type: 'NUTS1', code: 'UKH', description: 'EAST OF ENGLAND' },
  { type: 'NUTS1', code: 'UKI', description: 'LONDON' },
  { type: 'NUTS1', code: 'UKJ', description: 'SOUTH EAST (ENGLAND)' },
  { type: 'NUTS1', code: 'UKK', description: 'SOUTH WEST (ENGLAND)' },
  { type: 'NUTS1', code: 'UKL', description: 'WALES' },
  { type: 'NUTS1', code: 'UKM', description: 'SCOTLAND' },
  { type: 'NUTS1', code: 'UKN', description: 'NORTHERN IRELAND' },
  { type: 'NUTS1', code: 'UKZ', description: 'EXTRA-REGIO NUTS 1' },
];

export const NUTS2Areas: NUTSArea[] = [
  { type: 'NUTS2', code: 'UKC1', description: 'Tees Valley and Durham' },
  { type: 'NUTS2', code: 'UKC2', description: 'Northumberland and Tyne and Wear' },
  { type: 'NUTS2', code: 'UKD1', description: 'Cumbria' },
  { type: 'NUTS2', code: 'UKD3', description: 'Greater Manchester' },
  { type: 'NUTS2', code: 'UKD4', description: 'Lancashire' },
  { type: 'NUTS2', code: 'UKD6', description: 'Cheshire' },
  { type: 'NUTS2', code: 'UKD7', description: 'Merseyside' },
  { type: 'NUTS2', code: 'UKE1', description: 'East Yorkshire and Northern Lincolnshire' },
  { type: 'NUTS2', code: 'UKE2', description: 'North Yorkshire' },
  { type: 'NUTS2', code: 'UKE3', description: 'South Yorkshire' },
  { type: 'NUTS2', code: 'UKE4', description: 'West Yorkshire' },
  { type: 'NUTS2', code: 'UKF1', description: 'Derbyshire and Nottinghamshire' },
  { type: 'NUTS2', code: 'UKF2', description: 'Leicestershire, Rutland and Northamptonshire' },
  { type: 'NUTS2', code: 'UKF3', description: 'Lincolnshire' },
  { type: 'NUTS2', code: 'UKG1', description: 'Herefordshire, Worcestershire and Warwickshire' },
  { type: 'NUTS2', code: 'UKG2', description: 'Shropshire and Staffordshire' },
  { type: 'NUTS2', code: 'UKG3', description: 'West Midlands' },
  { type: 'NUTS2', code: 'UKH1', description: 'East Anglia' },
  { type: 'NUTS2', code: 'UKH2', description: 'Bedfordshire and Hertfordshire' },
  { type: 'NUTS2', code: 'UKH3', description: 'Essex' },
  { type: 'NUTS2', code: 'UKI1', description: 'Inner London' },
  { type: 'NUTS2', code: 'UKI2', description: 'Outer London' },
  { type: 'NUTS2', code: 'UKJ1', description: 'Berkshire, Buckinghamshire and Oxfordshire' },
  { type: 'NUTS2', code: 'UKJ2', description: 'Surrey, East and West Sussex' },
  { type: 'NUTS2', code: 'UKJ3', description: 'Hampshire and Isle of Wight' },
  { type: 'NUTS2', code: 'UKJ4', description: 'Kent' },
  { type: 'NUTS2', code: 'UKK1', description: 'Gloucestershire, Wiltshire and Bristol/Bath area' },
  { type: 'NUTS2', code: 'UKK2', description: 'Dorset and Somerset' },
  { type: 'NUTS2', code: 'UKK3', description: 'Cornwall and Isles of Scilly' },
  { type: 'NUTS2', code: 'UKK4', description: 'Devon' },
  { type: 'NUTS2', code: 'UKL1', description: 'West Wales and The Valleys' },
  { type: 'NUTS2', code: 'UKL2', description: 'East Wales' },
  { type: 'NUTS2', code: 'UKM2', description: 'Eastern Scotland' },
  { type: 'NUTS2', code: 'UKM3', description: 'South Western Scotland' },
  { type: 'NUTS2', code: 'UKM5', description: 'North Eastern Scotland' },
  { type: 'NUTS2', code: 'UKM6', description: 'Highlands and Islands' },
  { type: 'NUTS2', code: 'UKN0', description: 'Northern Ireland' },
  { type: 'NUTS2', code: 'UKZZ', description: 'Extra-Regio NUTS 2' },
];

export const NUTS3Areas: NUTSArea[] = [
  { type: 'NUTS3', code: 'UKC11', description: 'Hartlepool and Stockton-on-Tees' },
  { type: 'NUTS3', code: 'UKC12', description: 'South Teesside' },
  { type: 'NUTS3', code: 'UKC13', description: 'Darlington' },
  { type: 'NUTS3', code: 'UKC14', description: 'Durham CC' },
  { type: 'NUTS3', code: 'UKC21', description: 'Northumberland' },
  { type: 'NUTS3', code: 'UKC22', description: 'Tyneside' },
  { type: 'NUTS3', code: 'UKC23', description: 'Sunderland' },
  { type: 'NUTS3', code: 'UKD11', description: 'West Cumbria' },
  { type: 'NUTS3', code: 'UKD12', description: 'East Cumbria' },
  { type: 'NUTS3', code: 'UKD31', description: 'Greater Manchester South' },
  { type: 'NUTS3', code: 'UKD32', description: 'Greater Manchester North' },
  { type: 'NUTS3', code: 'UKD41', description: 'Blackburn with Darwen' },
  { type: 'NUTS3', code: 'UKD42', description: 'Blackpool' },
  { type: 'NUTS3', code: 'UKD43', description: 'Lancashire CC' },
  { type: 'NUTS3', code: 'UKD61', description: 'Warrington' },
  { type: 'NUTS3', code: 'UKD62', description: 'Cheshire East' },
  { type: 'NUTS3', code: 'UKD63', description: 'Cheshire West and Chester' },
  { type: 'NUTS3', code: 'UKD71', description: 'East Merseyside' },
  { type: 'NUTS3', code: 'UKD72', description: 'Liverpool' },
  { type: 'NUTS3', code: 'UKD73', description: 'Sefton' },
  { type: 'NUTS3', code: 'UKD74', description: 'Wirral' },
  { type: 'NUTS3', code: 'UKE11', description: 'Kingston upon Hull, City of' },
  { type: 'NUTS3', code: 'UKE12', description: 'East Riding of Yorkshire' },
  { type: 'NUTS3', code: 'UKE13', description: 'North and North East Lincolnshire' },
  { type: 'NUTS3', code: 'UKE21', description: 'York' },
  { type: 'NUTS3', code: 'UKE22', description: 'North Yorkshire CC' },
  { type: 'NUTS3', code: 'UKE31', description: 'Barnsley, Doncaster and Rotherham' },
  { type: 'NUTS3', code: 'UKE32', description: 'Sheffield' },
  { type: 'NUTS3', code: 'UKE41', description: 'Bradford' },
  { type: 'NUTS3', code: 'UKE42', description: 'Leeds' },
  { type: 'NUTS3', code: 'UKE44', description: 'Calderdale and Kirklees' },
  { type: 'NUTS3', code: 'UKE45', description: 'Wakefield' },
  { type: 'NUTS3', code: 'UKF11', description: 'Derby' },
  { type: 'NUTS3', code: 'UKF12', description: 'East Derbyshire' },
  { type: 'NUTS3', code: 'UKF13', description: 'South and West Derbyshire' },
  { type: 'NUTS3', code: 'UKF14', description: 'Nottingham' },
  { type: 'NUTS3', code: 'UKF15', description: 'North Nottinghamshire' },
  { type: 'NUTS3', code: 'UKF16', description: 'South Nottinghamshire' },
  { type: 'NUTS3', code: 'UKF21', description: 'Leicester' },
  { type: 'NUTS3', code: 'UKF22', description: 'Leicestershire CC and Rutland' },
  { type: 'NUTS3', code: 'UKF24', description: 'West Northamptonshire' },
  { type: 'NUTS3', code: 'UKF25', description: 'North Northamptonshire' },
  { type: 'NUTS3', code: 'UKF30', description: 'Lincolnshire' },
  { type: 'NUTS3', code: 'UKG11', description: 'Herefordshire, County of' },
  { type: 'NUTS3', code: 'UKG12', description: 'Worcestershire' },
  { type: 'NUTS3', code: 'UKG13', description: 'Warwickshire' },
  { type: 'NUTS3', code: 'UKG21', description: 'Telford and Wrekin' },
  { type: 'NUTS3', code: 'UKG22', description: 'Shropshire CC' },
  { type: 'NUTS3', code: 'UKG23', description: 'Stoke-on-Trent' },
  { type: 'NUTS3', code: 'UKG24', description: 'Staffordshire CC' },
  { type: 'NUTS3', code: 'UKG31', description: 'Birmingham' },
  { type: 'NUTS3', code: 'UKG32', description: 'Solihull' },
  { type: 'NUTS3', code: 'UKG33', description: 'Coventry' },
  { type: 'NUTS3', code: 'UKG36', description: 'Dudley' },
  { type: 'NUTS3', code: 'UKG37', description: 'Sandwell' },
  { type: 'NUTS3', code: 'UKG38', description: 'Walsall' },
  { type: 'NUTS3', code: 'UKG39', description: 'Wolverhampton' },
  { type: 'NUTS3', code: 'UKH11', description: 'Peterborough' },
  { type: 'NUTS3', code: 'UKH12', description: 'Cambridgeshire CC' },
  { type: 'NUTS3', code: 'UKH13', description: 'Norfolk' },
  { type: 'NUTS3', code: 'UKH14', description: 'Suffolk' },
  { type: 'NUTS3', code: 'UKH21', description: 'Luton' },
  { type: 'NUTS3', code: 'UKH23', description: 'Hertfordshire' },
  { type: 'NUTS3', code: 'UKH24', description: 'Bedford' },
  { type: 'NUTS3', code: 'UKH25', description: 'Central Bedfordshire' },
  { type: 'NUTS3', code: 'UKH31', description: 'Southend-on-Sea' },
  { type: 'NUTS3', code: 'UKH32', description: 'Thurrock' },
  { type: 'NUTS3', code: 'UKH33', description: 'Essex CC' },
  { type: 'NUTS3', code: 'UKI11', description: 'Inner London - West' },
  { type: 'NUTS3', code: 'UKI12', description: 'Inner London - East' },
  { type: 'NUTS3', code: 'UKI21', description: 'Outer London - East and North East' },
  { type: 'NUTS3', code: 'UKI22', description: 'Outer London - South' },
  { type: 'NUTS3', code: 'UKI23', description: 'Outer London - West and North West' },
  { type: 'NUTS3', code: 'UKJ11', description: 'Berkshire' },
  { type: 'NUTS3', code: 'UKJ12', description: 'Milton Keynes' },
  { type: 'NUTS3', code: 'UKJ13', description: 'Buckinghamshire CC' },
  { type: 'NUTS3', code: 'UKJ14', description: 'Oxfordshire' },
  { type: 'NUTS3', code: 'UKJ21', description: 'Brighton and Hove' },
  { type: 'NUTS3', code: 'UKJ22', description: 'East Sussex CC' },
  { type: 'NUTS3', code: 'UKJ23', description: 'Surrey' },
  { type: 'NUTS3', code: 'UKJ24', description: 'West Sussex' },
  { type: 'NUTS3', code: 'UKJ31', description: 'Portsmouth' },
  { type: 'NUTS3', code: 'UKJ32', description: 'Southampton' },
  { type: 'NUTS3', code: 'UKJ33', description: 'Hampshire CC' },
  { type: 'NUTS3', code: 'UKJ34', description: 'Isle of Wight' },
  { type: 'NUTS3', code: 'UKJ41', description: 'Medway' },
  { type: 'NUTS3', code: 'UKJ42', description: 'Kent CC' },
  { type: 'NUTS3', code: 'UKK11', description: 'Bristol, City of' },
  {
    type: 'NUTS3',
    code: 'UKK12',
    description: 'Bath and North East Somerset, North Somerset and South Gloucestershire',
  },
  { type: 'NUTS3', code: 'UKK13', description: 'Gloucestershire' },
  { type: 'NUTS3', code: 'UKK14', description: 'Swindon' },
  { type: 'NUTS3', code: 'UKK15', description: 'Wiltshire CC' },
  { type: 'NUTS3', code: 'UKK21', description: 'Bournemouth and Poole' },
  { type: 'NUTS3', code: 'UKK22', description: 'Dorset CC' },
  { type: 'NUTS3', code: 'UKK23', description: 'Somerset' },
  { type: 'NUTS3', code: 'UKK30', description: 'Cornwall and Isles of Scilly' },
  { type: 'NUTS3', code: 'UKK41', description: 'Plymouth' },
  { type: 'NUTS3', code: 'UKK42', description: 'Torbay' },
  { type: 'NUTS3', code: 'UKK43', description: 'Devon CC' },
  { type: 'NUTS3', code: 'UKL11', description: 'Isle of Anglesey' },
  { type: 'NUTS3', code: 'UKL12', description: 'Gwynedd' },
  { type: 'NUTS3', code: 'UKL13', description: 'Conwy and Denbighshire' },
  { type: 'NUTS3', code: 'UKL14', description: 'South West Wales' },
  { type: 'NUTS3', code: 'UKL15', description: 'Central Valleys' },
  { type: 'NUTS3', code: 'UKL16', description: 'Gwent Valleys' },
  { type: 'NUTS3', code: 'UKL17', description: 'Bridgend and Neath Port Talbot' },
  { type: 'NUTS3', code: 'UKL18', description: 'Swansea' },
  { type: 'NUTS3', code: 'UKL21', description: 'Monmouthshire and Newport' },
  { type: 'NUTS3', code: 'UKL22', description: 'Cardiff and Vale of Glamorgan' },
  { type: 'NUTS3', code: 'UKL23', description: 'Flintshire and Wrexham' },
  { type: 'NUTS3', code: 'UKL24', description: 'Powys' },
  { type: 'NUTS3', code: 'UKM21', description: 'Angus and Dundee City' },
  { type: 'NUTS3', code: 'UKM22', description: 'Clackmannanshire and Fife' },
  { type: 'NUTS3', code: 'UKM23', description: 'East Lothian and Midlothian' },
  { type: 'NUTS3', code: 'UKM24', description: 'Scottish Borders' },
  { type: 'NUTS3', code: 'UKM25', description: 'Edinburgh, City of' },
  { type: 'NUTS3', code: 'UKM26', description: 'Falkirk' },
  { type: 'NUTS3', code: 'UKM27', description: 'Perth & Kinross and Stirling' },
  { type: 'NUTS3', code: 'UKM28', description: 'West Lothian' },
  {
    type: 'NUTS3',
    code: 'UKM31',
    description: 'East Dunbartonshire, West Dunbartonshire and Helensburgh & Lomond',
  },
  { type: 'NUTS3', code: 'UKM32', description: 'Dumfries & Galloway' },
  { type: 'NUTS3', code: 'UKM33', description: 'East Ayrshire and North Ayrshire mainland' },
  { type: 'NUTS3', code: 'UKM34', description: 'Glasgow City' },
  { type: 'NUTS3', code: 'UKM35', description: 'Inverclyde, East Renfrewshire and Renfrewshire' },
  { type: 'NUTS3', code: 'UKM36', description: 'North Lanarkshire' },
  { type: 'NUTS3', code: 'UKM37', description: 'South Ayrshire' },
  { type: 'NUTS3', code: 'UKM38', description: 'South Lanarkshire' },
  { type: 'NUTS3', code: 'UKM50', description: 'Aberdeen City and Aberdeenshire' },
  { type: 'NUTS3', code: 'UKM61', description: 'Caithness & Sutherland and Ross & Cromarty' },
  {
    type: 'NUTS3',
    code: 'UKM62',
    description: 'Inverness & Nairn and Moray, Badenoch & Strathspey',
  },
  {
    type: 'NUTS3',
    code: 'UKM63',
    description: 'Lochaber, Skye & Lochalsh, Arran & Cumbrae and Argyll & Bute',
  },
  { type: 'NUTS3', code: 'UKM64', description: 'Eilean Siar (Western Isles)' },
  { type: 'NUTS3', code: 'UKM65', description: 'Orkney Islands' },
  { type: 'NUTS3', code: 'UKM66', description: 'Shetland Islands' },
  { type: 'NUTS3', code: 'UKN01', description: 'Belfast' },
  { type: 'NUTS3', code: 'UKN02', description: 'Outer Belfast' },
  { type: 'NUTS3', code: 'UKN03', description: 'East of Northern Ireland' },
  { type: 'NUTS3', code: 'UKN04', description: 'North of Northern Ireland' },
  { type: 'NUTS3', code: 'UKN05', description: 'West and South of Northern Ireland' },
  { type: 'NUTS3', code: 'UKZZZ', description: 'Extra-Regio NUTS 3' },
];

export const PostcodeSectors: NUTSArea[] = [
  { type: 'Sector', code: 'IM', description: 'Isle of Man' },
  { type: 'Sector', code: 'GY', description: 'Guernsey' },
  { type: 'Sector', code: 'JE', description: 'Jersey' },
];

export const AllNUTSAreas = [...NUTS1Areas, ...NUTS2Areas, ...NUTS3Areas, ...PostcodeSectors];

const indexedNUTSAreas = pipe(
  AllNUTSAreas,
  Iterables.map((area): [string, NUTSArea] => [area.code, area]),
  Maps.ofIterable
);

export const findNUTSAreaForPath = (path: string[]): NUTSArea | undefined => {
  for (const step of [...path].reverse()) {
    const area = indexedNUTSAreas.get(step);
    if (area !== undefined) {
      return area;
    }
  }
  return undefined;
};
