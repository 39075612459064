import styled from 'styled-components';

const InfoIcon = styled.div`
  position: relative;
  > svg {
    position: absolute;
    right: 0;
    display: none;
    width: 1.8rem;
    color: #0090cf;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    > svg {
      display: inline-block;
    }
  }
`;

export default InfoIcon;
