import React from 'react';

export type HelpView =
  | 'visitType'
  | 'gender'
  | 'ageBand'
  | 'spendingPower'
  | 'interest'
  | 'subInterest';

interface Props {
  display?: HelpView;
}

// eslint-disable-next-line react/prop-types
export const AudienceHelp: React.FC<Props> = ({ display }) => {
  switch (display) {
    case 'visitType':
      return (
        <>
          <h3>Visitor Type</h3>
          <p>
            Count people based if their location is within the vicinity of their home or work
            address, otherwise assumes they&apos;re a visitor.
          </p>
          <p>
            Example 1: Selecting &quot;Worker&quot; will only count people when they are within the
            vicinity of their work address.
          </p>
          <p>
            Example 2: Selecting &quot;Visitor&quot; will only count people when they are not within
            the vicinity of either their home or work addresses.
          </p>
        </>
      );
    case 'gender':
      return (
        <>
          <h3>Gender</h3>
          <p>Limit results to count people only where we know their gender to be male or female.</p>
          <p>
            Selecting &quot;All&quot; will also include people where we do not know their gender.
          </p>
        </>
      );
    case 'ageBand':
      return (
        <>
          <h3>Age Bands</h3>
          <p>Limit results to count people whose age falls within the selected brackets.</p>
          <p>
            If no age brackets are selected then people of all ages will be included in the counts.
          </p>
        </>
      );
    case 'spendingPower':
      return (
        <>
          <h3>Spending Power</h3>
          <p>This is an statistical estimation of a person&apos;s spending power based on:</p>
          <ul>
            <li>Home and work locations</li>
            <li>Inbound phone calls</li>
            <li>Distance travelled</li>
          </ul>
        </>
      );
    case 'interest':
      return (
        <>
          <h3>Interests</h3>
          <p>
            If AND is selected as query it only counts people who have interest in all of the
            selected tier 1 IAB categories.
          </p>
          <p>
            If OR is selected as query it sums up people who have interest in any of the selected
            tier 1 IAB categories.
          </p>
          <p>This information is inferred from internet usage.</p>
        </>
      );
    default:
      return (
        <>
          <h3>Welcome to Audience Insights!</h3>
          <p>
            This is a visualization tool for the Audience Insights API, developed for media agencies
            to plan marketing campaigns based on audience locations.
          </p>
          <p>
            Please define your audience. Descriptions of each field are available on interaction.
          </p>
        </>
      );
  }
};
