import React, { FormEvent, useState } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Close from '@material-ui/icons/Close';
import Error from '@material-ui/icons/Error';
import ExitToApp from '@material-ui/icons/ExitToApp';

import { AuthState, login } from '../../lib/authAzure';

import Logo from '../Assets/Images/Logo';

import { StyledPaper } from '../../styles/MaterialComponents';
import { SignInStyles } from './SignIn.styles';
import Logout from './Logout';
import O2Logo from '../Assets/Images/O2Logo';
import TheLabLogo from '../Assets/Images/TheLabLogo';
import { InputAdornment, Typography } from '@material-ui/core';
import { HomeButton } from '../Shared/HomeButton';

interface SignInProps {
  onSignIn: (authState: AuthState) => void;
}

export const SignIn = (props: SignInProps) => {
  const [error, setError] = useState<string | undefined>();

  const handleSignIn = async (e: FormEvent) => {
    e.preventDefault();

    const result = await login();

    switch (result.type) {
      case 'ERROR':
        setError(result.message);
        break;
      case 'SUCCESS':
        props.onSignIn({ isAuthenticated: true, username: result.user });
        break;
    }
  };

  return (
    <SignInStyles className="motion-bg">
      <HomeButton />
      <div className="content">
        <div className="headings">
          <Logo size="lg" align="center" />
        </div>
        <StyledPaper className="paper">
          <form onSubmit={handleSignIn}>
            <div className="intro">
              <Typography>
                By signing into this system you are agreeing to the{' '}
                <a href="https://www.o2.co.uk/termsandconditions" target="_blank" rel="noreferrer">
                  terms &amp; conditions
                </a>{' '}
                and{' '}
                <a
                  href="https://www.o2.co.uk/termsandconditions/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </a>
                .
              </Typography>
            </div>
            <div className="buttons">
              <Button variant="contained" color="primary" type="submit" size="large">
                Agree &amp; Sign In
                <InputAdornment position="end">
                  <ExitToApp className="buttonIcon" />
                </InputAdornment>
              </Button>
            </div>
          </form>
        </StyledPaper>
      </div>
      <div className="login">
        <O2Logo />
        <Logout />
        <TheLabLogo />
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={error !== undefined}
        autoHideDuration={6000}
        onClose={() => setError(undefined)}
      >
        <SnackbarContent
          className="errorSnack"
          message={
            <span className="message">
              <Error className="icon" />
              <span>{error}</span>
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={() => setError(undefined)}
            >
              <Close />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </SignInStyles>
  );
};
