import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

import { defaultAudienceFilter } from '../../../lib/filter';

import { AudienceFilter as AudienceFilterState } from '../../../types/smartStepsApi';

import { StyledDialogActions, StyledDialogContent } from '../../../styles/MaterialComponents';

import { AudienceFields } from './Fields';

// Types
interface Props {
  audienceFilter: AudienceFilterState;
  onCancel: () => void;
  onSubmit: (audienceFilter: AudienceFilterState) => void;
}

const Modal: React.FC<Props> = ({ audienceFilter, onSubmit, onCancel }: Props) => {
  const [audience, setAudience] = useState(audienceFilter);

  const handleDefault = () => {
    // Reset audience to default
    setAudience(defaultAudienceFilter);
  };

  const handleSubmit = () => {
    onSubmit(audience);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={true}
      onClose={() => onCancel()}
      fullScreen={fullScreen}
      maxWidth="xs"
      className="dialog"
    >
      <StyledDialogContent className="overrideScroll">
        <AudienceFields audienceFilter={audience} includeHelp={false} onChange={setAudience} />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleDefault} color="secondary">
          Default
        </Button>
        <div className="groupedButtons">
          <Button onClick={() => onCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Apply
          </Button>
        </div>
      </StyledDialogActions>
    </Dialog>
  );
};

export default Modal;
