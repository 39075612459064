import styled from 'styled-components';

export default styled.div`
  height: 100%;

  > .paper {
    height: 100%;

    display: flex;
    flex-direction: column;

    padding: 2rem;

    > h4 {
      flex: 0 0 auto;

      margin: 0 0 1rem;

      font-size: 1.4rem;
      line-height: 1.4rem;
      font-weight: bold;
      color: ${(props) => props.theme.palette.primary.main};
    }

    > .paper-content {
      flex: 1 1 auto;

      > .paper-loading {
        height: 100%;

        display: flex;

        align-items: center;
        justify-content: center;
      }
    }
  }
`;
