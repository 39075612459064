import React from 'react';

import { LogoProps } from '../../../types/global';

import LogoStyles from './Logo.styles';
import { navigate } from '@reach/router';

const Logo = (props: LogoProps) => (
  <LogoStyles {...props}>
    <p className="mini-logo" onClick={() => props.linkToHome && navigate('/')}>
      {props.viewStatus === 'mini' ? `AI` : `Audience Insights`}
    </p>
  </LogoStyles>
);

export default Logo;
