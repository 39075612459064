import { Arrays } from 'collection-fns';

import LocationsData from '../assets/data/postcode_sectors.json';
import { SelectOption } from '../types/audienceFilter';
import {
  AgeBand,
  FromHours,
  FromSingleHours,
  FromThreeHours,
  Gender,
  Genders,
  Hours,
  Interest,
  InterestLevel,
  QueryInterest,
  SpendingPower,
  ToHours,
  ToSingleHours,
  ToThreeHours,
  VisitType,
} from '../types/smartStepsApi';
// eslint-disable-next-line
import interestV2dot1 from './interest-v2.1.json';
/*
  ----- FORM -----
*/

export const CLIENT: string[] = [
  'graham.ross@telefonica.com',
  'Phil.schulze@cityco.com',
  'Vaughan.Allen@cityco.com',
  'Andrew.McDermott@cityco.com',
];

export const CURRENT_MONTH: string[] = ['2024-09-01', '2024-09-30'];
export const START_DATE: string = '2024-05-01';

export const TIMES_FROM: SelectOption<FromHours>[] = [
  { value: 0, label: '00:00' },
  { value: 1, label: '01:00' },
  { value: 2, label: '02:00' },
  { value: 3, label: '03:00' },
  { value: 4, label: '04:00' },
  { value: 5, label: '05:00' },
  { value: 6, label: '06:00' },
  { value: 7, label: '07:00' },
  { value: 8, label: '08:00' },
  { value: 9, label: '09:00' },
  { value: 10, label: '10:00' },
  { value: 11, label: '11:00' },
  { value: 12, label: '12:00' },
  { value: 13, label: '13:00' },
  { value: 14, label: '14:00' },
  { value: 15, label: '15:00' },
  { value: 16, label: '16:00' },
  { value: 17, label: '17:00' },
  { value: 18, label: '18:00' },
  { value: 19, label: '19:00' },
  { value: 20, label: '20:00' },
  { value: 21, label: '21:00' },
  { value: 22, label: '22:00' },
  { value: 23, label: '23:00' },
];

export const FROM_SINGLE_HOURS: FromSingleHours[] = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
];

export const FROM_THREE_HOURS: FromThreeHours[] = [0, 3, 6, 9, 12, 15, 18, 21];

const formatHour = (hour: number) => hour.toString().padStart(2, '0');
export const getTimeRanges = (hours: Hours) => {
  if (hours < 1) {
    throw new Error('Invalid hours: ' + hours);
  }

  return Arrays.init({ from: 0, to: 24 - hours, increment: hours }).map((startHour) => ({
    value: startHour,
    label: `${formatHour(startHour)}:00-${formatHour(startHour + hours)}:00`,
  }));
};

export const TIMES_TO: SelectOption<ToHours>[] = [
  { value: 1, label: '01:00' },
  { value: 2, label: '02:00' },
  { value: 3, label: '03:00' },
  { value: 4, label: '04:00' },
  { value: 5, label: '05:00' },
  { value: 6, label: '06:00' },
  { value: 7, label: '07:00' },
  { value: 8, label: '08:00' },
  { value: 9, label: '09:00' },
  { value: 10, label: '10:00' },
  { value: 11, label: '11:00' },
  { value: 12, label: '12:00' },
  { value: 13, label: '13:00' },
  { value: 14, label: '14:00' },
  { value: 15, label: '15:00' },
  { value: 16, label: '16:00' },
  { value: 17, label: '17:00' },
  { value: 18, label: '18:00' },
  { value: 19, label: '19:00' },
  { value: 20, label: '20:00' },
  { value: 21, label: '21:00' },
  { value: 22, label: '22:00' },
  { value: 23, label: '23:00' },
  { value: 24, label: '24:00' },
];

export const TO_SINGLE_HOURS: ToSingleHours[] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
];

export const TO_THREE_HOURS: ToThreeHours[] = [6, 9, 12, 15, 18, 21, 24];

export const LOCATIONS: SelectOption<string>[] = LocationsData;

export const demoUserLocationPrefixes = ['CF', 'LD', 'LL', 'NP', 'SA', 'SY'];

export const VISIT_TYPES: SelectOption<VisitType>[] = [
  { value: 'resident', label: 'Resident' },
  { value: 'visitor', label: 'Visitor' },
  { value: 'worker', label: 'Worker' },
  { value: 'all', label: 'All' },
];

function genderLabel(gender: Gender): string {
  switch (gender) {
    case '*':
      return 'All';
    case 'm':
      return 'Male';
    case 'f':
      return 'Female';
  }
}
export const GENDERS: SelectOption<Gender>[] = Genders.map((gender) => ({
  value: gender,
  label: genderLabel(gender),
}));

export const AGE_BANDS: SelectOption<AgeBand>[] = [
  { value: '1824', label: '18-24' },
  { value: '2534', label: '25-34' },
  { value: '3544', label: '35-44' },
  { value: '4554', label: '45-54' },
  { value: '5564', label: '55-64' },
  { value: '6599', label: '65+' },
];

export const SPENDING_POWERS: SelectOption<SpendingPower>[] = [
  { value: '0019', label: 'V. Low' },
  { value: '2039', label: 'Low' },
  { value: '4059', label: 'Medium' },
  { value: '6079', label: 'High' },
  { value: '8099', label: 'V. High' },
];

export const QUERYS: SelectOption<QueryInterest>[] = [
  { value: 'AND', label: 'AND' },
  { value: 'OR', label: 'OR' },
];

// export const QUERYS: SelectOption<QueryInterest>[] = QueryInterests.map((queryInterests) => ({
//   value: queryInterests,
//   label: genderLabel(queryInterests),
// }));

export const INTERESTS: SelectOption<Interest>[] = [
  // { value: 'Advertising', label: 'Advertising' },
  { value: 'Arts_and_Entertainment', label: 'Arts and Entertainment' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Beginning_Investing', label: 'Beginning Investing' },
  { value: 'Business', label: 'Business' },
  { value: 'Career_and_Education', label: 'Career and Education' },
  // { value: 'Competitors', label: 'Competitors' },
  { value: 'Family_and_Parenting', label: 'Family and Parenting' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Food_and_Drink', label: 'Food and Drink' },
  { value: 'Games', label: 'Games' },
  { value: 'Health', label: 'Health' },
  { value: 'Hobbies_and_Interests', label: 'Hobbies and Interests' },
  { value: 'Home_and_Garden', label: 'Home and Garden' },
  { value: 'Internet_Services', label: 'Internet Services' },
  { value: 'Kids_and_Teens', label: 'Kids and Teens' },
  { value: 'Law_Government_and_Politics', label: 'Law Government and Politics' },
  { value: 'News', label: 'News' },
  { value: 'Online_Communities', label: 'Online Communities' },
  { value: 'Pets', label: 'Pets' },
  { value: 'Real_Estate', label: 'Real Estate' },
  { value: 'Reference', label: 'Reference' },
  { value: 'Religion_and_Spirituality', label: 'Religion and Spirituality' },
  { value: 'Science', label: 'Science' },
  { value: 'Searching', label: 'Searching' },
  { value: 'Shopping', label: 'Shopping' },
  { value: 'Society', label: 'Society' },
  { value: 'Sports', label: 'Sports' },
  { value: 'Style_and_Fashion', label: 'Style and Fashion' },
  { value: 'Technology_and_Computing', label: 'Technology and Computing' },
  { value: 'Telecommunications', label: 'Telecommunications' },
  { value: 'Travel', label: 'Travel' },
];

export const interestsValueToLabel = INTERESTS.reduce((a, v) => {
  a[v.value] = v.label;
  return a;
}, {} as Record<string, string>);

const interestV2 = interestV2dot1.reduce((a, v) => {
  const category = v.split('__')[0];
  const subInterest = (a[category] = a[category] || []);
  subInterest.push(v);
  return a;
}, {} as Record<string, string[]>);
export const SUB_INTERESTS: Map<Interest, SelectOption<string>[]> = Object.keys(interestV2).reduce(
  (a, category) => {
    const subCategories = interestV2[category] as string[];
    const mapValue = subCategories.map((s) => ({
      value: s,
      label: s.split('__')[1].replace(/_/g, ' '),
    }));
    a.set(category as Interest, mapValue);
    return a;
  },
  new Map() as Map<Interest, SelectOption<string>[]>
);

export const subInterestValueToLabel = Array.from(SUB_INTERESTS.values())
  .flat()
  .reduce((a, v) => {
    a[v.value] = v.label;
    return a;
  }, {} as Record<string, string>);

export const subInterestsValueSet = Object.keys(interestV2).reduce((a, category) => {
  a.set(category as Interest, new Set(interestV2[category]));
  return a;
}, new Map() as Map<Interest, Set<string>>);

export const INTEREST_LEVELS: SelectOption<InterestLevel>[] = [
  { value: 'l', label: 'Low' },
  { value: 'm', label: 'Medium' },
  { value: 'h', label: 'High' },
];
