import React from 'react';

import PaperWithHeading from '../../Assets/Layout/PaperWithHeading';

import { ApiProfilesDataState } from '../../../types/apiData';
import { ProfilesFilter } from '../../../types/smartStepsApi';
import { capitaliseText } from '../../../lib/helpers';

import TotalAndVisitTypeStyles from './TotalAndVisitType.styles';

type Props = {
  isLoading: boolean;
  count: ApiProfilesDataState['count'];
  visitType: ProfilesFilter['visitType'] | undefined;
};

const TotalAndVisitType: React.FC<Props> = (props) => (
  <PaperWithHeading isLoading={props.isLoading} label="People">
    <TotalAndVisitTypeStyles>
      <h5>{props.count.toLocaleString()}</h5>
      <h6>{props.visitType ? `${capitaliseText(props.visitType)}s` : '-'}</h6>
    </TotalAndVisitTypeStyles>
  </PaperWithHeading>
);

export default TotalAndVisitType;
