import React from 'react';
import { TableCellProps, TableCellRenderer } from 'react-virtualized';
import { Room } from '@material-ui/icons';
import { TableCell } from '@material-ui/core';
import { ColumnDef } from '../../../../types/Catchment';

interface DataGridCellProps extends TableCellProps {
  columns: ColumnDef[];
  selected: string[];
}

export const DataGridCell = ({
  cellData,
  rowData,
  dataKey,
  columns,
  selected,
}: DataGridCellProps): TableCellRenderer => {
  // Find column
  const column = columns.find((c) => c.dataKey === dataKey);
  if (!column) {
    return (null as unknown) as TableCellRenderer;
  }

  const { align, formatter } = column;

  // Set selected
  const isSelected = selected.indexOf(rowData.origin) !== -1;

  // Build classes
  let classes = 'cell';

  if (isSelected) {
    classes += ' selected';
  }

  // Format value
  let value = cellData;

  if (value) {
    // Format text
    if (formatter !== undefined) {
      value = formatter(value);
    }

    // Truncate long text
    if (value.length > 57) {
      value = <abbr title={value}>{value.substring(0, 57)}...</abbr>;
    }
  } else if (dataKey === 'selected') {
    value = <Room fontSize="small" className="marker" />;
  }

  // Render
  return ((
    <TableCell component="div" variant="body" className={classes} align={align ? align : 'left'}>
      {value}
    </TableCell>
  ) as unknown) as TableCellRenderer;
};
