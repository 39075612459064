import React from 'react';
import { HelpView } from './HelpView';

type CatchmentHelpProps = {
  display?: HelpView;
};

export const CatchmentHelp: React.FC<CatchmentHelpProps> = ({ display }) => {
  return display === 'locations' ? (
    <>
      <h3>Locations</h3>
      <p>
        Generates the Audience Profiling based on the Postcode sectors selected. Currently limited
        to 10 Postcodes sectors at any time
      </p>
    </>
  ) : (
    <>
      <h3>Welcome to Audience Insights!</h3>
      <p>
        This is a visualization tool for the Audience Insights API, developed for media agencies to
        plan marketing campaigns based on audience profiles.
      </p>
      <p>Please define your location.</p>
    </>
  );
};
