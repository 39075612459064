import React from 'react';
import { TableCell, TableSortLabel } from '@material-ui/core';
import { TableHeaderProps } from 'react-virtualized';
import { CatchmentDataColumn, CatchmentGridSort, ColumnDef } from '../../../../types/Catchment';

interface DataGridHeaderProps extends TableHeaderProps {
  columns: ColumnDef[];
  sorting: CatchmentGridSort;
  setSorting: React.Dispatch<React.SetStateAction<CatchmentGridSort>>;
}

export const DataGridHeader: React.FC<DataGridHeaderProps> = ({
  dataKey,
  label,
  columns,
  sorting,
  setSorting,
}) => {
  // Find column
  const column = columns.find((c) => c.dataKey === dataKey);

  if (!column) {
    return null;
  }

  // Set sorting
  const isSortedColumn = sorting.field === dataKey;

  const changeSort = (dataKey: CatchmentDataColumn) => {
    const isDesc = sorting.field === dataKey && sorting.direction === 'desc';

    setSorting({
      field: dataKey,
      direction: isDesc ? 'asc' : 'desc',
    });
  };

  // Render
  return (
    <TableCell
      component="div"
      variant="head"
      className="cell"
      align={column.align ? column.align : 'left'}
      sortDirection={isSortedColumn ? sorting.direction : false}
    >
      <TableSortLabel
        active={isSortedColumn}
        direction={sorting.direction}
        onClick={() => changeSort(column.dataKey)}
      >
        {label}
        {isSortedColumn ? (
          <span className="sortHidden">
            {sorting.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};
