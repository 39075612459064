export const capitaliseText = (text: string) => {
  const words = text.split(' ');

  for (let i = 0; i < words.length; i += 1) {
    const letters = words[i].split('');

    letters[0] = letters[0].toUpperCase();
    words[i] = letters.join('');
  }

  return words.join(' ');
};
