import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { IconButton } from '@material-ui/core';
import { Edit, NavigateBefore, NavigateNext } from '@material-ui/icons';
import { CatchmentFilter, DateRange } from '../../../../types/smartStepsApi';

import { DateTimePanelStyles } from './DateTimePanel.styles';
import { addDays, format } from 'date-fns';
import { AuthContext } from '../../../Auth/AuthProvider';
import { IntervalModalPanel } from './IntervalModalPanel';
import { START_DATE, CURRENT_MONTH } from '../../../../constants/filter';

const demoIntervals: DateRange[] = [
  // demo interval for catchment dashboard
  { startDate: new Date('2023-04-04'), endDate: new Date('2023-04-04') },
];

// need to replicate logic from DateTimeSummary.tx in src/components/Shared if I want to have
// different intervals for different clients
const authorizedIntervals: DateRange[] = [
  { startDate: new Date(START_DATE), endDate: new Date(CURRENT_MONTH[1]) },
];
const getInterval = (authorized?: boolean) => (authorized ? authorizedIntervals : demoIntervals);

interface DateTimePanelProps {
  dataFilter: CatchmentFilter;
  onSubmit: (day: Date) => void;
}

export const DateTimePanel: React.FC<DateTimePanelProps> = ({ dataFilter, onSubmit }) => {
  const [day, setDay] = useState(dataFilter.day);
  const [showModal, setShowModal] = useState(false);
  const { state: authState } = useContext(AuthContext);
  const intervals = useMemo(() => getInterval(authState?.isAuthorized), []);

  const onSubmitDebounced = useRef(debounce(onSubmit, 500));
  const isInsideDateRange = (range: DateRange, date: Date) =>
    range.startDate <= date && range.endDate >= date;

  const findRangeIndex = (ranges: DateRange[], date: Date) =>
    ranges.findIndex((v) => isInsideDateRange(v, date));

  const getNextDate = (ranges: DateRange[], date: Date) =>
    findRangeIndex(ranges, addDays(date, 1)) === -1;

  const getPrevDate = (ranges: DateRange[], date: Date) =>
    findRangeIndex(ranges, addDays(date, -1)) === -1;

  useEffect(() => {
    setDay(dataFilter.day);
  }, [dataFilter.day]);

  const navigate = (amount: number) => {
    setDay((currentDate) => {
      const newDate: Date = addDays(currentDate, amount);
      onSubmitDebounced.current(newDate);
      return newDate;
    });
  };

  const handlePrev = () => navigate(-1);
  const handleNext = () => navigate(1);

  return (
    <DateTimePanelStyles>
      <header>
        <div className="left">
          <h3>Date</h3>
        </div>
        <div className="right">
          <IconButton
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={() => setShowModal(true)}
          >
            <Edit />
          </IconButton>
        </div>
      </header>
      <div className="date">
        <div className="range">
          <IconButton
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={handlePrev}
            disabled={getPrevDate(intervals, day)}
          >
            <NavigateBefore fontSize="large" />
          </IconButton>
        </div>

        <div className="content">
          <span className="date-from">
            <abbr title={day.toUTCString()}>{format(day, 'd MMM y')}</abbr>
          </span>
        </div>

        <div className="range">
          <IconButton
            color="secondary"
            aria-label="edit"
            size="small"
            onClick={handleNext}
            disabled={getNextDate(intervals, day)}
          >
            <NavigateNext fontSize="large" />
          </IconButton>
        </div>
      </div>
      {showModal ? (
        <IntervalModalPanel
          dataFilter={dataFilter}
          allowedDate={intervals}
          onCancel={() => setShowModal(false)}
          onSubmit={(date) => {
            setShowModal(false);
            onSubmit(date);
          }}
          findRangeIndex={findRangeIndex}
        />
      ) : null}
    </DateTimePanelStyles>
  );
};
