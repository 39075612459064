import React, { useState, FormEvent } from 'react';
import Clear from '@material-ui/icons/Clear';
import Drawer from '@material-ui/core/Drawer';

import CustomButton from '../../Assets/Form/Button';
import { ProfilesFields } from './Fields';
import ProfilesHelp, { HelpView } from './Help';

import { ProfilesFilter as ProfilesFilterState } from '../../../types/smartStepsApi';
import DrawerStyles from '../../../styles/Drawer.styles';

import ProfilesFilterStyles from './Filter.styles';
import CustomSnackbar from '../../Assets/Form/CustomSnackbar';

// Types
interface Props {
  profilesFilter: ProfilesFilterState;
  onSubmit: (profilesFilter: ProfilesFilterState) => void;
}

const ProfilesFilter: React.FC<Props> = ({ profilesFilter, onSubmit }: Props) => {
  // Local State
  const [profiles, setProfiles] = useState(profilesFilter);
  const [mouseHover, setMouseHover] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [helpView, setHelpView] = useState<HelpView | undefined>(undefined);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Handlers
  const submitForm = (e: FormEvent) => {
    e.preventDefault();

    //Check if at lease 1 location is selected
    if (!profiles.locations.length) {
      setSnackbarOpen(true);
    } else {
      // Continue to parent
      onSubmit(profiles);
    }
  };

  const toggleDrawer = (open: boolean, event?: React.KeyboardEvent | React.MouseEvent) => {
    // Copied accessibility example from Material-UI
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  // Render
  return (
    <ProfilesFilterStyles>
      <CustomSnackbar snackbarOpen={snackbarOpen} setSnackbarOpen={(r) => setSnackbarOpen(r)} />
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={(e: React.KeyboardEvent | React.MouseEvent) => toggleDrawer(false, e)}
        onMouseEnter={() => {
          // Resetting helpView to combat onMouseLeave
          setHelpView(helpView);
        }}
      >
        <DrawerStyles>
          <Clear onClick={(e) => toggleDrawer(false, e)} />
          <ProfilesHelp display={helpView} />
        </DrawerStyles>
      </Drawer>

      <form onSubmit={submitForm}>
        <div
          onMouseEnter={() => setMouseHover(true)}
          onMouseLeave={() => {
            setMouseHover(false);
            setHelpView(undefined);
          }}
        >
          <ProfilesFields
            profilesFilter={profiles}
            includeHelp={true}
            onChange={setProfiles}
            toggleDrawer={toggleDrawer}
            helpView={helpView}
            setHelpView={setHelpView}
          />
        </div>
        <div className="submitButton">
          <CustomButton text="Results" active={mouseHover ? 'on' : 'off'} />
        </div>
      </form>
    </ProfilesFilterStyles>
  );
};

export default ProfilesFilter;
