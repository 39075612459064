import styled from 'styled-components';

const Portrait = styled.section`
  ${(props) => props.theme.breakpoints.up('md')} {
    display: none;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    position: fixed;

    width: 100%;
    height: 100%;

    z-index: 5000;

    background: linear-gradient(180deg, #0019a5 0%, #0090d0 70%, #41b6e6 100%);
    background-size: cover;
    background-attachment: fixed;

    padding: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    text-align: center;

    > * {
      flex: 0 0 auto;

      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    opacity: 0;

    pointer-events: none;

    @media screen and (orientation: portrait) {
      opacity: 1;

      pointer-events: auto;
    }
  }
`;

export default Portrait;
