import { numberToLocale } from '../../../../lib/gridResults';
import { ColumnDef, ColumnWidths } from '../../../../types/Catchment';

export const getColumnDef = (
  columnWidths: ColumnWidths,
  columnSelected: ColumnDef
): ColumnDef[] => {
  return [
    {
      dataKey: 'origin',
      label: 'Origin',
      align: 'right',
      width: columnWidths.origin,
    },
    {
      dataKey: 'origin_type',
      label: 'Type',
      align: 'right',
      width: columnWidths.type,
    },
    {
      dataKey: 'num_visits',
      label: 'Visits',
      align: 'right',
      formatter: numberToLocale,
      width: columnWidths.visits,
    },
    columnSelected,
  ];
};
