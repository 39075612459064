import * as msal from '@azure/msal-browser';
import { getConfig } from './config';

const config = getConfig();

const msalConfig: msal.Configuration = {
  auth: {
    clientId: config.REACT_APP_MSAL_CLIENT_ID,
    authority: config.REACT_APP_MSAL_AUTHORITY,
    knownAuthorities: [config.REACT_APP_MSAL_OPENID_CONFIGURATION],
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const LOCAL_STORAGE_USERNAME_KEY = 'media-planning-dashboard:username';

export interface Authorised {
  isAuthenticated: boolean;
  username?: string;
  isAuthorized?: boolean;
}

export type AuthState = Authorised;

async function tryGetCurrentSession() {
  try {
    const redirectResult = await msalInstance.handleRedirectPromise();
    if (redirectResult) {
      return redirectResult;
    }
    const account = await msalInstance.getAllAccounts()[0];
    return await msalInstance.acquireTokenSilent({
      scopes: [config.REACT_APP_MSAL_SCOPE],
      account,
    });
  } catch (err) {
    if (err instanceof msal.AuthError) {
      if (err.errorMessage.startsWith('AADB2C90118:')) {
        const passwordResetUrl = new URL(config.REACT_APP_MSAL_PASSWORD_RESET_FLOW);
        passwordResetUrl.searchParams.set('redirect_uri', window.location.origin);
        window.location.assign(passwordResetUrl.href);
        return undefined;
      }
    }
    console.log('tryGetCurrentSession failed with', err);
    return undefined;
  }
}
async function isUserAuthorized() {
  const jwtToken = await getJwtToken();
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: jwtToken,
  };
  return (await fetch(process.env.REACT_APP_API_BASE + 'access', { headers }).then((d) => d.json()))
    .hasAccess;
}

export const getAuthState = async (): Promise<AuthState> => {
  const session = await tryGetCurrentSession();
  if (session === undefined) {
    return { isAuthenticated: false };
  }
  const isAuthorized = await isUserAuthorized();
  return {
    isAuthenticated: true,
    username: session.account.username,
    isAuthorized,
  };
};

export const getJwtToken = async () => {
  const account = await msalInstance.getAllAccounts()[0];
  const accessTokenReq = await msalInstance.acquireTokenSilent({
    scopes: [config.REACT_APP_MSAL_SCOPE],
    account,
  });
  if (accessTokenReq.accessToken !== '') {
    return 'Bearer ' + accessTokenReq.accessToken;
  }

  await msalInstance.acquireTokenRedirect({
    scopes: [config.REACT_APP_MSAL_SCOPE],
    account,
  });

  throw new Error('Unreachable - we should now have navigated away');
};

export interface LoginArgs {
  username: string;
  password: string;
}

export type LoginResult =
  | { type: 'ERROR'; message: string; originalError: Error }
  | { type: 'CANCELLED' }
  | { type: 'NEW_PASSWORD_REQUIRED'; user: any }
  | { type: 'SUCCESS'; user: any };

export const login = async (): Promise<LoginResult> => {
  try {
    await msalInstance.loginRedirect({
      scopes: [config.REACT_APP_MSAL_SCOPE],
      loginHint: localStorage.getItem(LOCAL_STORAGE_USERNAME_KEY) ?? undefined,
    });
    throw new Error('Unreachable');
  } catch (err) {
    if (err instanceof msal.AuthError) {
      if (err.errorCode === 'user_cancelled') {
        return {
          type: 'CANCELLED',
        };
      }
      if (err.errorMessage.startsWith('AADB2C90118:')) {
        const passwordResetUrl = new URL(config.REACT_APP_MSAL_PASSWORD_RESET_FLOW);
        passwordResetUrl.searchParams.set('redirect_uri', window.location.origin);
        window.location.assign(passwordResetUrl.href);
        return { type: 'CANCELLED' };
      }
    }
    return {
      type: 'ERROR',
      message: err.message,
      originalError: err,
    };
  }
};

export const signOut = async () => {
  localStorage.removeItem(LOCAL_STORAGE_USERNAME_KEY);
  await msalInstance.logout({ postLogoutRedirectUri: window.location.origin });
};
