import styled, { css } from 'styled-components';

import { BASE_FONT_SIZE } from '../../../constants/theme';

const FILTER_HEIGHT = 15;

type Props = {
  includeFilter: boolean;
  containerHeight: number | undefined;
};

export default styled.div<Props>`
  display: flex;

  flex-direction: column;
  justify-content: center;

  height: 100%;

  ${(props) =>
    props.includeFilter &&
    css`
      > .filter {
        display: flex;

        justify-content: flex-end;

        height: ${FILTER_HEIGHT / BASE_FONT_SIZE}rem;

        > button {
          flex: 0 0 auto;

          font-size: 1.2rem;
          color: ${(props) => props.theme.palette.secondary.main};

          &.active {
            font-weight: bold;
          }

          &:not(:first-of-type) {
            margin-left: 0.8rem;
            padding-left: 0.8rem;

            border-left: 1px solid ${(props) => props.theme.palette.text.secondary};
          }
        }
      }
    `}
`;
