import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { CatchmentFilter } from './Filter';
import Logo from '../Assets/Images/Logo.styles';
import O2Logo from '../Assets/Images/O2Logo.styles';
import TheLabLogo from '../Assets/Images/TheLabLogo.styles';
import Logout from '../Auth/Logout.styles';

import { defaultCatchmentFilter } from '../../lib/filter';
import { CatchmentFilter as CatchmentFilterState } from '../../types/smartStepsApi';

import { CatchmentStyles } from './Catchment.styles';
import { withTracking } from '../../lib/insights';
import { HomeButton } from '../Shared/HomeButton';

const Catchment: React.FC<RouteComponentProps> = () => {
  // Handlers
  const handleSubmit = async (catchmentFilter: CatchmentFilterState) => {
    // Proceed to dashboard
    await navigate('/catchment/dashboard', { state: catchmentFilter });
  };

  return (
    <CatchmentStyles className="motion-bg">
      <HomeButton />
      <div className="content">
        <div className="headings">
          <div className="back-button">
            <IconButton color="inherit" onClick={async () => await navigate('/')}>
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </div>

          <div className="logo">
            <Logo size="lg" align="center" />
          </div>
        </div>

        <CatchmentFilter catchmentFilter={defaultCatchmentFilter} onSubmit={handleSubmit} />
      </div>
      <div className="login">
        <O2Logo />
        <Logout />
        <TheLabLogo />
      </div>
    </CatchmentStyles>
  );
};

export default withTracking(Catchment);
