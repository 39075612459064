import styled, { css } from 'styled-components';

import { LogoProps } from '../../../types/global';

const Logo = styled.section<LogoProps>`
  flex: 0 0 auto;
  display: flex;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex: 1 1 100%;
  }

  ${(props) =>
    props.viewStatus === 'mini' &&
    css`
      justify-content: center;
    `};

  > p {
    color: #ffffff;
    letter-spacing: -0.1rem;

    ${(props) =>
      props.linkToHome &&
      css`
        cursor: pointer;
      `}

    ${(props) =>
      props.size === 'lg' &&
      css`
        font-size: 6rem;
        line-height: 6rem;
      `};

    ${(props) =>
      props.size === 'md' &&
      css`
        font-size: 3.6rem;
        line-height: 3.6rem;
      `};

    ${(props) =>
      props.size === 'sm' &&
      css`
        font-size: 2.5rem;
        line-height: 2.5rem;
      `};

    ${(props) =>
      props.align === 'left' &&
      css`
        justify-content: left;
      `};
    ${(props) =>
      props.align === 'center' &&
      css`
        justify-content: center;
      `};
    ${(props) =>
      props.align === 'right' &&
      css`
        justify-content: right;
      `};

    font-weight: 500;
    margin: 0;
  }
`;
export default Logo;
