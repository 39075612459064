import format from 'date-fns/format';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
//import subYears from 'date-fns/subYears';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import addMonths from 'date-fns/addMonths';
import differenceInDays from 'date-fns/differenceInDays';
import isLastDayOfMonth from 'date-fns/isLastDayOfMonth';

import {
  AGE_BANDS,
  GENDERS,
  interestsValueToLabel,
  SPENDING_POWERS,
  SUB_INTERESTS,
  VISIT_TYPES,
  CLIENT,
  CURRENT_MONTH,
} from '../constants/filter';
import { SelectOption } from '../types/audienceFilter';
import {
  AgeBand,
  AudienceFilter,
  CatchmentFilter,
  DateRange,
  FromHours,
  FromSingleHours,
  FromThreeHours,
  Gender,
  Interest,
  ProfilesFilter,
  QueryInterest,
  SpendingPower,
  TimeOfDay,
  ToHours,
  VisitType,
} from '../types/smartStepsApi';

/*
  ----- DISPLAY -----
*/

export const visitTypeLabel = (visitType: VisitType | undefined) => {
  if (!visitType) {
    return 'NO VALUE';
  }

  const dataVisitType = VISIT_TYPES.find((vt) => vt.value === visitType);

  if (!dataVisitType) {
    return 'NOT FOUND';
  }

  return dataVisitType.label;
};

export const genderLabel = (gender: Gender) => {
  const dataGender = GENDERS.find((g) => g.value === gender);

  if (!dataGender) {
    return 'NOT FOUND';
  }

  return dataGender.label;
};

export const ageBandLabel = (ageBand: AgeBand) => {
  const dataAgeBand = AGE_BANDS.find((ab) => ab.value === ageBand);

  if (!dataAgeBand) {
    return 'NOT FOUND';
  }

  return dataAgeBand.label;
};

export const spendingPowerLabel = (spendingPower: SpendingPower) => {
  const dataSpendingPower = SPENDING_POWERS.find((sp) => sp.value === spendingPower);

  if (!dataSpendingPower) {
    return 'NOT FOUND';
  }

  return dataSpendingPower.label;
};

export const interestLabel = (interest: Interest) => {
  const dataInterestLabel = interestsValueToLabel[interest];

  if (!dataInterestLabel) {
    return 'NOT FOUND';
  }

  return dataInterestLabel;
};

const subInterestToLabel = [...(SUB_INTERESTS as any).values()].flat().reduce((a, v) => {
  a[v.value] = v.label;
  return a;
}, {} as Record<string, string>);

export const subInterestLabel = (subInterests: string): string => {
  const dataInterest = subInterestToLabel[subInterests];

  if (!dataInterest) {
    return 'NOT FOUND';
  }

  return dataInterest;
};

export function getAvailableSubInterests(interests: Interest[]): SelectOption<string>[] {
  return interests.map((i) => SUB_INTERESTS.get(i)!).flat();
}

export function filterSubInterests(
  subInterests: string[],
  availableSubInterests: SelectOption<string>[]
): string[] {
  const possibleSubInterests = new Set(availableSubInterests.map((e) => e.value));
  const newSubInterests = subInterests.filter((s) => possibleSubInterests.has(s));
  return newSubInterests.length !== subInterests.length ? newSubInterests : subInterests;
}
/*
  ----- FORMATTERS -----
*/

export const calculateTimeTo = (timeOfDay: TimeOfDay): ToHours =>
  (timeOfDay.startHour + timeOfDay.hours) as ToHours;

export const formatTimeOfDay = (timeFrom: FromHours, timeTo: ToHours): TimeOfDay | undefined => {
  const hours = timeTo - timeFrom;

  // Over-convoluted validation to please TypeScript
  if (hours === 1) {
    return {
      startHour: timeFrom as FromSingleHours,
      hours,
    };
  }

  if (hours === 3 && timeFrom % 3 === 0) {
    return {
      startHour: timeFrom as FromThreeHours,
      hours: hours,
    };
  }

  if (timeFrom === 0 && hours === 24) {
    return {
      startHour: timeFrom,
      hours,
    };
  }

  return;
};

/*
  ----- DATES -----
*/

interface DateRangeLength {
  units: DateRangeUnits;
  quantity: number;
}

const monthDiff = (start: Date, end: Date) => {
  const endMonth = end.getFullYear() * 12 + end.getMonth();
  const startMonth = start.getFullYear() * 12 + start.getMonth();

  return endMonth - startMonth;
};

export const firstDayOfMonth = (date: Date) => new Date(format(date, 'yyyy-MM-01'));

export const defaultDates = (authorized: boolean | undefined, client?: string | undefined) => {
  if (!!authorized && client && CLIENT.includes(client)) {
    // Using optional chaining with client
    return {
      startDate: new Date(CURRENT_MONTH[0]),
      endDate: new Date(CURRENT_MONTH[1]),
    };
  } else if (!!authorized) {
    // Add the specific dates you want for authorized users that are not CLIENT.
    return {
      startDate: new Date(CURRENT_MONTH[0]), // Replace with the desired date
      endDate: new Date(CURRENT_MONTH[1]), // Replace with the desired date
    };
  } else {
    return {
      startDate: new Date('2023-04-04'),
      endDate: new Date('2023-04-04'),
    };
  }
};

export const defaultDate = (
  authorized: boolean | undefined // default date catchment
) => (authorized ? new Date(CURRENT_MONTH[1]) : new Date('2023-04-04'));

export type DateRangeUnits = 'months' | 'days';

export const printDateRangeLength = ({ quantity, units }: DateRangeLength) => {
  const pluralizedUnits = quantity === 1 ? units.substr(0, units.length - 1) : units;
  return `${quantity} ${pluralizedUnits}`;
};

export const getRangeLength = (dateRange: DateRange): DateRangeLength => {
  if (dateRange.startDate.getDate() === 1 && isLastDayOfMonth(dateRange.endDate)) {
    const quantity = monthDiff(dateRange.startDate, dateRange.endDate) + 1;

    return {
      units: 'months',
      quantity,
    };
  }

  return {
    units: 'days',
    quantity: differenceInDays(dateRange.endDate, dateRange.startDate) + 1,
  };
};

export const addToDateRange = (dateRange: DateRange, amount: number) => {
  const length = getRangeLength(dateRange);

  if (length.units === 'months') {
    const startDate = addMonths(dateRange.startDate, amount * length.quantity);
    const endDate =
      amount >= 0
        ? lastDayOfMonth(addMonths(startDate, length.quantity - 1))
        : lastDayOfMonth(addMonths(dateRange.startDate, amount));

    return { startDate, endDate };
  }

  return {
    startDate: addDays(dateRange.startDate, amount * length.quantity),
    endDate: addDays(dateRange.endDate, amount * length.quantity),
  };
};

export const isInsideDateRange = (
  parentDateRange: DateRange,
  dateRangeToCheck: DateRange
): boolean => {
  if (!dateRangeToCheck.endDate || !dateRangeToCheck.startDate) {
    return false;
  }

  const parentStartDate = startOfDay(parentDateRange.startDate);
  const parentEndDate = startOfDay(parentDateRange.endDate);
  const checkStartDate = startOfDay(dateRangeToCheck.startDate);
  const checkEndDate = startOfDay(dateRangeToCheck.endDate);

  return parentStartDate <= checkStartDate && parentEndDate >= checkEndDate;
};

export const findRangeIndex = (ranges: DateRange[], range: DateRange): number => {
  return ranges.findIndex((v) => isInsideDateRange(v, range));
};

export const getNextDateRange = (ranges: DateRange[], dateRange: DateRange) => {
  let nextRange: DateRange | null = addToDateRange(dateRange, 1);
  if (findRangeIndex(ranges, nextRange) !== -1) {
    return nextRange;
  } else {
    nextRange = null;
  }
  const length = getRangeLength(dateRange);
  const curIndex = findRangeIndex(ranges, dateRange);
  for (let i = curIndex + 1; i < ranges.length; i++) {
    const curRange = ranges[i];
    if (
      differenceInDays(curRange.endDate, curRange.startDate) >=
      differenceInDays(dateRange.endDate, dateRange.startDate)
    ) {
      return {
        startDate: curRange.startDate,
        endDate:
          length.units === 'months'
            ? lastDayOfMonth(addMonths(curRange.startDate, length.quantity - 1))
            : addDays(curRange.startDate, length.quantity),
      };
    }
  }
  return nextRange;
};

export const getPrevDateRange = (ranges: DateRange[], dateRange: DateRange) => {
  let nextRange: DateRange | null = addToDateRange(dateRange, -1);
  if (findRangeIndex(ranges, nextRange) !== -1) {
    return nextRange;
  } else {
    nextRange = null;
  }
  const length = getRangeLength(dateRange);
  const curIndex = findRangeIndex(ranges, dateRange);
  for (let i = curIndex - 1; i >= 0; i--) {
    const curRange = ranges[i];
    const curRangeSize = differenceInDays(curRange.endDate, curRange.startDate);
    const rangeSize = differenceInDays(dateRange.endDate, dateRange.startDate);
    if (curRangeSize >= rangeSize) {
      return {
        startDate:
          length.units === 'months'
            ? firstDayOfMonth(addMonths(curRange.endDate, -length.quantity + 1))
            : addDays(curRange.endDate, -length.quantity),
        endDate: curRange.endDate,
      };
    }
  }
  return nextRange;
};
/*
  ----- DEFAULTS -----
*/

export const defaultVisitType: VisitType = 'resident';

export const defaultQueryInterest: QueryInterest = 'AND';

export const defaultAudienceFilter: AudienceFilter = {
  visitType: defaultVisitType,
  gender: '*',
  ageBand: [],
  queryInterest: defaultQueryInterest,
  interests: [],
  spendingPower: [],
  subInterests: [],
};

export const defaultProfilesFilter: ProfilesFilter = {
  locations: [],
  visitType: defaultVisitType,
  gender: '*',
  ageBand: [],
  queryInterest: defaultQueryInterest,
  interests: [],
  subInterests: [],
  spendingPower: [],
};

export const defaultCatchmentFilter: CatchmentFilter = {
  day: new Date('2022-10-10'),
  locations: ['CF31 4', 'CF83 2', 'CF10 5', 'CF62 8'],
};
