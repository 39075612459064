import styled from 'styled-components';

const Logout = styled.section`
  p {
    display: inline;

    margin: 0 0.4rem 0 0;

    font-size: 1.2rem;
    color: #ffffff;
  }

  .link {
    display: inline;
    margin: 0 0.4rem 0 0;
    font-size: 1.2rem;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;

    &:not(:first-of-type) {
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: 1px solid rgb(255, 255, 255, 0.4);
    }
  }
`;

export default Logout;
