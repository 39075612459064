import styled from 'styled-components';

import { o2colorsGender } from '../../../styles/NivoTheme';

export default styled.div`
  height: 100%;

  padding-top: 3rem;

  > .gender {
    padding: 2rem;

    &.male {
      border-left: 1.5rem solid ${o2colorsGender['Male']};
    }

    &.female {
      &:not(:first-child) {
        margin-top: 4rem;
      }

      border-left: 1.5rem solid ${o2colorsGender['Female']};
    }

    > h5 {
      display: inline-block;

      margin: 0 0 2rem;

      font-size: 2rem;
      font-weight: 500;

      border-bottom: 1px solid #efefef;
    }

    > p {
      margin: 0;

      &.people {
        font-size: 3.4rem;
        line-height: 3.4rem;
        font-weight: 300;
      }

      &.people-label {
        margin-top: 0.2rem;

        text-transform: capitalize;
        font-size: 1.2rem;
        font-weight: 300;
      }

      &.percent {
        margin-top: 1.4rem;

        font-size: 2.4rem;
        font-weight: 500;
      }
    }
  }
`;
