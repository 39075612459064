import React from 'react';
import HelpOutline from '@material-ui/icons/HelpOutline';

import { DrawerProps } from '../../../types/global';

import InfoIconStyle from './InfoIcon.styles';

const InfoIcon = (props: DrawerProps) => (
  <div onClick={(e) => props.toggleDrawer(true, e)}>
    <InfoIconStyle>
      <HelpOutline />
    </InfoIconStyle>
  </div>
);

export default InfoIcon;
