import Link from '@material-ui/core/Link';
import styled from 'styled-components';

export const HomeButtonStyles = styled(Link)`
  opacity: 0.3;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  > svg {
    font-size: 4.5rem;
    color: white;
  }
`;
