import React, { useContext } from 'react';
import { RouteComponentProps, WindowLocation } from '@reach/router';

import { isCatchmentFilter } from '../../../types/smartStepsApi';
import { defaultCatchmentRefinement } from '../../../types/refinement';

import { defaultDate } from '../../../lib/filter';
import { withTracking } from '../../../lib/insights';
import { CatchmentQueryStringState, parseCatchmentQueryString } from '../../../lib/queryString';

import { Dashboard } from './Dashboard';
import { AuthContext } from '../../Auth/AuthProvider';

interface DashboardRouteProps extends RouteComponentProps {}

function getInitialState(
  location: WindowLocation | undefined,
  authorized: boolean | undefined
): CatchmentQueryStringState {
  if (location === undefined) {
    return parseCatchmentQueryString('', authorized);
  }

  if (isCatchmentFilter(location.state)) {
    return {
      query: {
        ...location.state,
        day: defaultDate(authorized),
      },
      refinement: defaultCatchmentRefinement,
    };
  }

  return parseCatchmentQueryString(location.search, authorized);
}

const DashboardRoute: React.FC<DashboardRouteProps> = ({ location }) => {
  const { state: authState } = useContext(AuthContext);
  const initialState = getInitialState(location, authState?.isAuthorized);

  return <Dashboard initialQuery={initialState.query} refinement={initialState.refinement} />;
};

export const CatchmentDashboard = withTracking(DashboardRoute);
