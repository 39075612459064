import styled from 'styled-components';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1 1 auto;
    text-transform: none;
  }

  &.heatmap {
    .MuiToggleButton-sizeSmall {
      height: 2.8rem;

      font-size: 1.1rem;
      text-transform: inherit;
    }

    .MuiToggleButtonGroup-grouped {
      border-radius: 0;
    }

    .Mui-selected {
      color: ${(props) => props.theme.palette.secondary.main};
      background-color: rgba(0, 0, 0, 0.05);
    }

    .MuiToggleButton-root:hover:not(.Mui-selected) {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }
`;
