import React, { useContext, useMemo, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleButton from '@material-ui/lab/ToggleButton';

import { AggregationLevel } from '../../../../lib/gridResults';
import { ApiCatchmentDataState } from '../../../../types/apiData';
import { ViewStatus } from '../../../../types/global';
import { HeatMapStyles } from './HeatMap.styles';
import { DEFAULT_BOUNDS, DEFAULT_DEMO_BOUNDS, MAX_BOUNDS } from './HeatMap.constant';
import { AuthContext } from '../../../Auth/AuthProvider';
import { HeatmapCatchmentFields } from '../../../../types/map';
import { StyledToggleButtonGroup } from '../../../Assets/Form/ToggleButtons.styles';
import { MapContainer } from 'react-leaflet';
import { HeatMapContent } from './HeatMap.content';

interface HeatMapProps {
  aggregationLevel: AggregationLevel;
  filter: string;
  apiData: ApiCatchmentDataState;
  selected: string[];
  setSelected: (value: string) => void;
  hovered: string | undefined;
  onMapSizeChange: React.MouseEventHandler<HTMLDivElement>;
  resize: boolean;
  viewStatus: ViewStatus;
}

export const HeatMap: React.FC<HeatMapProps> = ({
  aggregationLevel,
  filter,
  apiData,
  selected,
  setSelected,
  hovered,
  onMapSizeChange,
  resize,
  viewStatus,
}) => {
  const { state: authState } = useContext(AuthContext);
  const [heatmapOn, setHeatMapOn] = useState<boolean>(true);
  const [heatmapField, setHeatmapField] = useState<HeatmapCatchmentFields>('num_visits');
  const defaultBound = useMemo(
    () => (authState?.isAuthorized ? DEFAULT_BOUNDS : DEFAULT_DEMO_BOUNDS),
    []
  );

  return (
    <HeatMapStyles heatmapOn={heatmapOn}>
      <MapContainer
        fullscreenControl={true}
        bounds={defaultBound}
        maxBounds={MAX_BOUNDS}
        zoomSnap={0.25}
      >
        <HeatMapContent
          aggregationLevel={aggregationLevel}
          filter={filter}
          apiData={apiData}
          selected={selected}
          setSelected={setSelected}
          hovered={hovered}
          onMapSizeChange={onMapSizeChange}
          resize={resize}
          viewStatus={viewStatus}
          heatmapOn={heatmapOn}
          setHeatMapOn={setHeatMapOn}
          heatmapField={heatmapField}
        />
      </MapContainer>
      {heatmapOn && (
        <div className="heatmap-select">
          <StyledToggleButtonGroup
            exclusive
            size="small"
            value={heatmapField}
            onChange={(_event, value) => value && setHeatmapField(value)}
            className="heatmap"
          >
            <ToggleButton value="num_visits">Visits</ToggleButton>
          </StyledToggleButtonGroup>
        </div>
      )}
      {apiData.loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}
    </HeatMapStyles>
  );
};
