import React from 'react';

import telO2LogoNeg from '../../../assets/images/tel-o2-logo-neg.png';

import O2LogoStyles from './O2Logo.styles';

const O2Logo = () => (
  <O2LogoStyles className="o2logo-container">
    <img src={telO2LogoNeg} alt="combo-tel-o2-logo" className="combo-tel-o2-logo" />
  </O2LogoStyles>
);

export default O2Logo;
