import React, { useContext, useEffect, useRef, useState } from 'react';

import Info from '../../../Assets/Images/InfoIcon';
import { AuthContext } from '../../../Auth/AuthProvider';
import { demoUserLocationPrefixes, LOCATIONS } from '../../../../constants/filter';
import { CatchmentFilter as CatchmentFilterState } from '../../../../types/smartStepsApi';

import { HelpView } from '../Help/HelpView';
import { CatchmentFieldStyles } from './CatchmentField.styles';
import MultiSelect from '../../../Assets/Form/MultiSelect';
import { queryPostalApi } from '../../../../lib/postal';
import { SelectOption } from '../../../../types/audienceFilter';

type CatchmentFieldProps = {
  catchmentFilter: CatchmentFilterState;
  includeHelp: boolean;
  onChange: (catchmentFilter: CatchmentFilterState) => void;
  toggleDrawer?: (open: boolean, event?: React.KeyboardEvent | React.MouseEvent) => void;
  helpView?: HelpView;
  setHelpView?: (helpView: HelpView | undefined) => void;
};

export const CatchmentField: React.FC<CatchmentFieldProps> = ({
  catchmentFilter,
  includeHelp,
  onChange,
  toggleDrawer,
  helpView,
  setHelpView,
}) => {
  // Local State
  const [filteredLocations, setFilteredLocations] = useState<SelectOption<string>[]>(LOCATIONS);
  const [day, setDay] = useState<Date>(catchmentFilter.day);
  const [locations, setLocations] = useState<string[]>(catchmentFilter.locations);
  const [showError, setShowError] = useState<boolean>();
  const { state: authState } = useContext(AuthContext);

  // Refs
  const dayRef = useRef<HTMLDivElement>(null);
  const locationsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetch = async () => {
      if (authState?.username) {
        const postalResult = await queryPostalApi({ username: authState.username });
        if (postalResult.recordset.length > 0) {
          const filter = LOCATIONS.filter((item) =>
            postalResult.recordset.some(
              (permission) =>
                item.value.split(' ')[0].toLocaleLowerCase() ===
                permission.Postal.toLocaleLowerCase()
            )
          );

          setFilteredLocations(filter);
        }
      } else if (!authState?.isAuthorized) {
        const filter = LOCATIONS.filter((l) =>
          demoUserLocationPrefixes.some((p) => l.value.startsWith(p))
        );
        setFilteredLocations(filter);
      }
    };

    fetch().catch(console.error);
  }, [authState?.username]);

  useEffect(() => {
    setDay(catchmentFilter.day);
    setLocations(catchmentFilter.locations);
  }, [catchmentFilter]);

  useEffect(() => {
    if (locations.length) {
      setShowError(false);
    } else {
      setShowError(true);
    }

    onChange({ day, locations });
  }, [day, locations, onChange]);

  useEffect(() => {
    // Refs
    const dayRefLocal = dayRef.current;
    const locationsRefLocal = locationsRef.current;

    // Helpers
    const addListeners = (ref: HTMLDivElement | null, helpView: HelpView) => {
      if (ref && setHelpView) {
        ref.addEventListener('mouseenter', () => setHelpView(helpView));
        ref.addEventListener('focusin', () => setHelpView(helpView));
      }
    };

    const removeListeners = (ref: HTMLDivElement | null, helpView: HelpView) => {
      if (ref && setHelpView) {
        ref.removeEventListener('mouseenter', () => setHelpView(helpView));
        ref.removeEventListener('focusin', () => setHelpView(helpView));
      }
    };

    // Add help listeners if required
    if (includeHelp) {
      addListeners(dayRefLocal, 'day');
      addListeners(locationsRefLocal, 'locations');
    }

    // Cleanup to remove help listeners (if required)
    return () => {
      if (includeHelp) {
        removeListeners(dayRefLocal, 'day');
        removeListeners(locationsRefLocal, 'locations');
      }
    };
  }, [includeHelp, setHelpView]);

  return (
    <CatchmentFieldStyles focused={helpView === 'locations'} ref={locationsRef}>
      {toggleDrawer && <Info toggleDrawer={toggleDrawer} />}
      {!authState?.isAuthorized && (
        <div className="demo-user-note">
          You are in demo mode. Only locations in Wales region available.
        </div>
      )}
      <MultiSelect
        showError={showError}
        label="Locations"
        placeholder="Please select up to 10 locations"
        data={filteredLocations}
        selected={locations}
        onChange={(locations) => setLocations(locations)}
        position="bottom"
        limit={10}
      />
    </CatchmentFieldStyles>
  );
};
