import styled, { css } from 'styled-components';
import { ButtonProps } from '../../../types/global';

const StyledButton = styled.div<ButtonProps>`
  > button {
    background-color: #ffffff;
    min-width: 228px;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.42857143;
    border-radius: 5px;
    user-select: none;
    display: inline-block;
    padding: 6px 45px;

    > span {
      width: 100%;
      display: inline-block;
      position: relative;
      > .text {
        transition: 0.5s;
        text-transform: none;
        color: #032b5a;
      }
      > .arrow {
        position: absolute;
        opacity: 0;
        right: 4rem;
        transition: 0.5s;
        color: #032b5a;

        > svg {
          margin-bottom: 0.2rem;
          font-size: 1.5rem;
          vertical-align: middle;
        }
      }
    }

    ${(props) =>
      props.active === 'on' &&
      css`
        background-color: #ffffff;
        > span {
          > .text {
            padding-right: 4rem;
          }
          > .arrow {
            opacity: 1;
            right: 0;
          }
        }
      `}

    &:hover {
      background-color: #ffffff6e;

      > span {
        > .text {
          padding-right: 4rem;
          color: white;
        }
        > .arrow {
          opacity: 1;
          right: 0;
        }
      }
    }
  }
`;

export default StyledButton;
