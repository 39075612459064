import React, { useState, ChangeEvent, useEffect } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';

import { SelectOption } from '../../../types/audienceFilter';

// Types
interface Props<T> {
  data: SelectOption<T>[];
  label: string;
  selected?: T;
  onChange: (value: T) => void;
}

// Component
const SingleSelect = <T extends string>({ data, label, selected, onChange }: Props<T>) => {
  const [value, setValue] = useState<T | undefined>(selected);

  useEffect(() => setValue(selected), [selected]);

  const handleChange = (event: ChangeEvent<{}>, value: string) => {
    setValue(value as T);

    // Continue to parent
    onChange(value as T);
  };

  return (
    <>
      <Typography variant="subtitle2">{label}</Typography>

      <RadioGroup row value={value} onChange={handleChange} name={label}>
        {data.map((d) => (
          <FormControlLabel key={d.value} value={d.value} label={d.label} control={<Radio />} />
        ))}
      </RadioGroup>
    </>
  );
};

export default SingleSelect;
